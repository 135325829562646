import {
  Component,
  DestroyRef,
  QueryList,
  ViewChildren,
  inject,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import {
  PBAutocompleteComponent,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { ArrivalLocationService } from "../../arrival-at-exit/arrival-location.service";
import {
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../components";
import { DOCUMENT_TYPE_PREFERENCE_KEY } from "../../constants";
import {
  AuthenticationService,
  UserRights,
} from "../../core/authentication/authentication.service";
import { NotificationService } from "../../core/services/notification.service";
import {
  ArrivalAtExitNotificationType,
  ArrivalAtExitPersonalNotificationSettings,
  PreferencesService,
} from "../services/preferences.service";
import { EmailAddressListComponent } from "./email-address-list.component";
import { OrganisationArrivalLocationAliasesFormComponent } from "./organisation-arrival-location-aliases-form.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    EmailAddressListComponent,
    ButtonIconDirective,
    MatButtonModule,
    MatInputModule,
    MatListModule,
    OrganisationArrivalLocationAliasesFormComponent,
  ],
  selector: "pbe-arrival-at-exit-preferences-form",
  template: `<div class="flex flex-col mt-4 w-full">
    <h2 class="text-lg font-medium">
      {{ "preferences.arrivalAtExit.title" | translate }}
    </h2>

    @if (form) {
      <div class="flex flex-col gap-5 w-full">
        <div class="max-w-md">
          <pbe-form-select
            [hideRequiredMarker]="true"
            [label]="
              'preferences.arrivalAtExit.fields.defaultDocumentType' | translate
            "
            [form]="form"
            controlName="defaultDocumentType"
            [placeholder]="
              'preferences.arrivalAtExit.fields.defaultDocumentTypePlaceholder'
                | translate
            "
            [options]="[
              {
                value: 'EX',
                label:
                  'services.arrivalAtExit.createPage.fields.documentTypeOptions.EX'
                  | translate
              },
              {
                value: 'CO',
                label:
                  'services.arrivalAtExit.createPage.fields.documentTypeOptions.CO'
                  | translate
              }
            ]"
          />
        </div>

        <pbe-arrival-location-aliases-form />
        <div class="flex flex-col w-full">
          <h2 class="text-base font-medium">
            {{ "preferences.notifications.title" | translate }}
          </h2>
          <p class="text-sm my-3">
            {{ "preferences.notifications.description" | translate }}
          </p>

          @if (
            personalNotificationSettings &&
            organisationNotificationSettings().data
          ) {
            @for (type of notificationTypes; track $index) {
              <pbe-email-address-list
                [notificationType]="type"
                [emailList]="
                  organisationNotificationSettings().data?.[type] ?? []
                "
                [isUserSubscribed]="personalNotificationSettings[type]"
                [canEdit]="
                  userRights().includes(UserRights.ConfigureEmailAddresses)
                "
                (toggleSubscription)="
                  updatePersonalNotificationSettings(type, $event)
                "
                (emailListChange)="
                  updateOrganisationNotificationSettings(type, $event)
                "
              />

              @if ($index < notificationTypes.length - 1) {
                <hr />
              }
            }
          }
        </div>
      </div>
    }
  </div>`,
  styles: [
    `
      // This modifies the margin-top from the autocomplete field of default arrival locations to match the Figma design
      ::ng-deep .arrival-at-exit-default-arrival-location-autocomplete {
        .mat-form-field-appearance-outline {
          margin-top: 0.5rem;
        }
      }
    `,
  ],
})
export class ArrivalAtExitPreferencesFormComponent {
  @ViewChildren(PBAutocompleteComponent)
  autocompleteComponents: QueryList<PBAutocompleteComponent> =
    new QueryList<PBAutocompleteComponent>();

  faPlus = faPlus;
  faTimes = faTimes;

  destroyRef = inject(DestroyRef);
  locations = inject(ArrivalLocationService).getArrivalLocations().result;
  preferencesService = inject(PreferencesService);
  notificationService = inject(NotificationService);
  translateService = inject(TranslateService);

  preferredDocumentType = this.preferencesService.getPreference<string>(
    DOCUMENT_TYPE_PREFERENCE_KEY,
  );

  notificationTypes = Object.values(ArrivalAtExitNotificationType);

  UserRights = UserRights;
  userRights = inject(AuthenticationService).userRights;

  organisationNotificationSettings =
    this.preferencesService.getOrganisationNotificationPreferencesForAax()
      .result;
  setOrganisationNotificationSettings =
    this.preferencesService.setOrganisationNotificationPreferencesForAax();

  personalNotificationSettings: ArrivalAtExitPersonalNotificationSettings | null =
    null;
  setPersonalNotificationSettings =
    this.preferencesService.setPersonalNotificationPreferencesForAax();

  profile = inject(AuthenticationService).getProfile().result;

  form = new FormGroup({
    defaultDocumentType: new FormControl(this.preferredDocumentType ?? "EX", {
      validators: [Validators.required],
    }),
  });

  constructor() {
    this.form.controls.defaultDocumentType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.preferencesService.setPreference(
          DOCUMENT_TYPE_PREFERENCE_KEY,
          value,
        );

        this.notificationService.show({
          type: "success",
          closable: true,
          title: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.title",
          ),
          message: this.translateService.instant(
            "preferences.arrivalAtExit.notifications.defaultDocumentTypeSaveSuccess.message",
          ),
        });
      });
  }

  async updatePersonalNotificationSettings(
    key: keyof ArrivalAtExitPersonalNotificationSettings,
    value: boolean,
  ) {
    await this.setPersonalNotificationSettings.mutateAsync({
      ...this.personalNotificationSettings!,
      [key]: value as boolean,
    });
  }

  async updateOrganisationNotificationSettings(
    type: ArrivalAtExitNotificationType,
    emailList: string[],
  ) {
    await this.setOrganisationNotificationSettings.mutateAsync({
      ...this.organisationNotificationSettings().data!,
      [type]: emailList,
    });
  }
}
