import { LowerCasePipe } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../shipment-history.service";

@Component({
  selector: "pbe-history-item-released",
  standalone: true,
  imports: [LowerCasePipe, TranslateModule, FontAwesomeModule],
  template: `
    <div class="flex flex-col gap-1">
      <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
            history.operationType | translate
        }}
      </p>
    </div>
  `,
})
export class HistoryItemReleasedComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS>;
}
