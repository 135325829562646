import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { PortbaseExportFormInputComponent } from "../../../../components";

import { CommonModule, NgFor } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faMagnifyingGlass,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { NotificationService } from "../../../../core/services/notification.service";
import {
  ArrivalAtExitStatus,
  PaginatedShipmentsResponseDto,
  ShipmentService,
} from "../../../shipment.service";

export type OrderDirection = "asc" | "desc";
export type OrderByOption = "creationDate" | "creationDate";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    TippyDirective,
    MatListModule,
    ButtonIconDirective,
    MatFormFieldModule,
    MatInputModule,
    FontAwesomeModule,
    MatButtonModule,
    PortbaseExportFormInputComponent,
    NgFor,
  ],
  selector: "pbe-operational-view-table-controls",
  template: `
    <div
      class="w-full pl-8 py-2 border-b border-b-black/20 flex flex-row justify-between action-bar @container"
    >
      <form (submit)="submitSearchQuery($event)" class="flex-grow">
        <div class="relative mt-[-3rem] inline-block h-4 mr-2 max-w-96 w-1/2">
          <div class="flex flex-col mt-3 w-full">
            <mat-form-field [formGroup]="searchForm">
              <input
                matInput
                [placeholder]="
                  'services.arrivalAtExit.overview.searchPlaceholder'
                    | translate
                "
                id="search"
                formControlName="search"
              />
              @if (searchForm.value.search) {
                <div matSuffix>
                  <button
                    mat-icon-button
                    type="button"
                    (click)="clearSearchQuery($event)"
                    [icon]="faTimes"
                  ></button>
                </div>
              }
            </mat-form-field>
          </div>
        </div>
        <button
          mat-stroked-button
          [icon]="faMagnifyingGlass"
          color="primary"
          type="submit"
        >
          <span class="@2xl:inline-block hidden">{{
            "services.arrivalAtExit.overview.searchButton" | translate
          }}</span>
        </button>
      </form>
      @if (shouldShowResendAllCustomsUnavailableButton()) {
        <button
          mat-stroked-button
          color="primary"
          (click)="resendAllCustomsUnavailableToCustoms()"
        >
          <span>{{
            "services.arrivalAtExit.overview.resubmitCustomsUnavailableButton"
              | translate
          }}</span>
        </button>
      }
      <div class="mr-3 flex flex-row gap-2 items-center">
        <button
          mat-button
          iconPos="right"
          [icon]="faChevronDown"
          [tp]="orderByOptionList"
          class="text-nowrap"
          [tpVariation]="'popper'"
          [tpPlacement]="'bottom-start'"
          [tpPopperWidth]="260"
          [tpOffset]="[0, 8]"
          #tooltip="tippy"
        >
          <span class="">{{
            "services.arrivalAtExit.overview.orderBy." +
              orderBy +
              "." +
              orderDirection | translate
          }}</span>
        </button>
        <ng-template #orderByOptionList>
          <mat-action-list>
            <mat-list-item
              (click)="submitOrderDirection('desc'); tooltip.hide()"
            >
              {{
                "services.arrivalAtExit.overview.orderBy.creationDate.desc"
                  | translate
              }}
            </mat-list-item>
            <mat-list-item
              (click)="submitOrderDirection('asc'); tooltip.hide()"
            >
              {{
                "services.arrivalAtExit.overview.orderBy.creationDate.asc"
                  | translate
              }}
            </mat-list-item>
          </mat-action-list>
        </ng-template>
        <span class="text-sm min-w-20 text-right">{{
          "services.arrivalAtExit.overview.pageAndCount"
            | translate: getPaginationTranslationVariables()
        }}</span>
        <div class="flex flex-row items-center">
          <button
            mat-icon-button
            [icon]="faChevronLeft"
            [disabled]="isLoading || paginationData?.first"
            (click)="previousPage()"
          ></button>
          <button
            mat-icon-button
            [icon]="faChevronRight"
            [disabled]="isLoading || paginationData?.last"
            (click)="nextPage()"
          ></button>
        </div>

        <button
          mat-raised-button
          (click)="
            router.navigate([], {
              relativeTo: this.route,
              queryParamsHandling: 'merge',
              queryParams: { view: 'create' }
            })
          "
          [icon]="faPlus"
        >
          <span class="hidden @4xl:block">{{
            "services.arrivalAtExit.overview.createButton" | translate
          }}</span>
          <span class="block @4xl:hidden">{{
            "services.arrivalAtExit.overview.createButtonShort" | translate
          }}</span>
        </button>
      </div>
    </div>
  `,
})
export class OperationalViewTableControlsComponent implements OnChanges {
  router = inject(Router);
  route = inject(ActivatedRoute);

  faMagnifyingGlass = faMagnifyingGlass;
  faPlus = faPlus;
  faTimes = faTimes;
  faChevronRight = faChevronRight;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;

  @Input({ required: true }) orderBy!: OrderByOption;
  @Input({ required: true }) orderDirection!: OrderDirection;
  @Input({ required: true }) search!: string;
  @Input({ required: true }) page!: number;
  @Input({ required: true }) pageSize!: number;
  @Input() filterStatusIsCustomsUnavailable = false;

  notificationService = inject(NotificationService);
  translate = inject(TranslateService);
  shipmentService = inject(ShipmentService);
  sendToCustoms = this.shipmentService.sendToCustoms({
    refetchOnSuccess: false,
    showNotificationOnSuccess: false,
  }).mutateAsync;

  searchForm: FormGroup = new FormGroup({
    search: new FormControl(""),
  });

  authData = inject(AuthenticationService).authenticated().data;

  @Input() paginationData!: PaginatedShipmentsResponseDto | undefined;
  @Input() isLoading!: boolean;

  ngOnChanges(changes: SimpleChanges): void {
    const search = changes["search"]?.currentValue;
    if (search !== undefined) {
      this.searchForm.controls["search"].setValue(search);
    }
  }

  clearSearchQuery(event: Event) {
    this.searchForm.controls["search"].setValue(null);
    this.submitSearchQuery(event);
  }

  submitSearchQuery(event: Event) {
    event.preventDefault();

    const value = this.searchForm.value.search;
    const shouldRemoveParam = !value || value.length === 0;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        search: shouldRemoveParam ? null : value,
      },
    });
  }

  submitOrderDirection(orderDirection: OrderDirection) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        orderDirection,
      },
    });
  }

  previousPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: Math.max(this.page - 1, 0),
      },
    });
  }

  nextPage() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        page: this.page + 1,
      },
    });
  }

  getPaginationTranslationVariables() {
    const totalElements = this.paginationData?.totalElements ?? 0;

    return {
      total: totalElements,
      first: this.page * this.pageSize + 1,
      last: Math.min((this.page + 1) * this.pageSize, totalElements),
    };
  }

  async resendAllCustomsUnavailableToCustoms() {
    const resubmissionCalls = (this.paginationData?.content ?? [])
      .filter(
        // only shipments with status CUSTOMS_UNAVAILABLE
        (shipment) =>
          shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE,
      )
      .filter(
        // only shpments this organisation can edit
        (shipment) => shipment.registeredByOrg === this.authData?.credentials,
      )
      .map((shipment) => this.sendToCustoms({ id: shipment.shipmentId }));
    await Promise.all(resubmissionCalls);
    this.shipmentService.refetchShipments();
    this.notificationService.show({
      type: "success",
      closable: true,
      title: this.translate.instant(
        "notifications.aaxResendUnavailableSuccess.title",
      ),
      message: this.translate.instant(
        "notifications.aaxResendUnavailableSuccess.message",
      ),
    });
  }

  shouldShowResendAllCustomsUnavailableButton() {
    const hasRelevantShipments = (this.paginationData?.content ?? []).some(
      (shipment) =>
        shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE &&
        shipment.registeredByOrg === this.authData?.credentials,
    );
    return this.filterStatusIsCustomsUnavailable && hasRelevantShipments;
  }
}
