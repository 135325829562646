import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectQuery, queryOptions } from "@ngneat/query";
import { AppConfigService } from "../core/services/app-config.service";
import { ArrivalAtExitStatus } from "./shipment.service";

export enum InspectionType {
  PHYSICAL_CONTROL,
  SCAN_CONTROL,
  NOT_SELECTED_FOR_INSPECTION,
  ZEGELCONTROLE_ECS = "004",
  BESCHEIDCONTROLE = "005",
  UNKNOWN = "UNKNOWN",
}

@Injectable({ providedIn: "root" })
export class InspectionTypeService {
  #http = inject(HttpClient);
  #query = injectQuery();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  inspectionTypeQueryOptions(id: string | null, status: ArrivalAtExitStatus) {
    return queryOptions({
      queryKey: ["shipments", id, "inspection-type"] as const,
      queryFn: () => {
        return this.#http.get<InspectionType>(
          `${this._baseUrl}/shipments/${id}/inspection-type`
        );
      },
      enabled:
        id !== null &&
        status ===
          ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS,
    });
  }

  getInspectionTypeForShipmentId(id: string | null) {
    return this.#query(
      this.inspectionTypeQueryOptions(
        id,
        ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS
      )
    );
  }
}
