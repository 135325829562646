import { Component, inject } from "@angular/core";

import { CommonModule } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import {
  AuthenticationService,
  ServiceSubscriptionDescriptionCode,
} from "../core/authentication/authentication.service";
import { PortbaseExportTopbarComponent } from "../core/topbar.component";
import { ArrivalAtExitPreferencesFormComponent } from "./forms/arrival-at-exit-preferences-form.component";
import { ExportManifestPreferencesFormComponent } from "./forms/export-manifest-preferences-form.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportTopbarComponent,
    ArrivalAtExitPreferencesFormComponent,
    ExportManifestPreferencesFormComponent,
  ],
  selector: "pbe-preferences",
  template: `<div class="flex flex-col w-full h-full">
    <pbe-topbar>
      <div class="flex flex-row justify-between items-center w-full">
        <h1 class="text-xl text-gray-700 ml-6">
          {{ "preferences.title" | translate }}
        </h1>
        <p class="text-xs mr-6">{{ userRoles().join(", ") }}</p>
      </div>
    </pbe-topbar>

    <div class="bg-slate-100 flex-grow p-8 w-full flex-col">
      <div class="bg-white p-8 shadow-sm border rounded-[4px] max-w-3xl">
        <h2 class="text-base font-medium mb-4">
          {{ "preferences.header" | translate }}
        </h2>

        <p class="text-sm font-normal">
          {{ "preferences.subHeader." + getSubHeaderVariant() | translate }}
        </p>
        @if (hasAax()) {
          <pbe-arrival-at-exit-preferences-form></pbe-arrival-at-exit-preferences-form>
        }
        @if (hasEm()) {
          <pbe-export-manifest-preferences-form></pbe-export-manifest-preferences-form>
        }
      </div>
    </div>
  </div>`,
})
export class PreferencesComponent {
  userRoles = inject(AuthenticationService).userRoles;
  hasAax = inject(AuthenticationService).hasAccessToAax;
  hasEm = inject(AuthenticationService).hasAccessToExportManifest;

  ServiceSubscriptionDescriptionCode = ServiceSubscriptionDescriptionCode;

  getSubHeaderVariant(): string {
    if (this.hasAax() && this.hasEm()) {
      return "both";
    } else if (this.hasAax()) {
      return "aaxOnly";
    }
    return "emOnly";
  }
}
