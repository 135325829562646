import {
  Component,
  computed,
  effect,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faTrash } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { DialogService } from "../../core/services/dialog/dialog.service";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { autocompleteSuggestionValidator } from "../../core/validators/autocomplete-suggestion.validator";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalLocationService } from "../arrival-location.service";
import { PortbaseExportRejectionReasonsComponent } from "../components/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../components/status-indicator.component";
import { ArrivalAtExitFormComponent } from "../forms/arrival-at-exit-form.component";
import {
  ArrivalAtExitStatus,
  ShipmentDto,
  ShipmentService,
} from "../shipment.service";
import { documentNumberValidator } from "../validators/document-number.validator";
import { maxOneMonthOldValidator } from "../validators/max-one-month-old.validator";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonIconDirective,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    FontAwesomeModule,
    MatButtonModule,
    MatToolbarModule,
    ArrivalAtExitFormComponent,
  ],
  selector: "pbe-arrival-at-exit-edit-page",
  template: `<div class="h-full flex flex-col">
    <mat-toolbar>
      <div class="flex flex-row w-full">
        <div class="flex-grow flex flex-row items-center gap-4">
          <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
          <h1 class="text-xl text-gray-700 ml-6 items-center">
            {{ "services.arrivalAtExit.editPage.header" | translate }}
          </h1>
        </div>
        <div class="lg:pr-16">
          <button
            class="border border-red-500 shadow-sm rounded text-red-700 px-4 py-2 text-sm font-medium hover:bg-red-200 active:bg-red-300"
            (click)="onDelete()"
          >
            <fa-icon [icon]="faTrash"></fa-icon>
            <span class="ml-3">{{
              "services.arrivalAtExit.editPage.buttons.delete" | translate
            }}</span>
          </button>
        </div>
      </div>
    </mat-toolbar>
    <div class="bg-slate-100 flex-grow p-8 w-full flex-col">
      @if (shipment !== undefined && form !== undefined) {
        <pbe-arrival-at-exit-form
          [showCargoHandlingAgentField]="userCanAssignCargoHandlingAgent"
          [form]="form"
          (cancel)="onClose()"
          (save)="onSave()"
          (sendToCustoms)="onSendToCustoms()"
          [isSaving]="isSaving()"
          [isSendingToCustoms]="isSendingToCustoms()"
        >
          <div class="mt-3 mb-3 text-sm">
            <label class="font-medium">{{
              "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalAtExitStatus"
                | translate
            }}</label>
            <div class="mt-2">
              <pbe-aax-status-indicator
                [status]="shipment.arrivalAtExitStatus"
              ></pbe-aax-status-indicator>

              <pbe-rejection-reasons [shipment]="shipment" />
            </div>
            @if (
              shipment.arrivalAtExitStatus !==
              ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS
            ) {
              <div class="mt-4">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalTimeAndDate"
                    | translate
                }}</label>
                <div class="text-sm text-slate-800 mt-2">
                  {{ shipment.arrivalTimeAndDate | date: "dd-MM-yyyy HH:mm" }}
                </div>
              </div>
            }
          </div>
        </pbe-arrival-at-exit-form>
      }
    </div>
  </div>`,
})
export class ArrivalAtExitEditPageComponent implements OnInit {
  @Input({ required: true }) shipment!: ShipmentDto;

  faTimes = faTimes;
  faTrash = faTrash;
  translateService = inject(TranslateService);
  dialogService = inject(DialogService);
  shipmentService = inject(ShipmentService);
  arrivalLocationsService = inject(ArrivalLocationService);
  preferencesService = inject(PreferencesService);
  arrivalLocationAliases =
    this.preferencesService.getOrganisationArrivalLocationAliases().result;
  updateShipment = this.shipmentService.updateShipment();
  sendToCustoms = this.shipmentService.sendToCustoms();
  sendToCustomsResult = this.sendToCustoms.result;
  updateShipmentForSendingDirectly = this.shipmentService.updateShipment({
    refetchOnSuccess: false,
    showNotificationOnSuccess: false,
  });
  updateShipmentForSendingDirectlyResult =
    this.updateShipmentForSendingDirectly.result;

  updateShipmentResult = this.updateShipment.result;
  deleteShipment = this.shipmentService.deleteShipment();

  cargoHandlingAgentService = inject(OrganisationService);
  assignCargoHandlingAgent =
    this.cargoHandlingAgentService.assignCargoHandlingAgentToShipment();
  assignToCargoHandlingAgentResult = this.assignCargoHandlingAgent.result;

  @Input() userCanAssignCargoHandlingAgent = false;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() editClose = new EventEmitter();
  @Output() editSuccess = new EventEmitter();

  form!: FormGroup;

  isSaving = computed(() => {
    const isCallingUpdate = this.updateShipmentResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return isCallingUpdate || isCallingAssignCargoHandlingAgent;
  });

  isSendingToCustoms = computed(() => {
    const isCallingSendToCustoms = this.sendToCustomsResult().isPending;
    const isCallingUpdateDirectly =
      this.updateShipmentForSendingDirectlyResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return (
      isCallingSendToCustoms ||
      isCallingUpdateDirectly ||
      isCallingAssignCargoHandlingAgent
    );
  });

  constructor() {
    effect(() => {
      const aliases = this.arrivalLocationAliases().data ?? {};
      const arrivalLocation =
        this.arrivalLocationsService.getArrivalLocationForId(
          this.shipment.arrivalLocationId,
        );
      if (arrivalLocation) {
        this.form.get("arrivalLocation")?.setValue({
          label: formatArrivalLocationAddress(
            arrivalLocation,
            aliases[arrivalLocation.id],
          ),
          value: arrivalLocation.id,
        });
      }
    });
  }

  ngOnInit() {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );

    this.form = new FormGroup({
      documentType: new FormControl(this.shipment.documentType, {
        validators: [Validators.required],
      }),
      documentNumber: new FormControl(this.shipment.documentNumber, {
        validators: [Validators.required, documentNumberValidator()],
      }),
      reference: new FormControl(this.shipment.reference, {
        validators: [Validators.maxLength(255)],
      }),
      arrivalLocation: new FormControl(
        arrivalLocation
          ? {
              label: formatArrivalLocationAddress(arrivalLocation),
              value: arrivalLocation.id,
            }
          : {
              label: "",
              value: "",
            },
        {
          validators: [Validators.required, autocompleteSuggestionValidator()],
        },
      ),
      agent: new FormControl(
        this.userCanAssignCargoHandlingAgent
          ? {
              label: this.cargoHandlingAgentService.getCargoHandlingAgentForId(
                this.shipment.assignedToOrg,
              )?.name,
              value: this.shipment.assignedToOrg,
            }
          : "",
      ),
      creationDate: new FormControl(this.shipment.creationDate, {
        validators: [Validators.required, maxOneMonthOldValidator()],
      }),
    });
  }

  async onSave() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;

      if (
        this.userCanAssignCargoHandlingAgent &&
        typeof agent === "object" &&
        this.shipment.assignedToOrg !== agent.value
      ) {
        await this.assignCargoHandlingAgent.mutateAsync({
          shipmentId: this.shipment.shipmentId,
          cargoHandlingAgent: agent.value,
        });
      }

      await this.updateShipment.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        id: this.shipment.shipmentId,
      });

      this.editSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  async onSendToCustoms() {
    if (this.form.valid) {
      const { documentNumber, reference, documentType, agent } =
        this.form.value;
      await this.updateShipmentForSendingDirectly.mutateAsync({
        arrivalLocationId: this.form.value.arrivalLocation.value,
        documentNumber,
        reference,
        documentType,
        id: this.shipment.shipmentId,
      });

      if (
        this.userCanAssignCargoHandlingAgent &&
        typeof agent === "object" &&
        this.shipment.assignedToOrg !== agent.value
      ) {
        this.assignCargoHandlingAgent.mutateAsync({
          shipmentId: this.shipment.shipmentId,
          cargoHandlingAgent: agent.value,
        });
      }

      await this.sendToCustoms.mutateAsync({
        id: this.shipment.shipmentId,
      });

      this.editSuccess.emit();
    } else {
      this.form.markAllAsTouched();
    }
  }

  async onDelete() {
    const shouldDelete = await this.dialogService.showDeleteConfirmationDialog(
      this.translateService.instant(
        "services.arrivalAtExit.editPage.deleteDialog.title",
      ),
      this.translateService.instant(
        "services.arrivalAtExit.editPage.deleteDialog.description",
      ),
    );
    if (shouldDelete) {
      await this.deleteShipment.mutateAsync({
        shipmentId: this.shipment.shipmentId,
      });
      this.editSuccess.emit();
    }
  }

  onClose() {
    this.editClose.emit();
  }
}
