import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import {
  injectMutation,
  injectQuery,
  injectQueryClient,
  keepPreviousData,
  queryOptions,
} from "@ngneat/query";
import { TranslateService } from "@ngx-translate/core";
import { ArrivalAtExitStatus } from "../arrival-at-exit/shipment.service";
import { AppConfigService } from "../core/services/app-config.service";
import { NotificationService } from "../core/services/notification.service";

export enum ExportManifestStatus {
  EXPORT_MANIFEST_NOT_YET_CREATED = "EXPORT_MANIFEST_NOT_YET_CREATED",
  EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS = "EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS",
  EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS = "EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS",
  EXPORT_MANIFEST_REPORTED_TO_CUSTOMS = "EXPORT_MANIFEST_REPORTED_TO_CUSTOMS",
  EXPORT_MANIFEST_REJECTED_AT_CUSTOMS = "EXPORT_MANIFEST_REJECTED_AT_CUSTOMS",
  EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS = "EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS",
  CUSTOMS_UNAVAILABLE = "CUSTOMS_UNAVAILABLE",
}

export enum VesselStatus {
  VESSEL_DEPARTED = "VESSEL_DEPARTED",
  VESSEL_ARRIVED = "VESSEL_ARRIVED",
  VESSEL_PRE_ARRIVAL = "VESSEL_PRE_ARRIVAL",
}

export interface ExportManifestsQueryOptions {
  page?: number;
  size?: number;
  search?: string;
  direction?: string;
  manifestStatuses?: ExportManifestStatus[];
  vesselStatuses?: VesselStatus[];
  fromCreationDate?: string;
  toCreationDate?: string;
  callReferenceNumbers?: string[];
}

export interface PaginatedExportManifestsResponseDto {
  content: ExportManifestListDto[];
  empty: boolean;
  first: boolean;
  last: boolean;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface ExportManifestListDto {
  id: string;
  vesselStatus: VesselStatus;
  manifestStatus: ExportManifestStatus;
  departureDate: string;
  voyageNumber: string;
  vesselName: string;
  callReferenceNumber: string;
}

export interface RejectionReason {
  code: string;
  description: string;
}

export interface RejectionReasonResponse {
  rejectionType: "DEC" | "DRN";
  reasons: RejectionReason[];
}

export interface ExportManifestDetailsDto {
  id: string;
  vesselStatus: VesselStatus;
  rejectionReason: RejectionReasonResponse;
  manifestStatus: ExportManifestStatus;
  departureDate: string;
  voyageNumber: string;
  vesselName: string;
  callReferenceNumber: string;
  customsOfficeName: string;
  customsOfficeUnCode: string;
  shipments: ExportManifestShipmentDto[];
}

export type ExportManifestShipmentDocumentType =
  | "AAD"
  | "ATA"
  | "ICT"
  | "PPE"
  | "RAR"
  | "TIR-R"
  | "REN"
  | "REX"
  | "RTR"
  | "EX"
  | "CO"
  | "EU"
  | "TAR"
  | "TIR-D"
  | "RT1"
  | "RT2"
  | "TT1"
  | "TT2"
  | "ZZZ";

export interface ExportManifestShipmentDto {
  documentType: ExportManifestShipmentDocumentType;
  documentNumber: string;
  referenceNumber: string;
  weight: string;
  arrivalAtExitStatus?: ArrivalAtExitStatus;
  copiedFromMael?: boolean;
  partShipment: boolean;
}

export interface CreateExportManifestShipmentDto {
  documentType: ExportManifestShipmentDocumentType;
  documentNumber: string;
  referenceNumber: string;
  weight: string;
  partShipment: boolean;
}

export interface CreateExportManifestDto {
  callReferenceNumber: string;
  shipments: CreateExportManifestShipmentDto[];
}

export interface UpdateExportManifestDto {
  id: string;
  shipments: CreateExportManifestShipmentDto[];
}

export interface SendManifestToCustomsRequestDto {
  id: string;
}

interface MutationOptions {
  showNotificationOnSuccess: boolean;
  refetchOnSuccess: boolean;
}

const defaultMutationOptions: MutationOptions = {
  refetchOnSuccess: true,
  showNotificationOnSuccess: true,
};

export interface ExportManifestAaxShipmentDto {
  id: string;
  documentNumber: string;
  documentType: "CO" | "EU" | "EX" | "TG1" | "TG2";
  arrivalAtExitStatus: ArrivalAtExitStatus;
  arrivalDateTime: string;
  reference: string;
  copiedFromMael: boolean;
}

export interface PaginatedAaxShipmentsResponseDto {
  content: ExportManifestAaxShipmentDto[];
  empty: boolean;
  first: boolean;
  last: boolean;
  size: number;
  totalElements: number;
  totalPages: number;
}

export interface AaxShipmentsQueryOptions {
  size?: number;
  search?: string;
}

export const MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE = 50;

@Injectable({ providedIn: "root" })
export class ExportManifestService {
  #notificationService = inject(NotificationService);
  #translate = inject(TranslateService);
  #http = inject(HttpClient);
  #query = injectQuery();
  #queryClient = injectQueryClient();
  #mutation = injectMutation();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  getExportManifestsQueryOptions(
    options: ExportManifestsQueryOptions,
    enabled: boolean,
  ) {
    const sanitizedOptions: ExportManifestsQueryOptions = Object.fromEntries(
      Object.entries(options).filter(([, value]) => value !== undefined),
    );

    return queryOptions({
      queryKey: ["manifests", sanitizedOptions] as const,
      queryFn: () => {
        return this.#http.get<PaginatedExportManifestsResponseDto>(
          `${this._baseUrl}/manifests`,
          {
            params: sanitizedOptions as Record<string, string>,
          },
        );
      },
      enabled: enabled,
    });
  }

  getExportManifests(page: number, size: number) {
    return this.#query({
      ...this.getExportManifestsQueryOptions({ page, size }, false),
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
    });
  }

  getExportManifestByIdQueryOptions(id: string | null, enabled: boolean) {
    return queryOptions({
      queryKey: ["manifests", id] as const,
      queryFn: () => {
        return this.#http.get<ExportManifestDetailsDto>(
          `${this._baseUrl}/manifests/${id}`,
        );
      },
      enabled,
      refetchOnWindowFocus: false,
    });
  }

  getExportManifestById(id: string | null) {
    return this.#query({
      ...this.getExportManifestByIdQueryOptions(id, false),
    });
  }

  createExportManifest({
    refetchOnSuccess,
    showNotificationOnSuccess,
  }: MutationOptions = defaultMutationOptions) {
    return this.#mutation({
      mutationFn: (exportManifest: CreateExportManifestDto) => {
        return this.#http.post<ExportManifestDetailsDto>(
          `${this._baseUrl}/manifests`,
          exportManifest,
        );
      },
      onSuccess: () => {
        if (showNotificationOnSuccess) {
          this.#notificationService.show({
            type: "success",
            closable: true,
            title: this.#translate.instant("notifications.emSaveSuccess.title"),
            message: "",
          });
        }
        if (refetchOnSuccess) {
          this.#queryClient.refetchQueries({
            predicate: (query) =>
              query.queryKey[0] === "manifests" && query.isActive(),
          });
        }
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.emSaveError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  updateExportManifest({
    refetchOnSuccess,
  }: MutationOptions = defaultMutationOptions) {
    return this.#mutation({
      mutationFn: (exportManifest: UpdateExportManifestDto) => {
        return this.#http.put<ExportManifestDetailsDto>(
          `${this._baseUrl}/manifests/${exportManifest.id}`,
          exportManifest,
        );
      },
      onSuccess: () => {
        if (refetchOnSuccess) {
          this.#queryClient.refetchQueries({
            predicate: (query) =>
              query.queryKey[0] === "manifests" && query.isActive(),
          });
        }
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.emSaveError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  sendToCustoms() {
    return this.#mutation({
      mutationFn: ({ id }: SendManifestToCustomsRequestDto) =>
        this.#http.patch(`${this._baseUrl}/manifests/${id}/manifest-status`, {
          manifestStatus:
            ExportManifestStatus.EXPORT_MANIFEST_REPORTED_TO_CUSTOMS,
        }),
      onSuccess: () => {
        this.#notificationService.show({
          type: "success",
          closable: true,
          title: this.#translate.instant("notifications.emSendSuccess.title"),
          message: this.#translate.instant(
            "notifications.emSendSuccess.message",
          ),
        });
        this.#queryClient.removeQueries({
          queryKey: ["manifests"],
          type: "inactive",
        });
        this.#queryClient.refetchQueries({
          queryKey: ["manifests"],
          type: "active",
        });
      },
      onError: (error: HttpErrorResponse) => {
        this.#notificationService.show({
          type: "error",
          closable: true,
          title: this.#translate.instant("notifications.emSendError.title"),
          message: error.error?.detail,
        });
      },
    });
  }

  getAaxShipments() {
    return this.#query({
      ...this.getAaxShipmentsQueryOptions({
        search: "",
        size: MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE,
      }),
    });
  }

  getAaxShipmentsQueryOptions(options: AaxShipmentsQueryOptions) {
    const sanitizedOptions: AaxShipmentsQueryOptions = Object.fromEntries(
      Object.entries(options).filter(([, value]) => value !== undefined),
    );

    return queryOptions({
      placeholderData: keepPreviousData,
      refetchOnWindowFocus: false,
      queryKey: ["manifests", "aax-shipments", sanitizedOptions],
      queryFn: () =>
        this.#http.get<PaginatedAaxShipmentsResponseDto>(
          `${this._baseUrl}/manifests/aax-shipments`,
          {
            params: sanitizedOptions as Record<string, string>,
          },
        ),
    });
  }
}
