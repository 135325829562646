import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import {
  Component,
  DestroyRef,
  effect,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/pro-light-svg-icons";
import {
  faArrowRight,
  faUpRightFromSquare,
} from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime } from "rxjs";
import {
  ExportManifestListDto,
  ExportManifestService,
  ExportManifestsQueryOptions,
  ExportManifestStatus,
} from "../export-manifest.service";
import { VesselVisit, VesselVisitService } from "../vessel-visit.service";

@Component({
  selector: "pbe-em-vessel-visit-search",
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatListModule,
    MatInputModule,
    MatAutocompleteModule,
    ButtonIconDirective,
    MatButtonModule,
    NgIf,
    NgFor,
    NgClass,
  ],
  template: `<div class="block">
    <mat-form-field [formGroup]="searchForm">
      <mat-label>
        {{ "services.exportManifest.createPage.vesselVisit.title" | translate }}
      </mat-label>

      <fa-icon
        matSuffix
        class="autocomplete-input-icon"
        size="lg"
        [icon]="faMagnifyingGlass"
      ></fa-icon>

      <input
        #inputRef
        matInput
        [placeholder]="
          'services.exportManifest.createPage.vesselVisit.placeholder'
            | translate
        "
        formControlName="search"
        [matAutocomplete]="auto"
      />
    </mat-form-field>

    <mat-autocomplete
      #auto="matAutocomplete"
      [hideSingleSelectionIndicator]="true"
      [displayWith]="displayFn"
      class="flex flex-col gap-2"
      (optionSelected)="onOptionSelected($event.option.value)"
    >
      <mat-option
        *ngFor="let vesselVisit of vesselVisits().data"
        [value]="vesselVisit"
        [disabled]="isCallReferenceNumberInUse(vesselVisit.callReferenceNumber)"
        [ngClass]="{
          'crn-in-use': isCallReferenceNumberInUse(
            vesselVisit.callReferenceNumber
          )
        }"
        class="!min-h-16"
      >
        <div class="flex justify-between items-start flex-row w-full gap-2">
          <div class="flex flex-col gap-1 w-1/3 min-w-52">
            <div class="font-medium font-sans text-sm text-gray-800">
              {{ vesselVisit.vesselName }} ({{ vesselVisit.vesselImoCode }})
            </div>
            <div class="font-normal font-sans text-sm text-gray-800">
              {{ vesselVisit.callReferenceNumber }}
            </div>
          </div>

          <div class="flex flex-col gap-1 w-1/3 items-start">
            <div class="flex flex-row gap-2">
              <div class="font-medium font-sans text-sm text-gray-800">
                {{ getArrivalTimeLabel(vesselVisit) }}
              </div>
              <div class="font-normal font-sans text-sm text-gray-600">
                {{ getArrivalTime(vesselVisit) | date: "dd MMM HH:mm" }}
              </div>
            </div>

            <div class="flex flex-row gap-2">
              <div class="font-medium font-sans text-sm text-gray-800">
                {{ getDepartureTimeLabel(vesselVisit) }}
              </div>
              <div class="font-normal font-sans text-sm text-gray-600">
                {{ getDepartureTime(vesselVisit) | date: "dd MMM HH:mm" }}
              </div>
            </div>
          </div>

          <div class="flex flex-col gap-1 w-1/3 items-start">
            <div class="font-medium font-sans text-sm text-gray-800">
              {{
                "general.vesselStatus.short." + vesselVisit.vesselStatus
                  | translate
              }}
            </div>

            @if (isCallReferenceNumberInUse(vesselVisit.callReferenceNumber)) {
              <div class=" font-normal font-sans text-sm text-gray-800">
                {{
                  "services.exportManifest.createPage.vesselVisit.manifestExists"
                    | translate
                }}
              </div>
            }
          </div>

          <button
            class="flex !pointer-events-auto flex-row pl-3 my-auto float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
            [ngClass]="{
              invisible: !isCallReferenceNumberInUse(
                vesselVisit.callReferenceNumber
              )
            }"
            (click)="
              redirectToManifestDetails(
                $event,
                getManifestIdByCallReferenceNumber(
                  vesselVisit.callReferenceNumber
                )
              )
            "
          >
            {{
              "services.exportManifest.createPage.vesselVisit.view" | translate
            }}
            <fa-icon class="ml-2" [icon]="faArrowRight"></fa-icon>
          </button>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>`,
  styles: [
    `
      .mat-mdc-form-field {
        width: 100%;
      }

      ::ng-deep .mat-mdc-autocomplete-panel {
        max-height: 345px !important;
      }

      ::ng-deep .mat-mdc-option.crn-in-use {
        background-color: #eaf0f4;
      }
    `,
  ],
})
export class PortbaseExportEmVesselVisitSearchComponent
  implements OnInit, OnChanges
{
  @Input() disabled = false;

  @Output() vesselVisitSelected = new EventEmitter<VesselVisit | null>();

  destroyRef = inject(DestroyRef);

  vesselVisitService = inject(VesselVisitService);
  vesselVisitQuery = this.vesselVisitService.getVesselVisits("");
  vesselVisits = this.vesselVisitQuery.result;

  callReferenceNumbersInUse = new Map<
    string /* CRN */,
    ExportManifestListDto /* Manifest Entity */
  >();

  exportManifestService = inject(ExportManifestService);
  exportManifestsQuery = this.exportManifestService.getExportManifests(0, 1);
  paginatedManifests = this.exportManifestsQuery.result;

  router = inject(Router);
  route = inject(ActivatedRoute);

  searchForm = new FormGroup({
    search: new FormControl("", [Validators.required]),
  });

  faMagnifyingGlass = faMagnifyingGlass;
  faUpRightFromSquare = faUpRightFromSquare;
  faArrowRight = faArrowRight;

  constructor() {
    effect(() => {
      const callReferenceNumbers =
        this.vesselVisits().data?.map(
          (vesselVisit) => vesselVisit.callReferenceNumber,
        ) ?? [];

      if (!callReferenceNumbers.length) {
        return;
      }

      this.callReferenceNumbersInUse.clear();

      const queryOptions: Partial<ExportManifestsQueryOptions> = {
        page: 0,
        size: callReferenceNumbers.length,
        callReferenceNumbers,
      };

      this.exportManifestsQuery.updateOptions(
        this.exportManifestService.getExportManifestsQueryOptions(
          queryOptions,
          true,
        ),
      );

      this.paginatedManifests().data?.content.forEach((manifest) => {
        this.callReferenceNumbersInUse.set(
          manifest.callReferenceNumber,
          manifest,
        );
      });
    });
  }

  ngOnInit(): void {
    this.searchForm.controls.search.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((search) => {
        if (!search || search === "") {
          this.vesselVisitSelected.emit(null);
          return;
        }

        if (search.length < 3) {
          return;
        }

        this.vesselVisitQuery.updateOptions(
          this.vesselVisitService.getVesselVisitsQueryOptions({ search }, true),
        );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["disabled"] && changes["disabled"].currentValue !== undefined) {
      const disabled = changes["disabled"].currentValue as boolean;

      if (disabled) {
        this.searchForm.get("search")?.disable();
      } else {
        this.searchForm.get("search")?.enable();
        this.searchForm.get("search")?.setValue("");
      }
    }
  }

  displayFn(option: VesselVisit) {
    return option ? `${option.vesselName} ${option.callReferenceNumber}` : "";
  }

  getArrivalTime(vesselVisit: VesselVisit) {
    return vesselVisit.ataPort ? vesselVisit.ataPort : vesselVisit.etaPort;
  }

  getArrivalTimeLabel(vesselVisit: VesselVisit) {
    return vesselVisit.ataPort ? "ATA" : "ETA";
  }

  getDepartureTime(vesselVisit: VesselVisit) {
    return vesselVisit.atdPort ? vesselVisit.atdPort : vesselVisit.etdPort;
  }

  getDepartureTimeLabel(vesselVisit: VesselVisit) {
    return vesselVisit.atdPort ? "ATD" : "ETD";
  }

  redirectToManifestDetails(event: MouseEvent, entity?: ExportManifestListDto) {
    event?.preventDefault();

    if (!entity) {
      return;
    }

    const shouldRedirectToEdit = this.canEdit(entity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: shouldRedirectToEdit ? "edit" : "details",
        id: entity.id,
      },
    });
  }

  canEdit(entity: ExportManifestListDto) {
    switch (entity.manifestStatus) {
      case ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED:
      case ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS:
      case ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS:
        return true;
      default:
        return false;
    }
  }

  isCallReferenceNumberInUse(callReferenceNumber: string) {
    return this.callReferenceNumbersInUse.has(callReferenceNumber);
  }

  getManifestIdByCallReferenceNumber(callReferenceNumber: string) {
    return this.callReferenceNumbersInUse.get(callReferenceNumber);
  }

  onOptionSelected(vesselVisit: VesselVisit) {
    const { callReferenceNumber } = vesselVisit;

    if (this.isCallReferenceNumberInUse(callReferenceNumber)) {
      return;
    }

    this.vesselVisitSelected.emit(vesselVisit);
  }
}
