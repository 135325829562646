import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AuthenticationService,
  UserRights,
} from "../../../core/authentication/authentication.service";
import { ShipmentService } from "../../shipment.service";
import { ArrivalAtExitCustomerServiceDetailsPageComponent } from "./customer-service-details-page/customer-service-details-page.component";

type ArrivalAtExitViewQueryParam = "create" | "edit" | "details" | null;

@Component({
  standalone: true,
  selector: "pbe-arrival-at-exit-customer-service-right-drawer",
  imports: [ArrivalAtExitCustomerServiceDetailsPageComponent, MatSidenavModule],
  template: `<div
    class="fixed w-screen h-screen top-0 left-0 z-[1] pointer-events-none"
  >
    <div
      class="bg-black transition-opacity opacity-0 w-full h-full"
      [class]="
        drawer.opened
          ? 'opacity-50 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      "
      (click)="closeRightDrawer()"
    ></div>
    <mat-drawer
      (closed)="removeQueryParams()"
      #drawer
      mode="over"
      class="w-screen max-w-5xl z-10 pointer-events-auto"
      position="end"
    >
      <div class="bg-white h-full w-full  z-10">
        @if (drawer.opened) {
          @switch (viewQueryParam) {
            @case ("details") {
              @if (shipment().data !== undefined) {
                <pbe-arrival-at-exit-customer-service-details-page
                  (detailsClose)="closeRightDrawer()"
                  [shipment]="shipment().data!"
                ></pbe-arrival-at-exit-customer-service-details-page>
              }
            }
          }
        }
      </div>
    </mat-drawer>
  </div>`,
})
export class ArrivalAtExitCustomerServiceRightDrawerComponent
  implements OnDestroy, AfterViewInit
{
  viewQueryParam: ArrivalAtExitViewQueryParam = null;
  entityIdQueryParam: string | null = null;

  UserRights = UserRights;
  userRights = inject(AuthenticationService).userRights;

  router = inject(Router);
  route = inject(ActivatedRoute);

  shipmentsService = inject(ShipmentService);
  shipmentByIdQuery = this.shipmentsService.getShipmentById(
    this.entityIdQueryParam,
  );
  shipment = this.shipmentByIdQuery.result;

  @ViewChild("drawer") public drawer!: MatDrawer;

  _paramsSubscription?: Subscription;

  ngAfterViewInit() {
    this._paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.viewQueryParam = params.get("view") as ArrivalAtExitViewQueryParam;
      if (this.viewQueryParam === "details") {
        this.entityIdQueryParam = this.route.snapshot.queryParamMap.get("id");
        this.shipmentByIdQuery.updateOptions(
          this.shipmentsService.getShipmentByIdQueryOptions(
            this.entityIdQueryParam,
            this.entityIdQueryParam !== null,
          ),
        );
      }
      const openRightDrawer = this.viewQueryParam !== null;
      if (openRightDrawer) {
        setTimeout(() => {
          this.drawer.open();
        });
      }
    });
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
  }

  closeRightDrawer() {
    this.drawer.close();
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: { view: null, id: null },
    });
  }
}
