import { Component, Input, inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faMagnifyingGlass, faPlus } from "@fortawesome/pro-light-svg-icons";
import { faShip } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

export type EmptyStateVariant = "empty" | "emptyQuickFilter" | "emptyNoFilter";

@Component({
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-overview-table-empty-state",
  template: `
    <div
      class="flex flex-col items-center w-full h-full justify-center  text-center"
    >
      <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
        <fa-icon class="text-5xl block text-gray-400" [icon]="faShip"></fa-icon>
      </div>
      <h3 class="font-medium mt-3">
        {{
          "services." + service + ".overview." + emptyStateVariant + ".title"
            | translate
        }}
      </h3>
      @if (emptyStateVariant !== "emptyNoFilter") {
        <p class="text-xs text-gray-600 mt-1">
          {{
            "services." +
              service +
              ".overview." +
              emptyStateVariant +
              ".description" | translate
          }}
        </p>
      }
      @if (emptyStateVariant === "emptyQuickFilter") {
        <button
          mat-stroked-button
          [icon]="faMagnifyingGlass"
          class="mt-3"
          (click)="
            router.navigate(['../all'], {
              relativeTo: this.route,
              queryParamsHandling: 'merge'
            })
          "
        >
          {{
            "services." + service + ".overview.emptyQuickFilter.button"
              | translate
          }}
        </button>
      } @else if (emptyStateVariant === "emptyNoFilter") {
        <button
          mat-stroked-button
          [icon]="faPlus"
          class="mt-3"
          (click)="
            router.navigate([], {
              relativeTo: this.route,
              queryParamsHandling: 'merge',
              queryParams: { view: 'create' }
            })
          "
        >
          {{
            "services." + service + ".overview.emptyNoFilter.button" | translate
          }}
        </button>
      }
    </div>
  `,
})
export class OverviewTableEmptyStateComponent {
  router = inject(Router);
  route = inject(ActivatedRoute);
  faMagnifyingGlass = faMagnifyingGlass;
  faShip = faShip;
  faPlus = faPlus;

  @Input() emptyStateVariant: EmptyStateVariant = "emptyQuickFilter";
  @Input() service!: "exportManifest" | "arrivalAtExit";
}
