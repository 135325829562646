import { CommonModule } from "@angular/common";
import { Component, DestroyRef, OnInit, computed, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faDoorOpen,
  faInbox,
  faShieldExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBSidenavModule } from "@portbase/material/sidenav";
import { isValid, parseISO } from "date-fns";
import {
  EmptyStateVariant,
  OverviewTableEmptyStateComponent,
} from "../../../components/overview-table/empty-state.component";
import { OrganisationService } from "../../../core/services/organisation.service";
import { PortbaseExportTopbarComponent } from "../../../core/topbar.component";
import { formatDateForApi } from "../../../core/utils/date-formatter.utils";
import { DataPrefetcherComponent } from "../../data-prefetcher.component";
import { ArrivalAtExitFilterListComponent } from "../../filter-list/filter-list.component";
import { ArrivalAtExitStatus, ShipmentService } from "../../shipment.service";
import {
  CustomerServiceViewTableControlsComponent,
  OrderByOption,
  OrderDirection,
} from "./customer-service-view-table/customer-service-table-controls.component";
import { ArrivalAtExitCustomerServiceViewTableComponent } from "./customer-service-view-table/customer-service-view-table.component";

const DEFAULT_PAGE_SIZE = 25;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PBSidenavModule,
    PortbaseExportTopbarComponent,
    ArrivalAtExitCustomerServiceViewTableComponent,
    ArrivalAtExitFilterListComponent,
    CustomerServiceViewTableControlsComponent,
    DataPrefetcherComponent,
    OverviewTableEmptyStateComponent,
  ],
  selector: "pbe-arrival-at-exit-customer-service-view",
  template: ` <pb-sidenav
    [primary]="false"
    [canCollapse]="false"
    [hasLogo]="false"
    [navigationListTitle]="
      'services.arrivalAtExit.navigation.title' | translate
    "
    [navigationOptions]="[
      {
        link: '../all',
        label: 'services.arrivalAtExit.navigation.all' | translate,
        icon: faDoorOpen
      },
      {
        link: '../draft',
        label: 'services.arrivalAtExit.navigation.notYetSent' | translate,
        icon: faInbox
      },
      {
        link: '../flagged',
        label: 'services.arrivalAtExit.navigation.flagged' | translate,
        icon: faShieldExclamation
      }
    ]"
    [additionalContentTitle]="
      'services.arrivalAtExit.filters.title' | translate
    "
  >
    <div additionalContent>
      <pbe-arrival-at-exit-filter-list
        [showStatusesFilter]="quickFilter === null"
        [showOrganisationsFilter]="true"
        [creationDateRange]="creationDateRange"
        [statusFilter]="statuses"
        [organisationFilter]="organisations"
        [organisationFilterOptions]="organisationFilterOptions()"
      ></pbe-arrival-at-exit-filter-list>
    </div>

    <div class="flex-grow flex flex-col border-l border-l-black/20">
      @if (paginatedShipments().data; as paginationData) {
        <pbe-customer-service-view-table-controls
          [paginationData]="paginationData"
          [search]="search ?? ''"
          [page]="page"
          [pageSize]="pageSize"
          [orderBy]="orderBy"
          [orderDirection]="orderDirection"
        />
      }
      <div class="bg-slate-100 flex-grow p-10">
        @if (paginatedShipments().data?.content; as shipments) {
          @if (shipments.length === 0) {
            <pbe-overview-table-empty-state
              [emptyStateVariant]="getEmptyStateVariant()"
              service="arrivalAtExit"
            ></pbe-overview-table-empty-state>
          } @else {
            <pbe-arrival-at-exit-customer-service-view-table
              [shipments]="shipments"
            ></pbe-arrival-at-exit-customer-service-view-table>
          }
        }
      </div>
    </div>
  </pb-sidenav>`,
  styles: `
    :host {
      width: 100%;
      height: 100%;
    }
  `,
})
export class ArrivalAtExitCustomerServiceViewComponent implements OnInit {
  faInbox = faInbox;
  faShieldExclamation = faShieldExclamation;
  faDoorOpen = faDoorOpen;

  quickFilter: ArrivalAtExitStatus[] | null = null;
  creationDateRange: [Date, Date] | null = null;
  statuses: ArrivalAtExitStatus[] | null = null;
  organisations: string[] | null = null;
  search: string | null = null;
  orderBy: OrderByOption = "creationDate";
  orderDirection: OrderDirection = "desc";
  page = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  router = inject(Router);
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);

  shipmentsService = inject(ShipmentService);
  shipmentsQuery = this.shipmentsService.getCustomerServiceShipments(
    this.page,
    this.pageSize,
  );
  paginatedShipments = this.shipmentsQuery.result;

  organisationService =
    inject(OrganisationService).getOrganisationsForCustomerService().result;

  organisationFilterOptions = computed(
    () =>
      this.organisationService().data?.map((agent) => ({
        label: agent.name,
        value: agent.shortName,
      })) ?? [],
  );

  ngOnInit(): void {
    this.route.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.quickFilter = data["quickFilter"] ?? null;
      });

    this.route.queryParamMap
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.handleQueryParamDateRange(params.get("creationDateRange"));
        this.handleQueryParamsOrganisations(params.get("organisations"));

        if (this.quickFilter) {
          this.statuses = this.quickFilter;
        } else {
          this.handleQueryParamStatuses(params.get("statuses"));
        }

        const orderDirection = params.get(
          "orderDirection",
        ) as OrderDirection | null;
        const page = params.get("page");
        const pageSize = params.get("pageSize");
        const search = params.get("search");

        this.orderDirection = orderDirection ?? "desc";

        this.page = page !== null ? +page : 0;
        this.pageSize = pageSize !== null ? +pageSize : DEFAULT_PAGE_SIZE;
        this.search = search;

        const queryOptions = {
          page: this.page,
          size: this.pageSize,
          search: this.search ?? undefined,
          statuses: this.statuses ?? undefined,
          organisations: this.organisations ?? undefined,
          direction: this.orderDirection.toUpperCase(),
          fromCreationDate: this.creationDateRange
            ? formatDateForApi(this.creationDateRange[0])
            : undefined,
          toCreationDate: this.creationDateRange
            ? formatDateForApi(this.creationDateRange[1])
            : undefined,
        };

        this.shipmentsQuery.updateOptions(
          this.shipmentsService.getCustomerServiceShipmentsQueryOptions(
            queryOptions,
            true,
          ),
        );
      });
  }

  handleQueryParamDateRange(range: null | string) {
    if (range === null) {
      this.creationDateRange = null;
      return;
    }
    const [startString, endString] = range.split(",");
    const start = parseISO(startString);
    const end = parseISO(endString);

    if (isValid(start) && isValid(end)) {
      this.creationDateRange = [start, end];
    } else {
      this.creationDateRange = null;
    }
  }

  handleQueryParamStatuses(statusesString: null | string) {
    if (statusesString === null || statusesString.length === 0) {
      this.statuses = null;
      return;
    }

    const statuses: string[] = statusesString.split(",");
    const validStatuses = statuses.filter((status) =>
      Object.values(ArrivalAtExitStatus).includes(
        status as ArrivalAtExitStatus,
      ),
    ) as ArrivalAtExitStatus[];

    if (
      validStatuses.length === 0 ||
      validStatuses.length !== statuses.length
    ) {
      return;
    }

    if (validStatuses.length > 0) {
      this.statuses = validStatuses;
    } else {
      this.statuses = null;
    }
  }

  handleQueryParamsOrganisations(organisationsString: null | string) {
    if (organisationsString === null || organisationsString.length === 0) {
      this.organisations = null;
      return;
    }

    const organisations = organisationsString.split(",");

    if (organisations.length > 0) {
      this.organisations = organisations;
    } else {
      this.organisations = null;
    }
  }

  getEmptyStateVariant(): EmptyStateVariant {
    return "empty";
  }
}
