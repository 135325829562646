import { Component, effect, inject } from "@angular/core";
import {
  AuthenticationService,
  UserRights,
} from "../core/authentication/authentication.service";
import { OrganisationService } from "../core/services/organisation.service";

@Component({
  selector: "pbe-export-manifest-data-prefetcher",
  standalone: true,
  template: `<ng-container></ng-container>`,
})
export class ExportManifestDataPrefetcherComponent {
  userRoles = inject(AuthenticationService).userRoles;
  userRights = inject(AuthenticationService).userRights;

  // Always preload organisations, as the information is basically always required for table views & filters.
  organisationsService = inject(OrganisationService);
  organisationsQuery =
    this.organisationsService.getOrganisationsForCustomerService();
  organisations = this.organisationsQuery.result;

  constructor() {
    effect(() => {
      if (this.userRights().includes(UserRights.SeeCustomerServiceView)) {
        this.organisationsQuery.updateOptions(
          this.organisationsService.getOrganisationsForCustomerServiceQueryOptions(
            true,
          ),
        );
      }
    });
  }
}
