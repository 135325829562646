import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { PortbaseExportSelectComponent } from "./select.component";

@Component({
  selector: "pbe-form-select",
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    PortbaseExportSelectComponent,
  ],
  template: `
    <div class="flex flex-col mt-3 w-full">
      <pbe-select
        [form]="form"
        [controlName]="controlName"
        [placeholder]="placeholder"
        [label]="label"
        [options]="options"
        [required]="required() === true"
        [hideRequiredMarker]="hideRequiredMarker"
      />
    </div>
  `,
})
export class PortbaseExportFormSelectComponent {
  @Input({ required: true }) label!: string;
  @Input() placeholder?: string;

  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) controlName!: string;
  @Input() options: { label: string; value: string }[] = [];

  @Input() hideRequiredMarker = false;

  required = () =>
    this.form.get(this.controlName)?.hasValidator(Validators.required);
}
