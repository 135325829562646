import { Component, Input } from "@angular/core";

@Component({
  selector: "pbe-vessel-visit-item",
  standalone: true,
  template: ` <div class="font-sans">
    <label class="font-medium text-sm text-gray-800">{{ label }}</label>
    <div class="text-sm font-normal text-gray-800">
      {{ value }}<ng-content></ng-content>
    </div>
  </div>`,
})
export class PortbaseVesselVisitItemComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) value!: string | number | Date | boolean;
}
