import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import {
  ApplicationConfig,
  importProvidersFrom,
  inject,
  isDevMode,
} from "@angular/core";
import {
  MAT_RIPPLE_GLOBAL_OPTIONS,
  RippleGlobalOptions,
} from "@angular/material/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig,
} from "@angular/material/snack-bar";
import { provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { provideTippyConfig } from "@ngneat/helipopper";
import { provideQueryDevTools } from "@ngneat/query-devtools";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { AMPLITUDE_CONFIG, AmplitudeModule } from "@portbase/amplitude";
import { BeamerModule, beamerConfig } from "@portbase/beamer";
import {
  SESSION_MONITORING_CONFIG,
  SessionMonitoringModule,
  sessionMonitoringConfig,
} from "@portbase/session-monitoring";
import { WebpackTranslateLoader } from "../assets/i18n/webpack-translate.loader";
import { environment } from "../environments/environment";
import { appRoutes } from "./app.routes";
import { AuthenticationTokenInterceptor } from "./core/authentication/authentication-token.interceptor";
import {
  APP_CONFIG,
  AppConfigService,
  Configuration,
} from "./core/services/app-config.service";
import { DEFAULT_NOTIFICATION_DURATION } from "./core/services/notification.service";
import {
  pbPopperVariation,
  pbTooltipArrowVariation,
  pbTooltipVariation,
} from "./core/tippy/variations";

export const provideTranslation = () => ({
  defaultLanguage: "en",
  loader: {
    provide: TranslateLoader,
    useClass: WebpackTranslateLoader,
  },
});

const configuration: Configuration = {
  apiUrl: environment.apiUrl,
  loginUrl: environment.loginUrl,
  tokenApiUrl: environment.tokenApiUrl,
  securityApiProfileUrl: environment.securityApiProfileUrl,
  accountManagementApiUrl: environment.accountManagementApiUrl,
  amplitude: environment.amplitude,
  wpcsBaseUrl: environment.wpcsBaseUrl,
  useMockApi: environment.useMockApi,
  production: environment.production,
};

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(SessionMonitoringModule),
    {
      provide: SESSION_MONITORING_CONFIG,
      useFactory: () => {
        return sessionMonitoringConfig({
          refreshUrl: `${inject(AppConfigService).getConfig().tokenApiUrl}/token/refresh`,
          prodMode: !isDevMode(),
        });
      },
    },
    { provide: AMPLITUDE_CONFIG, useValue: configuration.amplitude },
    importProvidersFrom(AmplitudeModule.forRoot()),
    importProvidersFrom(
      BeamerModule.forRoot(
        beamerConfig({
          parameters: {
            product_id: "oeoPruyu26965",
            filter: "all;pcs",
          },
        }),
      ),
    ),
    { provide: APP_CONFIG, useValue: configuration },
    provideRouter(appRoutes),
    provideAnimations(),
    environment.production === false
      ? provideQueryDevTools({ buttonPosition: "bottom-left" })
      : [],

    importProvidersFrom([
      HttpClientModule,
      TranslateModule.forRoot(provideTranslation()),
    ]),

    provideTippyConfig({
      defaultVariation: "tooltip",
      variations: {
        tooltip: pbTooltipVariation,
        popper: pbPopperVariation,
        tooltipArrow: pbTooltipArrowVariation,
      },
    }),
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        ...new MatSnackBarConfig(),
        duration: DEFAULT_NOTIFICATION_DURATION,
        verticalPosition: "top",
      },
    },

    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: <RippleGlobalOptions>{ disabled: true },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: "outline",
        floatLabel: "always",
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationTokenInterceptor,
      multi: true,
    },
  ],
};
