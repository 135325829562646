import { Component, Input } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

@Component({
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-overview-table-loading-state",
  template: `
    <div
      class="flex flex-col items-center w-full h-full justify-center  text-center"
    >
      <fa-icon
        class="text-6xl  block animate-spin-slow text-blue-900"
        [icon]="loadingIcon"
      />
      <p class="text-sm text-gray-600 mt-4">
        {{
          "services." + service + ".overview.loading.description" | translate
        }}
      </p>
    </div>
  `,
})
export class OverviewTableLoadingStateComponent {
  loadingIcon = faCircleNotch;
  @Input() service!: "exportManifest" | "arrivalAtExit";
}
