import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleEllipsis,
  faCircleExclamation,
  faExclamationCircle,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ArrivalAtExitStatus } from "../shipment.service";

@Component({
  selector: "pbe-aax-status-indicator",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  template: `<div class="flex flex-row items-center h-full min-w-32">
    @if (getStatusIcon(status) !== null) {
      <fa-icon
        [class]="getStatusColor(status)"
        [icon]="getStatusIcon(status)!"
        size="lg"
      ></fa-icon>
    } @else {
      <div class="w-5 h-5 min-w-5 rounded-full text-center bg-gray-300"></div>
    }
    <span class="ml-4 text-sm text-slate-800">{{
      "general.shipmentStatus.short." + status | translate
    }}</span>
  </div>`,
})
export class PortbaseExportAaxStatusIndicatorComponent {
  @Input({ required: true }) status!: ArrivalAtExitStatus;

  statusToColorMap: Record<ArrivalAtExitStatus, string> = {
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS]: "text-blue-500",
    [ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS]:
      "text-yellow-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS]: "text-green-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS]: "text-green-500",
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS]: "text-red-500",
    [ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE]: "text-red-500",
    [ArrivalAtExitStatus.INTERNAL_ERROR]: "text-red-500",
  };

  statusToIconMap: Record<ArrivalAtExitStatus, IconProp | null> = {
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS]: null,
    [ArrivalAtExitStatus.ARRIVAL_AT_EXIT_REPORTED_TO_CUSTOMS]: faCircleEllipsis,
    [ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS]:
      faCircleExclamation,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS]: faCircleCheck,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS]: faCircleCheck,
    [ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS]: faTimesCircle,
    [ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE]: faQuestionCircle,
    [ArrivalAtExitStatus.INTERNAL_ERROR]: faExclamationCircle,
  };

  getStatusColor(status: ArrivalAtExitStatus) {
    return this.statusToColorMap[status];
  }

  getStatusIcon(status: ArrivalAtExitStatus) {
    return this.statusToIconMap[status];
  }
}
