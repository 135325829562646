import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../shipment-history.service";

@Component({
  selector: "pbe-history-item-rejection",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  template: `
    <div class="flex flex-col gap-1">
      <div class="flex text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
            history.operationType
            | translate
              : { documentNumber: history.operationPayload.documentNumber }
        }}
      </div>

      @if (history.operationPayload.rejectionReason) {
        <div class="text-xs text-slate-600">
          <h1>
            {{ "services.arrivalAtExit.editPage.rejection.title" | translate }}
          </h1>
          <div class="text-xs text-slate-600 mt-2">
            <ul class="list-disc ml-4">
              @for (
                reason of history.operationPayload.rejectionReason.reasons;
                track reason.code
              ) {
                <li>
                  <strong class="font-medium">{{ reason.code }}</strong> -
                  {{ "rejectionReasons." + reason.code | translate }}
                </li>
              }
            </ul>
            <p class="mt-3">
              {{
                "services.arrivalAtExit.editPage.rejection.moreDetails"
                  | translate
              }}
              <a
                class="underline"
                target="_blank"
                [href]="
                  'rejectionReasons.' +
                    (history.operationPayload.rejectionReason.rejectionType ===
                    'DEC'
                      ? 'codebookUrl'
                      : 'codebookDrnUrl') | translate
                "
              >
                {{
                  "services.arrivalAtExit.editPage.rejection.moreDetailsLink"
                    | translate
                }}
              </a>
            </p>
          </div>
        </div>
      }
    </div>
  `,
})
export class HistoryItemRejectionComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS>;
}
