import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../shipment-history.service";
import { HistoryItemAcceptedComponent } from "./operations/history-item-accepted.component";
import { HistoryItemCargoHandlingAgentChangedComponent } from "./operations/history-item-cargo-handling-agent-changed.component";
import { HistoryItemDetailsChangedComponent } from "./operations/history-item-details-changed.component";
import { HistoryItemInspectionComponent } from "./operations/history-item-inspection.component";
import { HistoryItemRejectionComponent } from "./operations/history-item-rejection.component";
import { HistoryItemReleasedComponent } from "./operations/history-item-released.component";
import { ShipmentHistoryWrapperComponent } from "./shipment-history-wrapper.component";

@Component({
  selector: "pbe-shipment-history",
  standalone: true,
  imports: [
    FontAwesomeModule,
    TranslateModule,
    ShipmentHistoryWrapperComponent,
    HistoryItemDetailsChangedComponent,
    HistoryItemCargoHandlingAgentChangedComponent,
    HistoryItemAcceptedComponent,
    HistoryItemReleasedComponent,
    HistoryItemRejectionComponent,
    HistoryItemInspectionComponent,
  ],
  template: `<pbe-shipment-history-wrapper
    [history]="history"
    [isMostRecent]="isMostRecent"
  >
    @switch (history.operationType) {
      @case (ShipmentOperationType.AAX_SHIPMENT_DETAILS_CHANGED) {
        <pbe-history-item-details-changed
          [history]="history"
        ></pbe-history-item-details-changed>
      }

      @case (ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED) {
        <pbe-history-item-cargo-handling-agent-changed [history]="history">
        </pbe-history-item-cargo-handling-agent-changed>
      }

      @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS) {
        <pbe-history-item-accepted
          [history]="history"
        ></pbe-history-item-accepted>
      }

      @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_RELEASED_AT_CUSTOMS) {
        <pbe-history-item-released
          [history]="history"
        ></pbe-history-item-released>
      }

      @case (ShipmentOperationType.AAX_EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS) {
        <pbe-history-item-rejection
          [history]="history"
        ></pbe-history-item-rejection>
      }

      @case (
        ShipmentOperationType.AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS
      ) {
        <pbe-history-item-inspection
          [history]="history"
        ></pbe-history-item-inspection>
      }

      @default {
        <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
          {{
            "services.arrivalAtExit.detailsPage.historyTab.operations." +
              history.operationType | translate
          }}
        </p>
      }
    }
  </pbe-shipment-history-wrapper>`,
})
export class ShipmentHistoryComponent {
  @Input({ required: true }) history!: ShipmentHistoryDto;
  @Input({ required: true }) isMostRecent!: boolean;

  ShipmentOperationType = ShipmentOperationType;
}
