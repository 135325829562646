import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../shipment-history.service";

@Component({
  selector: "pbe-history-item-inspection",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  template: `
    <div class="flex flex-col gap-1">
      <p class="text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
            history.operationType
            | translate
              : { documentNumber: history.operationPayload.documentNumber }
        }}
      </p>

      @if (history.operationPayload.inspectionType) {
        <p class="text-xs text-slate-600">
          Inspection type: {{ history.operationPayload.inspectionType }}
        </p>
      }
    </div>
  `,
})
export class HistoryItemInspectionComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS>;
}
