import { Component, inject, Input } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { AuthenticationService } from "../../../../core/authentication/authentication.service";
import { PortbaseExportEmStatusIndicatorComponent } from "../../../components/status-indicator.component";
import { ExportManifestListDto, ExportManifestStatus } from "../../../export-manifest.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportEmStatusIndicatorComponent,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
  ],
  selector: "pbe-export-manifest-operational-view-table",
  styleUrls: ["operational-view-table.component.scss"],
  template: `
    <table mat-table [dataSource]="manifests">
      <ng-container matColumnDef="callReferenceNumber">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.callReferenceNumber"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.callReferenceNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vesselName">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.vesselName"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.vesselName }}
        </td>
      </ng-container>
      <ng-container matColumnDef="voyageNumber">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.voyageNumber"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.voyageNumber }}
        </td>
      </ng-container>
      <ng-container matColumnDef="departureDate">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.departureDate"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.departureDate | date: "dd-MM-yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="vesselStatus">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.vesselStatus"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ "general.vesselStatus.short." + element.vesselStatus | translate }}
        </td>
      </ng-container>
      <ng-container matColumnDef="manifestStatus">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.exportManifest.overview.table.headers.manifestStatus"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          <pbe-em-status-indicator [status]="element.manifestStatus" />
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="flex flex-row pl-3 float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
            (click)="openDetailsPage(element)"
          >
            @if (canEdit(element)) {
              <span>{{
                "services.exportManifest.overview.table.actions.edit"
                  | translate
              }}</span>
            } @else {
              <span>{{
                "services.exportManifest.overview.table.actions.details"
                  | translate
              }}</span>
            }
            <fa-icon class="ml-4" [icon]="faChevronRight"></fa-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `,
})
export class ExportManifestOperationalViewTableComponent {
  @Input({ required: true }) manifests!: ExportManifestListDto[];

  authenticationData = inject(AuthenticationService).authenticated;

  router = inject(Router);
  route = inject(ActivatedRoute);

  faChevronRight = faChevronRight;

  displayedColumns = [
    "callReferenceNumber",
    "vesselName",
    "voyageNumber",
    "departureDate",
    "vesselStatus",
    "manifestStatus",
    "actions",
  ];

  openDetailsPage(entity: ExportManifestListDto) {
    const shouldGoToEdit = this.canEdit(entity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: shouldGoToEdit ? "edit" : "details",
        id: entity.id,
      },
    });
  }

  canEdit(entity: ExportManifestListDto) {

    const status = entity.manifestStatus

    return (
        status === ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED ||
        status === ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS ||
        status === ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS ||
        status === ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS ||
        status === ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS
    );
  }
}
