import {
  Component,
  EventEmitter,
  Input,
  Output,
  effect,
  inject,
} from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  PortbaseExportDocumentNumberFormInputComponent,
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../components";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleNotch } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import {
  AutocompleteSuggestion,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import { PbInput } from "@portbase/portbase-theme";
import Fuse from "fuse.js";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { PreferencesService } from "../../preferences/services/preferences.service";
import { ArrivalLocationService } from "../arrival-location.service";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["label"],
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    PortbaseExportDocumentNumberFormInputComponent,
    FontAwesomeModule,
    MatButtonModule,
    PbInput,
    MatInputModule,
    PBAlertModule,
  ],
  selector: "pbe-arrival-at-exit-form",
  template: ` <form
    [formGroup]="form"
    (ngSubmit)="sendToCustoms.emit()"
    class="bg-white p-8 shadow-sm border max-w-3xl mx-auto"
  >
    <h2 class="text-lg font-medium mb-8">
      {{ "services.arrivalAtExit.createPage.headers.document" | translate }}
    </h2>

    @if (
      form.controls["creationDate"] && form.controls["creationDate"].errors
    ) {
      <div class="w-full -mt-4 mb-2">
        <pb-alert
          type="error"
          [title]="
            'services.arrivalAtExit.errors.maxOneMonthOld.title' | translate
          "
          [message]="
            'services.arrivalAtExit.errors.maxOneMonthOld.message' | translate
          "
        ></pb-alert>
      </div>
    }

    <div class="w-full flex flex-row gap-8">
      <div class="max-w-96 flex-grow">
        <pbe-form-select
          [label]="
            'services.arrivalAtExit.createPage.fields.documentType' | translate
          "
          [form]="form"
          controlName="documentType"
          [placeholder]="
            'services.arrivalAtExit.createPage.fields.documentTypePlaceholder'
              | translate
          "
          [options]="[
            {
              value: 'EX',
              label:
                'services.arrivalAtExit.createPage.fields.documentTypeOptions.EX'
                | translate
            },
            {
              value: 'CO',
              label:
                'services.arrivalAtExit.createPage.fields.documentTypeOptions.CO'
                | translate
            }
          ]"
        />
        <pbe-document-number-form-input
          [form]="form"
          controlName="documentNumber"
        />
        <pb-input
          [label]="
            'services.arrivalAtExit.createPage.fields.reference' | translate
          "
          [form]="form"
          controlName="reference"
        />

        @if (form.controls["reference"].errors) {
          <mat-error class="text-xs">{{
            "general.forms.errors.reference.maxlength" | translate
          }}</mat-error>
        }

        <h2 class="text-lg font-medium mb-3 mt-4">
          {{ "services.arrivalAtExit.createPage.headers.arrival" | translate }}
        </h2>
        <pb-autocomplete
          [label]="
            'services.arrivalAtExit.createPage.fields.arrivalLocation'
              | translate
          "
          [placeholder]="
            'services.arrivalAtExit.createPage.fields.arrivalLocationPlaceholder'
              | translate
          "
          [formGroup]="form"
          controlName="arrivalLocation"
          [suggestions]="
            getFilteredSuggestionsForArrivalLocations(
              form.value.arrivalLocation
            )
          "
          [quickSelectionSuggestions]="
            quickSelectionSuggestionsForArrivalLocations
          "
        ></pb-autocomplete>
        @if (
          form.controls["arrivalLocation"].errors &&
          form.controls["arrivalLocation"].touched
        ) {
          <mat-error class="text-xs">{{
            "general.forms.errors.required" | translate
          }}</mat-error>
        }
        @if (showCargoHandlingAgentField) {
          <h2 class="text-lg font-medium mb-3 mt-4">
            {{ "services.arrivalAtExit.createPage.headers.agent" | translate }}
          </h2>
          <pb-autocomplete
            [label]="
              'services.arrivalAtExit.createPage.fields.agent' | translate
            "
            [placeholder]="
              'services.arrivalAtExit.createPage.fields.agentPlaceholder'
                | translate
            "
            [formGroup]="form"
            controlName="agent"
            [optionalLabel]="'general.forms.optionalLabel' | translate"
            [suggestions]="
              getFilteredSuggestionsForCargoHandlingAgents(form.value.agent)
            "
          ></pb-autocomplete>
        }
      </div>
      <div class="max-w-64">
        <ng-content></ng-content>
      </div>
    </div>
    <div class="flex flex-row align-center justify-between mt-8">
      <button
        mat-stroked-button
        type="button"
        (click)="cancel.emit()"
        [disabled]="isSaving || isSendingToCustoms"
      >
        {{ "services.arrivalAtExit.createPage.buttons.cancel" | translate }}
      </button>

      <div>
        <button
          mat-stroked-button
          type="button"
          class="mr-5"
          [disabled]="isSaving || isSendingToCustoms"
          (click)="save.emit()"
        >
          <span class="flex flex-row items-center">
            @if (isSaving) {
              <fa-icon [icon]="loadingIcon" class="animate-spin-slow" />
            }
            <span>{{
              "services.arrivalAtExit.createPage.buttons.saveAsDraft"
                | translate
            }}</span>
          </span>
        </button>
        <button
          mat-raised-button
          type="submit"
          [disabled]="isSaving || isSendingToCustoms"
        >
          <span class="flex flex-row items-center ">
            @if (isSendingToCustoms) {
              <fa-icon [icon]="loadingIcon" class="animate-spin-slow" />
            }
            <span>{{
              "services.arrivalAtExit.createPage.buttons.submit" | translate
            }}</span>
          </span>
        </button>
      </div>
    </div>
  </form>`,
})
export class ArrivalAtExitFormComponent {
  locations = inject(ArrivalLocationService).getArrivalLocations().result;
  cargoHandlingAgents =
    inject(OrganisationService).getCargoHandlingAgents().result;

  preferencesService = inject(PreferencesService);

  arrivalLocationAliases =
    this.preferencesService.getOrganisationArrivalLocationAliases().result;

  loadingIcon = faCircleNotch;

  @Input() showCargoHandlingAgentField = false;
  @Input() isSaving = false;
  @Input() isSendingToCustoms = false;

  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() sendToCustoms = new EventEmitter();

  @Input({ required: true }) form!: FormGroup;

  suggestionsForArrivalLocations: AutocompleteSuggestion[] = [];
  suggestionsForCargoHandlingAgents: AutocompleteSuggestion[] = [];
  quickSelectionSuggestionsForArrivalLocations: AutocompleteSuggestion[] = [];

  searchableListForArrivalLocations?: Fuse<{ value: string; label: string }>;
  searchableListForCargoHandlingAgents?: Fuse<{ value: string; label: string }>;

  constructor() {
    effect(() => {
      const aliases = this.arrivalLocationAliases().data ?? {};

      const mappedValues =
        this.locations().data?.map((location) => ({
          label: formatArrivalLocationAddress(location, aliases[location.id]),
          value: location.id,
        })) ?? [];
      // Sort locations by presence of alias (check on ID)
      mappedValues.sort((a, b) => {
        const aHasAlias = aliases[a.value] !== undefined;
        const bHasAlias = aliases[b.value] !== undefined;

        if (aHasAlias && !bHasAlias) {
          return -1;
        }

        if (!aHasAlias && bHasAlias) {
          return 1;
        }

        return 0;
      });

      this.suggestionsForArrivalLocations = mappedValues;

      this.searchableListForArrivalLocations = new Fuse(
        this.suggestionsForArrivalLocations,
        fuseOptions,
      );

      this.quickSelectionSuggestionsForArrivalLocations = Object.entries(
        aliases,
      ).map(([id, alias]) => ({
        label: alias,
        value: id,
      }));
    });

    effect(() => {
      if (this.showCargoHandlingAgentField) {
        const mappedValues =
          this.cargoHandlingAgents().data?.map((agent) => ({
            label: agent.name,
            value: agent.shortName,
          })) ?? [];
        this.suggestionsForCargoHandlingAgents = mappedValues;

        this.searchableListForCargoHandlingAgents = new Fuse(
          this.suggestionsForCargoHandlingAgents,
          fuseOptions,
        );
      }
    });
  }

  getFilteredSuggestionsForArrivalLocations(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForArrivalLocations
          ?.search(query)
          .map((r) => r.item) ?? []
      : this.suggestionsForArrivalLocations;
  }

  getFilteredSuggestionsForCargoHandlingAgents(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForCargoHandlingAgents
          ?.search(query)
          .map((r) => r.item) ?? []
      : this.suggestionsForCargoHandlingAgents;
  }
}
