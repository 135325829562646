import { CommonModule } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";

const WEIGHT_PATTERN = /^\d+$/;

@Component({
  selector: "pbe-em-partshipment-form-select",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    TippyDirective,
  ],
  template: `
    <div class="flex flex-row justify-between items-center">
      <ng-container [formGroup]="formGroup">
        <p class="font-sans text-sm font-normal text-gray-800 w-5">
          {{
            formGroup.get("partShipment")?.value
              ? ("services.exportManifest.createPage.fields.partShipmentOptions.yes"
                | translate)
              : ("services.exportManifest.createPage.fields.partShipmentOptions.no"
                | translate)
          }}
        </p>

        <fa-icon
          matSuffix
          size="lg"
          [icon]="faChevronDown"
          class="py-1.5 px-2 rounded-full"
          [ngClass]="{
            'text-blue-300': isDisabled,
            'text-blue-700 group-hover:bg-blue-100': !isDisabled
          }"
        ></fa-icon>

        <ng-content></ng-content>
      </ng-container>
    </div>
  `,
})
export class PortbaseExportEmPartshipmentFormSelectComponent implements OnInit {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  faChevronDown = faChevronDown;

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.formGroup
      .get("partShipment")
      ?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((partShipment) => {
        const weightControl = this.formGroup.get("weight");

        if (partShipment) {
          weightControl?.setValidators([
            Validators.required,
            Validators.pattern(WEIGHT_PATTERN),
          ]);
          weightControl?.markAsTouched();
        } else {
          weightControl?.clearValidators();
          weightControl?.setValidators([Validators.pattern(WEIGHT_PATTERN)]);
        }

        weightControl?.updateValueAndValidity();
      });
  }

  get isDisabled() {
    return (
      this.formGroup.controls[this.controlName].disabled &&
      !this.formGroup.controls[this.controlName].invalid
    );
  }
}
