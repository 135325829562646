import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Output } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faAngleDown,
  faCircle,
  faCircleDot,
  faTimes,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../components";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar.component";
import { PortbaseExportEmStatusIndicatorComponent } from "../components/status-indicator.component";
import { PortbaseExportEmVesselVisitSearchComponent } from "../components/vessel-visit-search.component";
import {
  CreateExportManifestShipmentDto,
  ExportManifestService,
  ExportManifestShipmentDto,
  ExportManifestStatus,
} from "../export-manifest.service";
import { VesselVisit } from "../vessel-visit.service";
import { ExportManifestShipmentsComponent } from "./components/export-shipments.component";
import { PortbaseVesselVisitItemComponent } from "./components/vessel-visit-item.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PortbaseVesselVisitItemComponent,
    ExportManifestShipmentsComponent,
    PortbaseExportEmVesselVisitSearchComponent,
    PBAlertModule,
  ],
  selector: "pbe-export-manifest-create-page",
  template: `<div class="h-full flex flex-col items-stretch overflow-hidden">
    <mat-toolbar class="sticky top-0 z-10 bg-white">
      <div class="flex flex-row items-center gap-4">
        <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
        <h1 class="text-xl text-gray-700 ml-6 items-center">
          {{ title }}
        </h1>
      </div>
    </mat-toolbar>

    <div
      class="bg-slate-100 flex-grow w-full flex-col overflow-auto overflow-x-hidden border-b border-b-gray-200"
    >
      <div class="bg-white shadow-sm">
        <div class="w-full py-8 ml-8 max-w-2xl">
          <h2 class="text-lg font-medium mb-4">
            {{
              "services.exportManifest.detailsPage.manifestDetails.title"
                | translate
            }}
          </h2>

          <div class="w-full">
            <pbe-em-vessel-visit-search
              (vesselVisitSelected)="onVesselVisitSelected($event)"
              [disabled]="!!selectedVesselVisit"
            ></pbe-em-vessel-visit-search>
          </div>

          @if (selectedVesselVisit) {
            <div
              class="w-full flex flex-row mt-4 py-6 pl-6 pr-2 border border-gray-200 rounded-md"
            >
              <div class="w-3/4 flex flex-col gap-4">
                <pbe-vessel-visit-item
                  [label]="
                    'services.exportManifest.detailsPage.manifestDetails.fields.vesselName'
                      | translate
                  "
                  [value]="
                    selectedVesselVisit.vesselName +
                    ' ' +
                    '(' +
                    selectedVesselVisit.vesselImoCode +
                    ')'
                  "
                />

                <div class="flex flex-row gap-12">
                  <pbe-vessel-visit-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.departureDate'
                        | translate
                    "
                    [value]="
                      (selectedVesselVisit.departureDate
                        | date: 'dd-MM-yyyy') ?? '-'
                    "
                  />

                  <pbe-vessel-visit-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.vesselStatus'
                        | translate
                    "
                    [value]="
                      'general.vesselStatus.short.' +
                        selectedVesselVisit.vesselStatus | translate
                    "
                  />
                </div>
              </div>

              <div class="flex flex-col w-full gap-4">
                <div class="w-full flex flex-row gap-10">
                  <pbe-vessel-visit-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.callReferenceNumber'
                        | translate
                    "
                    [value]="selectedVesselVisit.callReferenceNumber"
                  />
                  <pbe-vessel-visit-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.voyageNumber'
                        | translate
                    "
                    [value]="selectedVesselVisit.voyageNumber"
                  />
                </div>

                <div class="flex w-full">
                  <pbe-vessel-visit-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.customsOffice'
                        | translate
                    "
                    [value]="
                      selectedVesselVisit.customsOfficeName +
                      ' (' +
                      selectedVesselVisit.customsOfficeUnCode +
                      ')'
                    "
                  />
                </div>
              </div>

              <div class="-mt-[18px] -mr-[5px]">
                <button
                  class="!text-pb-blue-700"
                  mat-icon-button
                  [icon]="faTrashCan"
                  (click)="selectedVesselVisit = null; initialShipments = []"
                ></button>
              </div>
            </div>
          }
        </div>

        @if (showEmptyShipmentsError) {
          <div class="w-fit px-8 pb-8">
            <pb-alert
              type="error"
              [title]="
                'services.exportManifest.errors.noShipments.title' | translate
              "
              [message]="
                'services.exportManifest.errors.noShipments.message' | translate
              "
            ></pb-alert>
          </div>
        }
      </div>

      @if (selectedVesselVisit) {
        <pbe-export-manifest-shipments
          [initialShipments]="initialShipments"
          (pendingChanges)="save($event)"
          (formValidityChange)="disableSubmit = $event"
        ></pbe-export-manifest-shipments>
      }
    </div>

    @if (selectedVesselVisit) {
      <div
        class="h-[4.5rem] p-4 mx-3 sticky bottom-0 z-10 bg-white footer-shadow"
      >
        <div class="w-full flex items-center justify-between ">
          <div class="flex flex-row justify-center items-center gap-2.5">
            <button
              class="border border-red-200 shadow-sm rounded text-red-700 px-4 py-2 text-sm font-medium hover:border-red-300 hover:bg-red-200 active:bg-red-300"
            >
              {{
                "services.exportManifest.createPage.buttons.delete" | translate
              }}
            </button>
          </div>
          <div class="flex flex-row justify-center items-center gap-2.5">
            <p class="text-gray-500 text-xs font-normal font-sans">
              {{
                "services.exportManifest.createPage.changesAreSavedAutomatically"
                  | translate
              }}
            </p>

            <button mat-stroked-button type="button" (click)="onClose()">
              {{
                "services.exportManifest.createPage.buttons.close" | translate
              }}
            </button>
            <button
              mat-raised-button
              type="submit"
              (click)="onSendToCustoms()"
              [disabled]="showEmptyShipmentsError || disableSubmit"
            >
              {{
                "services.exportManifest.createPage.buttons.submit" | translate
              }}
            </button>
          </div>
        </div>
      </div>
    }
  </div>`,
  styles: [
    `
      .footer-shadow {
        box-shadow: 0px -4px 4px 0px #0000000d;
      }
    `,
  ],
})
export class ExportManifestCreatePageComponent {
  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;
  faTrashCan = faTrashCan;

  ExportManifestStatus = ExportManifestStatus;

  createExportManifest =
    this.exportManifestService.createExportManifest().mutateAsync;

  updateExportManifest =
    this.exportManifestService.updateExportManifest().mutateAsync;

  sendToCustoms = this.exportManifestService.sendToCustoms().mutateAsync;

  initialShipments: ExportManifestShipmentDto[] = [];

  updatedShipments: CreateExportManifestShipmentDto[] = [];

  showEmptyShipmentsError = false;
  disableSubmit = false;

  @Output() createClose = new EventEmitter();

  selectedVesselVisit: VesselVisit | null = null;

  manifestId: string | null = null;

  constructor(
    private translateService: TranslateService,
    private exportManifestService: ExportManifestService,
  ) {}

  onClose() {
    this.createClose.emit();
  }

  async save(shipments: CreateExportManifestShipmentDto[]) {
    this.updatedShipments = shipments;
    this.showEmptyShipmentsError = false;

    if (!this.manifestId) {
      return;
    }

    await this.updateExportManifest({
      id: this.manifestId,
      shipments:
        this.updatedShipments.filter((shipment) => shipment !== null) ?? [],
    });
  }

  async onSendToCustoms() {
    if (!this.selectedVesselVisit) {
      return;
    }

    if (
      this.updatedShipments === null ||
      this.updatedShipments.length === 0 ||
      this.manifestId === null
    ) {
      this.showEmptyShipmentsError = true;
      return;
    }

    await this.sendToCustoms({ id: this.manifestId });
    this.createClose.emit();
  }

  async onVesselVisitSelected(vesselVisit: VesselVisit | null) {
    this.selectedVesselVisit = vesselVisit;

    if (!this.selectedVesselVisit) {
      this.manifestId = null;
      return;
    }

    const { id } = await this.createExportManifest({
      callReferenceNumber: this.selectedVesselVisit.callReferenceNumber,
      shipments: [],
    });

    this.manifestId = id;
  }

  get title() {
    if (this.selectedVesselVisit) {
      return this.selectedVesselVisit.callReferenceNumber;
    }

    return this.translateService.instant(
      "services.exportManifest.createPage.header",
    );
  }
}
