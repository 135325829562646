import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  inject,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faAngleDown,
  faArrowRightFromBracket,
} from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { endOfDay, format, startOfDay, subYears } from "date-fns";
import { PortbaseExportTabBarComponent } from "../../../../components";
import { AppConfigService } from "../../../../core/services/app-config.service";
import { OrganisationService } from "../../../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../../../core/utils/address-formatter.utils";
import { ArrivalLocationService } from "../../../arrival-location.service";
import { PortbaseExportRejectionReasonsComponent } from "../../../components/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../components/status-indicator.component";
import { ShipmentHistoryComponent } from "../../../history/shipment-history.component";
import { InspectionTypeService } from "../../../inspection-type.service";
import { ShipmentHistoryService } from "../../../shipment-history.service";
import { ArrivalAtExitStatus, ShipmentDto } from "../../../shipment.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    ShipmentHistoryComponent,
  ],
  selector: "pbe-arrival-at-exit-customer-service-details-page",
  template: `<div class="h-full flex flex-col items-stretch">
    <mat-toolbar>
      <div class="flex items-center justify-between w-full">
        <div class="flex flex-row items-center gap-4">
          <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
          <h1 class="text-xl text-gray-700 ml-6 items-center">
            {{ "services.arrivalAtExit.detailsPage.header" | translate }}
          </h1>
        </div>

        @if (shipment.messageReferenceId) {
          <button
            (click)="openMessageManagement(shipment.messageReferenceId)"
            mat-stroked-button
            [icon]="faArrowRightFromBracket"
            color="primary"
            type="submit"
          >
            {{
              "services.arrivalAtExit.detailsPage.messageManagementButton"
                | translate
            }}
          </button>
        }
      </div>
    </mat-toolbar>
    <div class="bg-slate-100 flex-grow w-full flex-col">
      <pbe-tab-bar
        [activeTabIndex]="activeTabIndex"
        (changeTabIndex)="activeTabIndex = $event"
        [tabLabels]="[
          'services.arrivalAtExit.detailsPage.detailsTab.header' | translate,
          'services.arrivalAtExit.detailsPage.historyTab.header' | translate
        ]"
      ></pbe-tab-bar>
      @if (activeTabIndex === 0 && shipment !== undefined) {
        <div class="mt-10 bg-white p-8 shadow-sm border max-w-3xl mx-auto">
          <div class="w-full">
            <div class="max-w-96">
              <h2 class="text-lg font-medium mb-4">
                {{
                  "services.arrivalAtExit.createPage.headers.document"
                    | translate
                }}
              </h2>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentType"
                    | translate
                }}</label>
                <div>
                  {{
                    "services.arrivalAtExit.createPage.fields.documentTypeOptions." +
                      shipment.documentType | translate
                  }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentNumber"
                    | translate
                }}</label>
                <div>{{ shipment.documentNumber }}</div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.reference"
                    | translate
                }}</label>
                <div>{{ shipment.reference || "-" }}</div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalAtExitStatus"
                    | translate
                }}</label>
                <div class="mt-2">
                  <pbe-aax-status-indicator
                    [status]="shipment.arrivalAtExitStatus"
                  ></pbe-aax-status-indicator>

                  @if (
                    shipment.arrivalAtExitStatus ===
                    ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS
                  ) {
                    <pbe-rejection-reasons [shipment]="shipment" />
                  } @else {
                    <div
                      class="text-xs text-slate-600 mt-2"
                      [innerHTML]="
                        'general.shipmentStatus.long.' +
                          shipment.arrivalAtExitStatus
                          | translate: { inspectionType: inspectionType().data }
                      "
                    ></div>
                  }
                </div>
              </div>

              <h2 class="text-lg font-medium mt-8 mb-4">
                {{
                  "services.arrivalAtExit.createPage.headers.arrival"
                    | translate
                }}
              </h2>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalLocation"
                    | translate
                }}</label>
                <div>
                  {{ getArrivalLocationAsString() }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.creationDate"
                    | translate
                }}</label>
                <div>
                  {{ shipment.creationDate | date: "dd-MM-yyyy HH:mm" }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalTimeAndDate"
                    | translate
                }}</label>
                @if (shipment.arrivalTimeAndDate !== null) {
                  <div>
                    {{ shipment.arrivalTimeAndDate | date: "dd-MM-yyyy HH:mm" }}
                  </div>
                } @else {
                  <div>
                    {{
                      "services.arrivalAtExit.detailsPage.detailsTab.emptyValues.arrivalTimeAndDate"
                        | translate
                    }}
                  </div>
                }
              </div>
              @if (shipment.registeredByOrg !== shipment.assignedToOrg) {
                <div>
                  @if (userCanAssignCargoHandlingAgent) {
                    <h2 class="text-lg font-medium mt-8 mb-4">
                      {{
                        "services.arrivalAtExit.createPage.headers.agent"
                          | translate
                      }}
                    </h2>
                    <div class="mb-3 text-sm">
                      <label class="font-medium">{{
                        "services.arrivalAtExit.detailsPage.detailsTab.fields.cargoHandlingAgent"
                          | translate
                      }}</label>
                      <div>{{ getCargoHandlingAgentAsString() }}</div>
                    </div>
                  } @else {
                    <h2 class="text-lg font-medium mt-8 mb-4">
                      {{
                        "services.arrivalAtExit.createPage.headers.terminal"
                          | translate
                      }}
                    </h2>
                    <div class="mb-3 text-sm">
                      <label class="font-medium">{{
                        "services.arrivalAtExit.detailsPage.detailsTab.fields.terminal"
                          | translate
                      }}</label>
                      <div>{{ getTerminalAsString() }}</div>
                      <p class="mt-3">
                        {{
                          "services.arrivalAtExit.detailsPage.detailsTab.createdByTerminal"
                            | translate
                        }}
                      </p>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
      }

      @if (activeTabIndex === 1) {
        <div class="mt-6 max-w-3xl mx-auto flex flex-col gap-y-6">
          <div class="flex flex-row justify-between items-center">
            <form class="flex-grow">
              <div class="relative mt-[-3rem] inline-block h-4 w-72">
                <div class="flex flex-col mt-3 w-full">
                  <mat-form-field>
                    <input matInput placeholder="Search" id="search" />
                  </mat-form-field>
                </div>
              </div>
            </form>

            <button
              mat-button
              color="primary"
              class="!p-0"
              [icon]="faAngleDown"
              iconPos="right"
            >
              All events
            </button>
          </div>

          <div
            class="bg-white shadow-sm border p-8 max-h-[65vh] overflow-y-auto"
          >
            <div class="w-full flex flex-col gap-5">
              @if (shipmentHistory().data) {
                @for (history of shipmentHistory().data; track history.id) {
                  <pbe-shipment-history
                    [history]="history"
                    [isMostRecent]="$index === 0"
                  ></pbe-shipment-history>
                }
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>`,
})
export class ArrivalAtExitCustomerServiceDetailsPageComponent
  implements OnChanges
{
  @Input({ required: true }) shipment!: ShipmentDto;

  faTimes = faTimes;
  faArrowRightFromBracket = faArrowRightFromBracket;
  faAngleDown = faAngleDown;

  arrivalLocationsService = inject(ArrivalLocationService);

  organisationService = inject(OrganisationService);

  inspectionTypeService = inject(InspectionTypeService);
  inspectionTypeQuery =
    this.inspectionTypeService.getInspectionTypeForShipmentId(null);
  inspectionType = this.inspectionTypeQuery.result;

  shipmentHistoryService = inject(ShipmentHistoryService);
  shipmentHistoryQuery =
    this.shipmentHistoryService.getHistoryForShipmentId(null);
  shipmentHistory = this.shipmentHistoryQuery.result;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() detailsClose = new EventEmitter();

  @Input() userCanAssignCargoHandlingAgent = false;

  activeTabIndex = 0;
  tabs = ["details", "history"];

  renderer = inject(Renderer2);
  appConfigService = inject(AppConfigService);

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;
      this.inspectionTypeQuery.updateOptions(
        this.inspectionTypeService.inspectionTypeQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );

      this.shipmentHistoryQuery.updateOptions(
        this.shipmentHistoryService.shipmentHistoryQueryOptions(
          shipment.shipmentId,
        ),
      );
    }
  }

  onClose() {
    this.detailsClose.emit();
  }

  getArrivalLocationAsString(): string {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );
    if (!arrivalLocation) {
      return "";
    }
    return formatArrivalLocationAddress(arrivalLocation);
  }

  getCargoHandlingAgentAsString(): string {
    const cargoHandlingAgent =
      this.organisationService.getCargoHandlingAgentForId(
        this.shipment.assignedToOrg,
      );
    if (!cargoHandlingAgent) {
      return "";
    }
    return cargoHandlingAgent.name;
  }

  getTerminalAsString(): string {
    const terminal = this.organisationService.getTerminalForId(
      this.shipment.registeredByOrg,
    );
    if (!terminal) {
      return "";
    }
    return terminal.name;
  }

  openMessageManagement(messageReferenceId: string): void {
    const form = this.renderer.createElement("form");
    form.action = `${this.appConfigService.getConfig().wpcsBaseUrl}/messagecontrol/searchmessage.do`;
    form.method = "POST";
    form.target = "messagemanagement";
    form.style.display = "none"; // Make sure the form is not visible on the screen

    const creationFromDate = startOfDay(subYears(new Date(), 1));
    const creationToDate = endOfDay(new Date());

    const data: Record<string, string | boolean> = {
      currentActionRouter: "messageOverview",
      actionRouter: "messageOverview",
      actionName: "filterMessagesPM",
      actionValue: true,
      screenId: "S087",
      fndMessageCreationFromDateTime: format(
        creationFromDate,
        "dd-MM-yyyy HH:mm",
      ),
      fndMessageCreationFromDate: format(creationFromDate, "dd-MM-yyyy"),
      fndMessageCreationFromTime: format(creationFromDate, "HH:mm"),
      fndMessageCreationToDateTime: format(creationToDate, "dd-MM-yyyy HH:mm"),
      fndMessageCreationToDate: format(creationToDate, "dd-MM-yyyy"),
      fndMessageCreationToTime: format(creationToDate, "HH:mm"),
      fndProcessId: messageReferenceId,
      deeplink: true,
    };

    for (const key in data) {
      const input = this.renderer.createElement("textarea");
      input.name = key;
      input.value = data[key];
      form.appendChild(input);
    }

    this.renderer.appendChild(document.body, form);
    form.submit();
    this.renderer.removeChild(document.body, form);
  }
}
