import { Route } from "@angular/router";
import { ArrivalAtExitComponent } from "./arrival-at-exit/arrival-at-exit.component";
import { ArrivalAtExitStatus } from "./arrival-at-exit/shipment.service";
import {
  ServiceSubscriptionDescriptionCode,
  UserRights,
} from "./core/authentication/authentication.service";
import { isAuthenticatedGuard } from "./core/authentication/is-authenticated.guard";
import { canAccessUserRightGuardedRoute } from "./core/guards/can-access-user-right-guarded-route.guard";
import { hasAccessToOneOfServicesGuard } from "./core/guards/has-access-to-service.guard";
import { ExportManifestComponent } from "./export-manifest/export-manifest.component";
import { PreferencesComponent } from "./preferences/preferences.component";

export const appRoutes: Route[] = [
  {
    path: "arrival-at-exit",
    canActivate: [
      isAuthenticatedGuard,
      hasAccessToOneOfServicesGuard([
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForCargoHandlingAgent,
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForTerminal,
        ServiceSubscriptionDescriptionCode.ArrivalAtExitForAdmin,
      ]),
    ],
    children: [
      { path: "all", component: ArrivalAtExitComponent },
      {
        path: "draft",
        component: ArrivalAtExitComponent,
        data: {
          quickFilter: [
            ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS,
          ],
        },
      },
      {
        path: "flagged",
        component: ArrivalAtExitComponent,
        data: {
          quickFilter: [
            ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS,
            ArrivalAtExitStatus.INSPECTION_OF_EXPORT_SHIPMENTS_AT_CUSTOMS,
            ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE,
          ],
        },
      },
      { path: "**", redirectTo: "all" },
    ],
  },
  {
    path: "export-manifest",
    canActivate: [
      isAuthenticatedGuard,
      hasAccessToOneOfServicesGuard([
        ServiceSubscriptionDescriptionCode.ExportManifestForCargoHandlingAgent,
        ServiceSubscriptionDescriptionCode.ExportManifestForTerminal,
      ]),
    ],
    children: [
      { path: "all", component: ExportManifestComponent },
      {
        path: "draft",
        component: ExportManifestComponent,
        data: {
          quickFilter: [],
        },
      },
      {
        path: "urgent",
        component: ExportManifestComponent,
        data: {
          quickFilter: [],
        },
      },
      {
        path: "rejected",
        component: ExportManifestComponent,
        data: {
          quickFilter: [],
        },
      },
      { path: "**", redirectTo: "draft" },
    ],
  },
  {
    path: "preferences",
    component: PreferencesComponent,
    canActivate: [
      isAuthenticatedGuard,
      canAccessUserRightGuardedRoute(UserRights.SeePreferences),
    ],
  },
  { path: "**", redirectTo: "arrival-at-exit" },
];
