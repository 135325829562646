import {
  Component,
  QueryList,
  ViewChildren,
  effect,
  inject,
} from "@angular/core";

import { CommonModule } from "@angular/common";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import {
  AutocompleteSuggestion,
  PBAutocompleteComponent,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import Fuse from "fuse.js";
import { debounceTime, take } from "rxjs/operators";
import { ArrivalLocationService } from "../../arrival-at-exit/arrival-location.service";
import {
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../components";
import { NotificationService } from "../../core/services/notification.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { autocompleteSuggestionValidator } from "../../core/validators/autocomplete-suggestion.validator";
import { PreferencesService } from "../services/preferences.service";
import { EmailAddressListComponent } from "./email-address-list.component";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["label"],
};
@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    EmailAddressListComponent,
    ButtonIconDirective,
    MatButtonModule,
    MatInputModule,
    MatListModule,
  ],
  selector: "pbe-arrival-location-aliases-form",
  template: `@if (arrivalLocationAliases !== undefined) {
    <form [formGroup]="form" class="flex flex-col mt-4 max-w-xl">
      <div formArrayName="arrivalLocationAliases">
        @for (
          group of form.controls.arrivalLocationAliases.controls;
          track $index
        ) {
          <div class="relative" [formGroupName]="$index">
            <div class="flex flex-row items-center gap-2">
              <pb-autocomplete
                class="arrival-at-exit-default-arrival-location-autocomplete w-96"
                optionalLabel=""
                [label]="
                  $index === 0
                    ? ('preferences.arrivalAtExit.fields.arrivalLocationAlias'
                      | translate)
                    : undefined
                "
                [placeholder]="
                  'preferences.arrivalAtExit.fields.arrivalLocationAliasPlaceholderLocation'
                    | translate
                "
                [formGroup]="group"
                controlName="location"
                [suggestions]="
                  getFilteredSuggestionsForArrivalLocationAliases(
                    group.controls.location.value
                  )
                "
              ></pb-autocomplete>
              <mat-form-field class="!mt-2">
                <input
                  matInput
                  formControlName="alias"
                  type="text"
                  [placeholder]="
                    'preferences.arrivalAtExit.fields.arrivalLocationAliasPlaceholderAlias'
                      | translate
                  "
                />
              </mat-form-field>
            </div>

            <div class="absolute -right-[35px] top-1/2 -translate-y-1/2">
              <button
                mat-icon-button
                [icon]="faTimes"
                (click)="removeArrivalLocationAlias($index)"
              ></button>
            </div>
          </div>
        }
      </div>

      <div class="flex justify-end items-end gap-4">
        <button
          mat-button
          color="primary"
          class="!p-0"
          [icon]="faPlus"
          (click)="addAnotherArrivalLocationAlias()"
          [disabled]="isAddAnotherArrivalLocationAliasButtonDisabled"
        >
          {{
            "preferences.arrivalAtExit.fields.addAnotherButtonText" | translate
          }}
        </button>
        <button
          mat-button
          color="primary"
          class="!p-0"
          [disabled]="
            form.controls.arrivalLocationAliases.invalid ||
            form.controls.arrivalLocationAliases.pristine
          "
          (click)="saveArrivalLocationAliases()"
        >
          {{
            "preferences.arrivalAtExit.fields.saveArrivalLocationAliasesButtonText"
              | translate
          }}
        </button>
      </div>
    </form>
  }`,
  styles: [
    `
      // This modifies the margin-top from the autocomplete field of default arrival locations to match the Figma design
      ::ng-deep .arrival-at-exit-default-arrival-location-autocomplete {
        .mat-form-field-appearance-outline {
          margin-top: 0.5rem;
        }
      }
    `,
  ],
})
export class OrganisationArrivalLocationAliasesFormComponent {
  @ViewChildren(PBAutocompleteComponent)
  autocompleteComponents: QueryList<PBAutocompleteComponent> =
    new QueryList<PBAutocompleteComponent>();

  faPlus = faPlus;
  faTimes = faTimes;

  locations = inject(ArrivalLocationService).getArrivalLocations().result;
  locationsService = inject(ArrivalLocationService);
  preferencesService = inject(PreferencesService);
  notificationService = inject(NotificationService);

  suggestionsForArrivalLocations: AutocompleteSuggestion[] = [];

  searchableListForArrivalLocations?: Fuse<{ value: string; label: string }>;

  arrivalLocationAliases =
    this.preferencesService.getOrganisationArrivalLocationAliases().result;
  setArrivalLocationAliases =
    this.preferencesService.setOrganisationArrivalLocationAliases();

  form = new FormGroup({
    arrivalLocationAliases: new FormArray<
      FormGroup<{
        location: FormControl<{ label: string; value: string } | null>;
        alias: FormControl<string | null>;
      }>
    >([]),
  });

  constructor() {
    effect(() => {
      const mappedValues =
        this.locations().data?.map((location) => ({
          label: formatArrivalLocationAddress(location),
          value: location.id,
        })) ?? [];

      this.suggestionsForArrivalLocations = mappedValues;

      this.searchableListForArrivalLocations = new Fuse(
        this.suggestionsForArrivalLocations,
        fuseOptions,
      );
    });

    effect(() => {
      const aliases = this.arrivalLocationAliases().data;
      const locations = this.locations().data;
      if (aliases !== undefined && locations !== undefined) {
        const arrivalLocationAliases = Object.entries(aliases)
          .map(([locationId, alias]) => {
            const location = locations.find((l) => l.id === locationId);
            if (!location) {
              return null;
            }

            const locationAddress = formatArrivalLocationAddress(location);

            return new FormGroup({
              location: new FormControl(
                {
                  label: locationAddress,
                  value: locationId,
                },
                [autocompleteSuggestionValidator()],
              ),
              alias: new FormControl(alias, [Validators.required]),
            });
          })
          .filter((alias) => alias !== null);

        if (arrivalLocationAliases.length > 0) {
          this.form.setControl(
            "arrivalLocationAliases",
            new FormArray(arrivalLocationAliases as any),
          );
        } else {
          this.form.setControl(
            "arrivalLocationAliases",
            new FormArray([
              new FormGroup({
                location: new FormControl({
                  label: "",
                  value: "",
                }),
                alias: new FormControl("", [Validators.required]),
              }),
            ]),
          );
        }
      }
    });
  }

  getFilteredSuggestionsForArrivalLocationAliases(
    query: unknown,
  ): { value: string; label: string }[] {
    const currentArrivalLocations =
      this.form.controls.arrivalLocationAliases.value.map(
        (v) => v.location?.value,
      );

    if (typeof query !== "string" || query.length < 2) {
      return this.suggestionsForArrivalLocations.filter(
        (suggestion) => !currentArrivalLocations?.includes(suggestion.value),
      );
    }

    return (
      this.searchableListForArrivalLocations
        ?.search(query)
        .map((r) => r.item)
        .filter(
          (suggestion) => !currentArrivalLocations?.includes(suggestion.value),
        ) ?? []
    );
  }

  public get isAddAnotherArrivalLocationAliasButtonDisabled(): boolean {
    return this.form.controls.arrivalLocationAliases.controls.length >= 20;
  }

  removeArrivalLocationAlias(index: number) {
    if (this.form.controls.arrivalLocationAliases.controls.length > 1) {
      this.form.controls.arrivalLocationAliases.removeAt(index);
    } else {
      this.form.controls.arrivalLocationAliases.reset([
        { location: { label: "", value: "" }, alias: "" },
      ]);
    }

    this.saveArrivalLocationAliases();
  }

  addAnotherArrivalLocationAlias() {
    this.form.controls.arrivalLocationAliases.push(
      new FormGroup({
        location: new FormControl({
          label: "",
          value: "",
        }),
        alias: new FormControl("", [Validators.required]), // Validator should be added for uniqueness
      }),
    );

    // Automatically focus the input field of the newly added default arrival location
    this.autocompleteComponents.changes
      .pipe(debounceTime(50), take(1))
      .subscribe((changes) =>
        (
          changes.last as PBAutocompleteComponent
        ).inputRef?.nativeElement.focus(),
      );
  }

  getArrivalLocationAliasMapFromFormValue() {
    const valueAsArray = this.form.controls.arrivalLocationAliases.value as {
      location: { label: string; value: string };
      alias: string;
    }[];

    return valueAsArray.reduce(
      (acc, { location, alias }) => {
        if (location.value.length > 0 && alias.length > 0) {
          acc[location.value] = alias;
        }
        return acc;
      },
      {} as Record<string, string>,
    );
  }

  async saveArrivalLocationAliases() {
    const arrivalLocationAliases =
      this.getArrivalLocationAliasMapFromFormValue();
    await this.setArrivalLocationAliases.mutateAsync(arrivalLocationAliases);
    this.form.controls.arrivalLocationAliases.markAsPristine();
  }
}
