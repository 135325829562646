import { Injectable, inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from "./confirmation-dialog.component";
import { DateRangePickerDialogComponent } from "./date-range-picker-dialog.component";
import { SessionExpirationDialogComponent } from "./session-expiration-dialog.component";

export const DEFAULT_NOTIFICATION_DURATION = 3000;

@Injectable({ providedIn: "root" })
export class DialogService {
  #dialog = inject(MatDialog);
  translateService = inject(TranslateService);

  showDeleteConfirmationDialog(
    title: string,
    description: string,
  ): Promise<boolean> {
    return new Promise((res) => {
      const dialogRef = this.#dialog.open(ConfirmationDialogComponent, {
        data: {
          title,
          description,
          confirmationButtonLabel: this.translateService.instant(
            "general.dialogs.delete.confirm",
          ),
          confirmationButtonColor: "warn",
          cancelButtonLabel: this.translateService.instant(
            "general.dialogs.delete.cancel",
          ),
        } as ConfirmationDialogData,
      });

      dialogRef.afterClosed().subscribe((result) => res(result === true));
    });
  }

  showSessionExpirationDialog() {
    const dialogRef = this.#dialog.open(SessionExpirationDialogComponent);
    dialogRef.componentInstance.dialogRef = dialogRef;
  }

  showDateRangePickerDialog(
    initialValue: [Date, Date] | null,
  ): Promise<[Date, Date] | undefined | ""> {
    return new Promise((res) => {
      const dialogRef = this.#dialog.open(DateRangePickerDialogComponent, {
        data: { initialValue },
      });

      dialogRef.afterClosed().subscribe((result) => res(result));
    });
  }
}
