import { Component, Inject } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { ThemePalette } from "@angular/material/core";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from "@angular/material/dialog";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { ButtonIconDirective } from "@portbase/material/button";

export interface ConfirmationDialogData {
  title: string;
  description: string;
  confirmationButtonLabel: string;
  confirmationButtonColor: ThemePalette;
  cancelButtonLabel: string;
}

@Component({
  selector: "pbe-confirmation-dialog",
  standalone: true,
  imports: [
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    ButtonIconDirective,
  ],
  template: `<div class="p-5 flex flex-col items-stretch mb-4">
      <div class="flex flex-row justify-between items-start">
        <h3 class="text-lg font-medium mb-2">{{ data.title }}</h3>
        <button mat-dialog-close mat-icon-button [icon]="faTimes"></button>
      </div>
      <p class="text-sm">{{ data.description }}</p>
    </div>
    <mat-dialog-actions [align]="'end'" class="p-0">
      <button mat-stroked-button mat-dialog-close>
        {{ data.cancelButtonLabel }}
      </button>
      <button
        mat-raised-button
        [mat-dialog-close]="true"
        [color]="data.confirmationButtonColor"
      >
        {{ data.confirmationButtonLabel }}
      </button>
    </mat-dialog-actions>`,
})
export class ConfirmationDialogComponent {
  faTimes = faTimes;

  constructor(@Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData) {}
}
