import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatListModule } from "@angular/material/list";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PBDatePickerModule } from "@portbase/material/date-picker";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatListModule,
    ButtonIconDirective,
    TippyDirective,
    FontAwesomeModule,
    MatButtonModule,
    PBDatePickerModule,
  ],
  selector: "pbe-filter-list-item",
  template: `<button
    class="flex flex-col items-stretch justify-start w-full px-4 py-2 hover:bg-gray-200 mb-2 min-h-8 rounded-sm"
    [class.bg-gray-100]="filter !== null"
  >
    <div class="flex flex-row justify-between items-center">
      <span class="font-medium text-sm">{{ label }}</span>
      <button mat-icon-button (click)="clearButtonClicked($event)">
        <fa-icon [icon]="filter === null ? faPlus : faTimes"></fa-icon>
      </button>
    </div>
    <span
      *ngIf="filter !== null"
      class="text-xs text-slate-500 text-left mb-2 text-ellipsis overflow-hidden whitespace-nowrap"
      >{{ formattedFilter }}</span
    >
  </button>`,
})
export class FilterListItemComponent<T> {
  @Input({ required: true }) filter!: null | T;
  @Input({ required: true }) label!: string;
  @Input({ required: true }) formattedFilter!: string;

  @Output() clearButtonClick = new EventEmitter();

  clearButtonClicked(event: Event) {
    if (this.filter !== null) {
      event.stopPropagation();
      this.clearButtonClick.emit();
    }
  }

  faPlus = faPlus;
  faTimes = faTimes;
}
