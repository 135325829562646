import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCopy,
  faPlus,
  faTrashCan,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
    ButtonIconDirective,
  ],
  selector: "pbe-export-manifest-shipments-table-controls",
  template: `
    <div
      class="w-full h-16 flex justify-between flex-row items-center px-8 border-y border-gray-300 bg-[#F7FAFC]"
    >
      <h1 class="font-sans font-medium text-lg">Export shipments</h1>

      @if (!hideActions) {
        <div class="flex flex-row gap-2.5">
          @if (shipmentsPendingDeletion > 0) {
            <div class="flex flex-row justify-center items-center gap-2.5">
              <p class="font-sans font-normal text-sm text-gray-800">
                {{
                  "services.exportManifest.detailsPage.shipments.header.selectedShipmentsCount"
                    | translate: { count: shipmentsPendingDeletion }
                }}
              </p>

              <button
                class="border border-red-200 shadow-sm rounded text-red-700 h-10 px-4 py-2 text-sm font-medium hover:border-red-300 hover:bg-red-200 active:bg-red-300 bg-white flex justify-center gap-1.5 items-center"
                (click)="deleteSelectedShipments.emit()"
              >
                <fa-icon [icon]="faTrashCan" class="text-base"></fa-icon>

                {{
                  "services.exportManifest.detailsPage.shipments.header.deleteShipmentsButton"
                    | translate
                }}
              </button>
            </div>
          }

          <button
            mat-stroked-button
            [icon]="faPlus"
            type="button"
            (click)="addShipment.emit()"
            [disabled]="disableActions"
          >
            {{
              "services.exportManifest.detailsPage.shipments.header.addAnotherShipmentButton"
                | translate
            }}
          </button>
          <button
            mat-stroked-button
            [icon]="faUpload"
            type="button"
            [disabled]="disableActions"
          >
            {{
              "services.exportManifest.detailsPage.shipments.header.uploadExcelFileButton"
                | translate
            }}
          </button>
          <button
            mat-stroked-button
            [icon]="faCopy"
            type="button"
            (click)="openCopyShipmentsDialog.emit()"
            [disabled]="disableActions"
          >
            {{
              "services.exportManifest.detailsPage.shipments.header.copyFromExistingButton"
                | translate
            }}
          </button>
        </div>
      }
    </div>
  `,
})
export class ExportManifestShipmentsTableControlsComponent {
  @Input() disableActions = false;
  @Input() hideActions = false;

  @Input() shipmentsPendingDeletion = 0;

  @Output() addShipment = new EventEmitter<void>();
  @Output() openCopyShipmentsDialog = new EventEmitter<void>();
  @Output() deleteSelectedShipments = new EventEmitter<void>();

  faPlus = faPlus;
  faCopy = faCopy;
  faUpload = faUpload;
  faTrashCan = faTrashCan;
}
