import { CommonModule } from "@angular/common";
import { Component, effect, inject, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import {
  AutocompleteSuggestion,
  PBAutocompleteModule,
} from "@portbase/material/autocomplete";
import Fuse from "fuse.js";
import { OrganisationService } from "../../core/services/organisation.service";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["label"],
};

@Component({
  selector: "pbe-cargo-handling-agent-input",
  standalone: true,
  imports: [
    CommonModule,
    FontAwesomeModule,
    TranslateModule,
    PBAutocompleteModule,
  ],
  template: ` <pb-autocomplete
    [label]="'services.arrivalAtExit.createPage.fields.agent' | translate"
    [placeholder]="
      'services.arrivalAtExit.createPage.fields.agentPlaceholder' | translate
    "
    [formGroup]="form"
    controlName="agent"
    [optionalLabel]="'general.forms.optionalLabel' | translate"
    [suggestions]="
      getFilteredSuggestionsForCargoHandlingAgents(form.value.agent)
    "
  ></pb-autocomplete>`,
})
export class PortbaseExportCargoHandlingAgentInputComponent {
  @Input({ required: true }) form!: FormGroup;

  organisationService = inject(OrganisationService);

  cargoHandlingAgents =
    inject(OrganisationService).getCargoHandlingAgents().result;

  suggestionsForCargoHandlingAgents: AutocompleteSuggestion[] = [];
  searchableListForCargoHandlingAgents?: Fuse<{ value: string; label: string }>;

  constructor() {
    effect(() => {
      const mappedValues =
        this.cargoHandlingAgents().data?.map((agent) => ({
          label: agent.name,
          value: agent.shortName,
        })) ?? [];
      this.suggestionsForCargoHandlingAgents = mappedValues;

      this.searchableListForCargoHandlingAgents = new Fuse(
        this.suggestionsForCargoHandlingAgents,
        fuseOptions,
      );
    });
  }

  getFilteredSuggestionsForCargoHandlingAgents(
    query: string | undefined | null | { label: string; value: string },
  ): { value: string; label: string }[] {
    return query && typeof query === "string" && query.length > 2
      ? this.searchableListForCargoHandlingAgents
          ?.search(query)
          .map((r) => r.item) ?? []
      : this.suggestionsForCargoHandlingAgents;
  }
}
