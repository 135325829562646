import { CommonModule } from "@angular/common";
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { TranslateModule } from "@ngx-translate/core";
import { RejectionReasonsService } from "../rejection-reasons.service";
import { ShipmentDto } from "../shipment.service";

@Component({
  selector: "pbe-rejection-reasons",
  standalone: true,
  imports: [CommonModule, TranslateModule],
  template: `<div class="text-xs text-slate-600 mt-4">
    @if (rejectionReasons().data) {
      <h1>
        {{ "services.arrivalAtExit.editPage.rejection.title" | translate }}
      </h1>
      <div class="text-xs text-slate-600 mt-2">
        <ul class="list-disc ml-4">
          @for (reason of rejectionReasons().data!.reasons; track reason.code) {
            <li>
              <strong class="font-medium">{{ reason.code }}</strong> -
              {{ "rejectionReasons." + reason.code | translate }}
            </li>
          }
        </ul>
        <p class="mt-3">
          {{
            "services.arrivalAtExit.editPage.rejection.moreDetails" | translate
          }}
          <a
            class="underline"
            target="_blank"
            [href]="
              'rejectionReasons.' +
                (rejectionReasons().data?.rejectionType === 'DEC'
                  ? 'codebookUrl'
                  : 'codebookDrnUrl') | translate
            "
          >
            {{
              "services.arrivalAtExit.editPage.rejection.moreDetailsLink"
                | translate
            }}
          </a>
        </p>
      </div>
    }
  </div>`,
})
export class PortbaseExportRejectionReasonsComponent implements OnChanges {
  @Input({ required: true }) shipment!: ShipmentDto;

  rejectionReasonsService = inject(RejectionReasonsService);
  rejectionReasonsQuery =
    this.rejectionReasonsService.getRejectionReasonsForShipmentId(null);
  rejectionReasons = this.rejectionReasonsQuery.result;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;

      this.rejectionReasonsQuery.updateOptions(
        this.rejectionReasonsService.rejectionReasonsQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );
    }
  }
}
