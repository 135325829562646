import { CommonModule } from "@angular/common";
import { Component, DestroyRef, Input, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatOptionModule } from "@angular/material/core";
import { MatListModule } from "@angular/material/list";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule } from "@ngx-translate/core";
import { CheckboxChangeEvent, CheckboxModule } from "primeng/checkbox";
import { TableModule } from "primeng/table";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../../arrival-at-exit/components/status-indicator.component";
import { TextareaAutoresizeDirective } from "../../../../directives/textarea-autoresize.directive";
import { PortbaseExportEmDocumentNumberFormInputComponent } from "../../../components/document-number-form-input.component";
import { PortbaseExportEmDocumentTypeFormInputComponent } from "../../../components/document-type-form-input.component";
import { PortbaseExportEmPartshipmentFormSelectComponent } from "../../../components/partshipment-form-select.component";
import { PortbaseExportEmReferenceFormInputComponent } from "../../../components/reference-form-input.component";
import { PortbaseExportEmWeightFormInputComponent } from "../../../components/weight-form-input.component";
import { ExportManifestShipmentDocumentType } from "../../../export-manifest.service";
import { isMRNDocumentType } from "../../../validators/document-number.validator";
import { ExportManifestShipmentFormControls } from "../export-shipments.component";
import { ExportManifestShipmentsTableControlsComponent } from "./export-shipments-table-controls.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportAaxStatusIndicatorComponent,
    ReactiveFormsModule,
    TextareaAutoresizeDirective,
    PortbaseExportEmDocumentTypeFormInputComponent,
    PortbaseExportEmDocumentNumberFormInputComponent,
    PortbaseExportEmReferenceFormInputComponent,
    PortbaseExportEmWeightFormInputComponent,
    PortbaseExportEmPartshipmentFormSelectComponent,
    ExportManifestShipmentsTableControlsComponent,
    TableModule,
    CheckboxModule,
    FontAwesomeModule,
    MatListModule,
    MatOptionModule,
    TippyDirective,
    FormsModule,
  ],
  selector: "pbe-export-manifest-shipments-table",
  template: `
    <form [formGroup]="formGroup" autocomplete="off">
      <p-table
        [value]="formGroup.controls.shipments.controls"
        formArrayName="shipments"
        class="em-shipments-table-container"
        responsiveLayout="scroll"
        [tableStyle]="{ 'min-width': '1600px' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th>
              <p-checkbox
                [(ngModel)]="selectAll"
                [ngModelOptions]="{ standalone: true }"
                [binary]="true"
                (onChange)="onSelectAllChange($event)"
                [disabled]="readonly"
              >
                <ng-template pTemplate="icon">
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6916 0.654148L4.1287 7.217L1.80851 4.89681C1.69868 4.78698 1.5206 4.78698 1.41075 4.89681L1.1456 5.16195C1.03578 5.27178 1.03578 5.44986 1.1456 5.55971L3.92984 8.34395C4.03967 8.45377 4.21774 8.45377 4.3276 8.34395L11.3545 1.31708C11.4643 1.20725 11.4643 1.02917 11.3545 0.91932L11.0893 0.654148C10.9795 0.54432 10.8014 0.54432 10.6916 0.654148Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                </ng-template>
              </p-checkbox>
            </th>
            <th class="w-[260px]">
              {{
                "services.exportManifest.createPage.fields.documentType"
                  | translate
              }}
              *
            </th>
            <th class="w-[260px]">
              {{
                "services.exportManifest.createPage.fields.documentNumber"
                  | translate
              }}
              *
            </th>
            <th class="w-[148px]">
              {{
                "services.exportManifest.createPage.fields.reference"
                  | translate
              }}
            </th>
            <th class="w-[120px] text-right pr-2">
              {{
                "services.exportManifest.createPage.fields.weight" | translate
              }}
            </th>
            <th class="w-[320px]">Commodity code</th>
            <th class="w-[160px]">Port of discharge</th>
            <th class="w-[136px]">
              {{
                "services.exportManifest.createPage.fields.partShipment"
                  | translate
              }}
            </th>
            <th class="w-[160px]">Status</th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-shipment let-index="rowIndex">
          <tr [formGroupName]="index">
            <td
              [ngClass]="{
                'disabled-cell': readonly
              }"
            >
              <p-checkbox formControlName="selected" [binary]="true">
                <ng-template pTemplate="icon">
                  <svg
                    width="12"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6916 0.654148L4.1287 7.217L1.80851 4.89681C1.69868 4.78698 1.5206 4.78698 1.41075 4.89681L1.1456 5.16195C1.03578 5.27178 1.03578 5.44986 1.1456 5.55971L3.92984 8.34395C4.03967 8.45377 4.21774 8.45377 4.3276 8.34395L11.3545 1.31708C11.4643 1.20725 11.4643 1.02917 11.3545 0.91932L11.0893 0.654148C10.9795 0.54432 10.8014 0.54432 10.6916 0.654148Z"
                      fill="white"
                      stroke="white"
                    />
                  </svg>
                </ng-template>
              </p-checkbox>
            </td>

            <td
              class="border border-gray-300 focusable-cell group hover:border-gray-500 z-10 hover:z-20 py-3 pr-2 pl-4"
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'documentType'),
                'error-cell': hasErrors(shipment, 'documentType')
              }"
            >
              <pbe-em-document-type-form-input
                [formGroup]="shipment"
                controlName="documentType"
              ></pbe-em-document-type-form-input>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'documentNumber'),
                'error-cell': hasErrors(shipment, 'documentNumber'),
                'warning-cell': hasWarnings(shipment, 'documentNumber')
              }"
            >
              <pbe-em-document-number-form-input
                [formGroup]="shipment"
                controlName="documentNumber"
                [ignoreLengthValidation]="
                  ignoreDocumentNumberLengthValidation(shipment)
                "
              ></pbe-em-document-number-form-input>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'referenceNumber'),
                'error-cell': hasErrors(shipment, 'referenceNumber')
              }"
            >
              <pbe-em-reference-form-input
                [formGroup]="shipment"
                controlName="referenceNumber"
                placeholder="e.g 123"
              ></pbe-em-reference-form-input>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'weight'),
                'error-cell': hasErrors(shipment, 'weight')
              }"
            >
              <pbe-em-weight-form-input
                [formGroup]="shipment"
                controlName="weight"
              ></pbe-em-weight-form-input>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': readonly
              }"
            >
              <span class="font-normal text-gray-800 text-sm">-</span>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': readonly
              }"
            >
              <span class="font-normal text-gray-800 text-sm">-</span>
            </td>

            <td
              class="group"
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'partShipment'),
                'error-cell': hasErrors(shipment, 'partShipment')
              }"
              [tp]="orderByOptionList"
              [tpVariation]="'popper'"
              [tpPlacement]="'bottom-start'"
              [tpPopperWidth]="135"
              [tpOffset]="[0, 0]"
              #tooltip="tippy"
              (mouseenter)="
                checkAndDisableTooltip(tooltip, shipment, 'partShipment')
              "
            >
              <pbe-em-partshipment-form-select
                [formGroup]="shipment"
                controlName="partShipment"
              >
                <ng-template #orderByOptionList>
                  <mat-action-list>
                    <mat-list-item
                      (click)="
                        shipment.get('partShipment').setValue(true);
                        tooltip.hide()
                      "
                    >
                      {{
                        "services.exportManifest.createPage.fields.partShipmentOptions.yes"
                          | translate
                      }}
                    </mat-list-item>
                    <mat-list-item
                      (click)="
                        shipment.get('partShipment').setValue(false);
                        tooltip.hide()
                      "
                    >
                      {{
                        "services.exportManifest.createPage.fields.partShipmentOptions.no"
                          | translate
                      }}
                    </mat-list-item>
                  </mat-action-list>
                </ng-template>
              </pbe-em-partshipment-form-select>
            </td>

            <td
              [ngClass]="{
                'disabled-cell': isDisabled(shipment, 'arrivalAtExitStatus')
              }"
            >
              @if (shipment.get("arrivalAtExitStatus").value) {
                <pbe-aax-status-indicator
                  [status]="shipment.get('arrivalAtExitStatus').value"
                ></pbe-aax-status-indicator>
              } @else {
                -
              }
            </td>
          </tr>
        </ng-template>
      </p-table>
    </form>
  `,
  styles: [
    `
      ::ng-deep .em-shipments-table-container table {
        @apply border-separate border-spacing-0;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-datatable
        .p-datatable-thead
        > tr
        > th {
        @apply font-sans font-medium text-sm text-gray-800 py-2.5 bg-[#F7FAFC] border-b border-gray-300;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-datatable
        .p-datatable-tbody
        > tr
        > td {
        @apply border border-gray-300 focusable-cell hover:border-gray-500 py-3 pr-2 pl-4;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-datatable
        .p-datatable-tbody
        > tr
        > td.error-cell {
        border-color: #c40000 !important;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-datatable
        .p-datatable-tbody
        > tr
        > td.warning-cell:not(.error-cell) {
        border-color: #facc15 !important;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-datatable
        .p-datatable-tbody
        > tr
        > td.disabled-cell {
        @apply border-gray-300 bg-[#eaf0f4] cursor-not-allowed;
      }

      ::ng-deep
        .em-shipments-table-container
        .p-checkbox
        .p-checkbox-box
        .p-icon {
        width: 13px;
        height: auto;
        margin-left: 0px;
        margin-top: 0px;
      }

      .focusable-cell:not(
          .disabled-cell,
          .error-cell,
          .warning-cell
        ):focus-within,
      .focusable-cell:not(.disabled-cell, .error-cell, .warning-cell):active {
        border-color: #004ab1;
      }
    `,
  ],
})
export class ExportManifestShipmentsTableComponent implements OnInit {
  @Input({ required: true }) formGroup!: FormGroup<{
    shipments: FormArray<FormGroup<ExportManifestShipmentFormControls>>;
  }>;

  @Input()
  readonly = false;

  selectAll = false;

  constructor(private destroyRef: DestroyRef) {}

  ngOnInit(): void {
    this.formGroup.controls.shipments.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateSelectAllState();
      });
  }

  ignoreDocumentNumberLengthValidation(element: FormGroup) {
    const documentTypeControl = element.get("documentType");
    return isMRNDocumentType(
      documentTypeControl?.value as ExportManifestShipmentDocumentType,
    );
  }

  hasWarnings(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null || control.untouched) {
      return false;
    }

    const errors = Object.keys(control.errors ?? []);

    if (controlName === "documentNumber") {
      const warningKeys = [
        "invalidYear",
        "invalidCountry",
        "invalidAlphaNumeric",
        "invalidCheckDigit",
        "invalidChecksum",
        "minlength",
        "maxlength",
      ];

      return errors.some((key) => warningKeys.includes(key));
    }

    return false;
  }

  hasErrors(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null || control.untouched) {
      return false;
    }

    const errors = Object.keys(control.errors ?? []);

    if (controlName === "documentType") {
      const errorKeys = ["required", "invalidDocumentType"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "documentNumber") {
      const errorKeys = ["required", "duplicateDocumentNumber"];

      if (!this.ignoreDocumentNumberLengthValidation(element)) {
        errorKeys.push("minlength", "maxlength");
      }

      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "referenceNumber") {
      const errorKeys = ["maxlength"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "weight") {
      const errorKeys = ["required", "pattern"];
      return errors.some((key) => errorKeys.includes(key));
    }

    if (controlName === "partShipment") {
      const errorKeys = ["required", "pattern"];
      return errors.some((key) => errorKeys.includes(key));
    }

    return false;
  }

  isDisabled(element: FormGroup, controlName: string) {
    const control = element.get(controlName);

    if (control === null) {
      return false;
    }

    return control.disabled && !control.invalid;
  }

  checkAndDisableTooltip(
    tooltip: TippyDirective,
    formGroup: FormGroup,
    controlName: string,
  ) {
    const isControlDisabled = formGroup.get(controlName)?.disabled;

    if (isControlDisabled) {
      tooltip.disable();
    } else {
      tooltip.enable();
    }
  }

  onSelectAllChange(event: CheckboxChangeEvent) {
    this.formGroup.controls.shipments.controls.forEach((control) => {
      const selectedControl = control.get("selected");
      if (selectedControl && !selectedControl.disabled) {
        selectedControl.setValue(event.checked);
      }
    });

    this.updateSelectAllState();
  }

  updateSelectAllState() {
    const allSelected = this.formGroup.controls.shipments.controls.every(
      (control) => control.get("selected")?.value === true,
    );

    const partiallySelected = this.formGroup.controls.shipments.controls.some(
      (control) => control.get("selected")?.value === false,
    );

    this.selectAll = partiallySelected ? false : allSelected;
  }
}
