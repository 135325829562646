import { Component, computed, effect, inject } from "@angular/core";

import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { BeamerService } from "@portbase/beamer";
import { PBSidenavModule } from "@portbase/material/sidenav";
import { environment } from "../../environments/environment";
import {
  AuthenticationService,
  UserRights,
} from "../core/authentication/authentication.service";
import { PortbaseExportTopbarComponent } from "../core/topbar.component";
import { DataPrefetcherComponent } from "./data-prefetcher.component";
import { ArrivalAtExitCustomerServiceRightDrawerComponent } from "./views/customer-service-view/customer-service-right-drawer.component";
import { ArrivalAtExitCustomerServiceViewComponent } from "./views/customer-service-view/customer-service-view.component";
import { ArrivalAtExitOperationalRightDrawerComponent } from "./views/operational-view/operational-right-drawer.component";
import { ArrivalAtExitOperationalViewComponent } from "./views/operational-view/operational-view.component";

enum ArrivalAtExitView {
  Default,
  CustomerService,
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PBSidenavModule,
    PortbaseExportTopbarComponent,
    DataPrefetcherComponent,
    ArrivalAtExitOperationalViewComponent,
    ArrivalAtExitOperationalRightDrawerComponent,
    ArrivalAtExitCustomerServiceViewComponent,
    ArrivalAtExitCustomerServiceRightDrawerComponent,
  ],
  selector: "pbe-arrival-at-exit-page",
  template: `<div class="flex flex-col w-full h-full">
      <pbe-topbar>
        <div class="flex flex-row justify-between items-center w-full">
          <h1 class="text-xl text-gray-700 ml-6">
            {{ "services.arrivalAtExit.title" | translate }}
          </h1>
          @if (!isProduction) {
            <p class="text-xs mr-6">{{ userRoles().join(", ") }}</p>
          }
        </div>
      </pbe-topbar>

      @if (view() === ArrivalAtExitView.CustomerService) {
        <pbe-arrival-at-exit-customer-service-view></pbe-arrival-at-exit-customer-service-view>
      } @else {
        <pbe-arrival-at-exit-operational-view></pbe-arrival-at-exit-operational-view>
      }
    </div>

    @if (view() === ArrivalAtExitView.CustomerService) {
      <pbe-arrival-at-exit-customer-service-right-drawer></pbe-arrival-at-exit-customer-service-right-drawer>
    } @else {
      <pbe-arrival-at-exit-operational-right-drawer></pbe-arrival-at-exit-operational-right-drawer>
    }

    <pbe-data-prefetcher /> `,
})
export class ArrivalAtExitComponent {
  userRoles = inject(AuthenticationService).userRoles;
  userRights = inject(AuthenticationService).userRights;

  ArrivalAtExitView = ArrivalAtExitView;

  isProduction = environment.production;

  view = computed(() => {
    if (this.userRights().includes(UserRights.SeeCustomerServiceView)) {
      return ArrivalAtExitView.CustomerService;
    }

    return ArrivalAtExitView.Default;
  });

  authenticationService = inject(AuthenticationService);
  beamerService = inject(BeamerService);

  securityProfile = this.authenticationService.getSecurityProfile().result;

  constructor() {
    effect(() => {
      const userId = this.securityProfile().data?.iamcUserId;
      if (userId) {
        this.beamerService.updateUserId(userId, "melding-aankomst-ecs-lading");
      }
    });
  }
}
