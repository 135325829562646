import { CommonModule, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import {
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";

@Component({
  selector: "pbe-document-number-form-input",
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  template: `
    <div class="flex flex-col mt-3 w-full">
      <mat-form-field [formGroup]="form">
        <mat-label>
          {{
            "services.arrivalAtExit.createPage.fields.documentNumber"
              | translate
          }}
        </mat-label>
        <input
          matInput
          [id]="controlName"
          [formControlName]="controlName"
          oninput="this.value = this.value.trim().toUpperCase();"
        />
      </mat-form-field>
      <div class="h-4">
        @if (form.controls[controlName].invalid &&
        form.controls[controlName].touched) {
        <mat-error class="text-xs">
          {{
            "general.forms.errors.documentNumber." +
              getFirstError(form.controls[controlName].errors) | translate
          }}
        </mat-error>
        }
      </div>
    </div>
  `,
})
export class PortbaseExportDocumentNumberFormInputComponent {
  @Input({ required: true }) form!: FormGroup;
  @Input({ required: true }) controlName!: string;

  getErrorsAsArray(validationErrors: ValidationErrors | null) {
    return Object.keys(validationErrors ?? {});
  }

  getFirstError(validationErrors: ValidationErrors | null) {
    return this.getErrorsAsArray(validationErrors)[0];
  }
}
