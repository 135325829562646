import { Component, Input, inject } from "@angular/core";
import { MatTableModule } from "@angular/material/table";

import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import {
  AuthenticationService,
  UserRole,
} from "../../../../core/authentication/authentication.service";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../components/status-indicator.component";
import { ArrivalAtExitStatus, ShipmentDto } from "../../../shipment.service";
import { resolveDocumentType } from "../../../utils/resolve-document-type.utils";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PortbaseExportAaxStatusIndicatorComponent,
    MatTableModule,
    FontAwesomeModule,
    MatButtonModule,
  ],
  selector: "pbe-arrival-at-exit-operational-view-table",
  styleUrls: ["operational-view-table.component.scss"],
  template: `
    <table mat-table [dataSource]="shipments">
      <ng-container matColumnDef="assigned">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers." +
              (isTerminal() ? "assignedToOrg" : "registeredByOrg") | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ isTerminal() ? element.assignedToOrg : element.registeredByOrg }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentNumber">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers.documentNumber"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.documentNumber }}
        </td>
      </ng-container>

      <ng-container matColumnDef="documentType">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers.documentType"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ mapDocumentType(element.documentType) }}
        </td>
      </ng-container>
      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers.creationDate"
              | translate
          }}
        </th>
        <td mat-cell *matCellDef="let element">
          {{ element.creationDate | date: "dd-MM-yyyy" }}
        </td>
      </ng-container>
      <ng-container matColumnDef="reference">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers.reference"
              | translate
          }}
        </th>
        <td
          class="w-64 max-w-64 text-ellipsis overflow-hidden whitespace-nowrap"
          mat-cell
          *matCellDef="let element"
        >
          {{ element.reference }}
        </td>
      </ng-container>
      <ng-container matColumnDef="arrivalAtExitStatus">
        <th mat-header-cell *matHeaderCellDef>
          {{
            "services.arrivalAtExit.overview.table.headers.arrivalAtExitStatus"
              | translate
          }}
        </th>
        <td
          class="w-60 max-w-60 text-ellipsis overflow-hidden whitespace-nowrap"
          mat-cell
          *matCellDef="let element"
        >
          <pbe-aax-status-indicator
            [status]="element.arrivalAtExitStatus"
          ></pbe-aax-status-indicator>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button
            class="flex flex-row pl-3 float-right text-sm font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
            (click)="openDetailsPage(element)"
          >
            @if (canEdit(element)) {
              <span>{{
                "services.arrivalAtExit.overview.table.actions.edit" | translate
              }}</span>
            } @else {
              <span>{{
                "services.arrivalAtExit.overview.table.actions.details"
                  | translate
              }}</span>
            }
            <fa-icon class="ml-4" [icon]="faChevronRight"></fa-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  `,
})
export class ArrivalAtExitOperationalViewTableComponent {
  @Input({ required: true }) shipments!: ShipmentDto[];

  authenticationData = inject(AuthenticationService).authenticated;
  userRoles = inject(AuthenticationService).userRoles;

  router = inject(Router);
  route = inject(ActivatedRoute);

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  faChevronRight = faChevronRight;

  displayedColumns = [
    "assigned",
    "documentNumber",
    "documentType",
    "creationDate",
    "reference",
    "arrivalAtExitStatus",
    "actions",
  ];

  openDetailsPage(entity: ShipmentDto) {
    const shouldGoToEdit = this.canEdit(entity);

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        view: shouldGoToEdit ? "edit" : "details",
        id: entity.shipmentId,
      },
    });
  }

  canEdit(entity: ShipmentDto) {
    return (
      entity.registeredByOrg === this.authenticationData().data?.credentials &&
      (entity.arrivalAtExitStatus ===
        ArrivalAtExitStatus.ARRIVAL_AT_EXIT_NOT_YET_REPORTED_TO_CUSTOMS ||
        entity.arrivalAtExitStatus ===
          ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS)
    );
  }

  mapDocumentType(documentType: string) {
    return resolveDocumentType(documentType);
  }

  isTerminal() {
    return this.userRoles().includes(UserRole.TerminalOperator);
  }
}
