import { Component, Input, inject } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ArrivalLocationService } from "../../arrival-location.service";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
  ValueHolder,
} from "../../shipment-history.service";

@Component({
  selector: "pbe-history-item-details-changed",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  template: `
    <div class="flex flex-col gap-1">
      <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
            history.operationType | translate: { field: getHeader() }
        }}
      </p>
      <div class="flex gap-1 items-center">
        @if (getChanges().oldValue) {
          <div
            class="text-red-500 font-semibold text-sm font-sans px-1 text-center bg-red-100 rounded-md"
          >
            {{ getChanges().oldValue }}
          </div>

          <p class="text-gray-600 font-semibold">-></p>
        }

        <div
          class="text-green-500 font-semibold text-sm font-sans px-1 text-center bg-green-100 rounded-md"
        >
          {{ getChanges().newValue }}
        </div>
      </div>
    </div>
  `,
})
export class HistoryItemDetailsChangedComponent {
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_DETAILS_CHANGED>;

  faArrowRight = faRightLeft;

  headerMap: Record<string, string> = {
    documentType: "Document Type",
    documentNumber: "Document Number",
    reference: "Reference",
    arrivalLocationId: "Arrival Location",
  };

  arrivalLocationService = inject(ArrivalLocationService);

  getHeader(): string {
    const { attribute } = this.history.operationPayload;
    return this.headerMap[attribute] || "Unknown";
  }

  getChanges(): ValueHolder<string, string> {
    const { attribute, change } = this.history.operationPayload;

    if (attribute === "arrivalLocationId") {
      const oldArrivalLocation =
        this.arrivalLocationService.getArrivalLocationForId(change.oldValue);

      const newArrivalLocation =
        this.arrivalLocationService.getArrivalLocationForId(change.newValue);

      return {
        oldValue: `${oldArrivalLocation?.warehouseName} ${oldArrivalLocation?.houseNumber}, ${oldArrivalLocation?.zipCode}`,
        newValue: `${newArrivalLocation?.warehouseName} ${newArrivalLocation?.houseNumber}, ${newArrivalLocation?.zipCode}`,
      };
    }

    return change;
  }
}
