import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faRightLeft } from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { OrganisationService } from "../../../core/services/organisation.service";
import {
  ShipmentHistoryDto,
  ShipmentOperationType,
} from "../../shipment-history.service";

@Component({
  selector: "pbe-history-item-cargo-handling-agent-changed",
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule],
  template: `
    <div class="flex flex-col gap-1">
      <p class="flex gap-x-1.5 text-gray-700 font-sans font-medium text-base">
        {{
          "services.arrivalAtExit.detailsPage.historyTab.operations." +
            history.operationType | translate
        }}
      </p>
      <div class="flex gap-1 items-center">
        @if (oldCargoHandlingAgent().data) {
          <div
            class="text-red-500 font-semibold text-sm font-sans px-1 text-center bg-red-100 rounded-md"
          >
            {{ oldCargoHandlingAgent().data!.fullName }}
          </div>

          <p class="text-gray-600 font-semibold">-></p>
        }

        <div
          class="text-green-500 font-semibold text-sm font-sans px-1 text-center bg-green-100 rounded-md"
        >
          {{ newCargoHandlingAgent().data?.fullName }}
        </div>
      </div>
    </div>
  `,
})
export class HistoryItemCargoHandlingAgentChangedComponent
  implements OnChanges
{
  @Input({ required: true })
  history!: ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED>;

  faArrowRight = faRightLeft;

  organisationService = inject(OrganisationService);

  oldCargoHandlingAgentQuery =
    this.organisationService.getOrganisationProfileForId(null);
  oldCargoHandlingAgent = this.oldCargoHandlingAgentQuery.result;

  newCargoHandlingAgentQuery =
    this.organisationService.getOrganisationProfileForId(null);
  newCargoHandlingAgent = this.newCargoHandlingAgentQuery.result;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["history"].currentValue) {
      const history = changes["history"]
        .currentValue as ShipmentHistoryDto<ShipmentOperationType.AAX_SHIPMENT_CARGO_HANDLING_AGENT_CHANGED>;

      if (history.operationPayload.change.oldValue) {
        this.oldCargoHandlingAgentQuery.updateOptions(
          this.organisationService.organisationProfileQueryOptions(
            history.operationPayload.change.oldValue,
          ),
        );
      }

      if (history.operationPayload.change.newValue) {
        this.newCargoHandlingAgentQuery.updateOptions(
          this.organisationService.organisationProfileQueryOptions(
            history.operationPayload.change.newValue,
          ),
        );
      }
    }
  }
}
