import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faCircleDot,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAlertModule } from "@portbase/material/alert";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../components";
import { PortbaseDataItemComponent } from "../../components/data-item.component";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar.component";
import { PortbaseExportEmRejectionReasonsComponent } from "../components/rejection-reason.component";
import { PortbaseExportEmStatusIndicatorComponent } from "../components/status-indicator.component";
import {
  CreateExportManifestShipmentDto,
  ExportManifestDetailsDto,
  ExportManifestService,
  ExportManifestStatus,
} from "../export-manifest.service";
import { ExportManifestShipmentsComponent } from "./components/export-shipments.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsComponent,
    PBAutocompleteModule,
    PBAlertModule,
    PortbaseExportEmRejectionReasonsComponent,
  ],
  selector: "pbe-export-manifest-edit-page",
  template: `<div class="h-full flex flex-col items-stretch overflow-hidden">
    <mat-toolbar class="sticky top-0 z-10 bg-white">
      <div class="flex flex-row items-center gap-4">
        <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
        <h1 class="text-xl text-gray-700 ml-6 items-center">
          {{ exportManifest.callReferenceNumber }}
        </h1>
      </div>
    </mat-toolbar>

    <div
      class="bg-slate-100 flex-grow w-full flex-col overflow-auto overflow-x-hidden border-b border-b-gray-200"
    >
      @if (exportManifest !== undefined) {
        <div class="bg-white">
          <div
            class="w-full ml-8 pt-8 flex flex-col justify-between items-start gap-4"
          >
            <h2 class="text-lg font-medium">
              {{
                "services.exportManifest.detailsPage.manifestDetails.title"
                  | translate
              }}
            </h2>

            @if (
              exportManifest.manifestStatus !==
                ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS &&
              exportManifest.manifestStatus !==
                ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED
            ) {
              <div class="flex flex-col justify-center items-start gap-1">
                <p class="text-sm font-medium font-sans text-gray-800">
                  {{
                    "services.exportManifest.detailsPage.customsFeedback.manifestStatus"
                      | translate
                  }}
                </p>

                <pbe-em-status-indicator
                  [status]="exportManifest.manifestStatus"
                  [includeLongDescription]="true"
                ></pbe-em-status-indicator>

                @if (
                  exportManifest.manifestStatus ===
                  ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS
                ) {
                  <pbe-em-rejection-reason
                    [rejectionReason]="exportManifest.rejectionReason"
                  />
                }
              </div>
            }

            <div class="w-full pb-5">
              <div class="flex flex-row justify-between gap-4 w-full 2xl:w-1/2">
                <div class="w-full flex flex-col">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.vesselName'
                        | translate
                    "
                    [value]="exportManifest.vesselName"
                  />
                  <div class="flex flex-row gap-12">
                    <pbe-data-item
                      [label]="
                        'services.exportManifest.detailsPage.manifestDetails.fields.departureDate'
                          | translate
                      "
                      [value]="
                        (exportManifest.departureDate | date: 'dd-MM-yyyy') ??
                        '-'
                      "
                    />
                    <pbe-data-item
                      [label]="
                        'services.exportManifest.detailsPage.manifestDetails.fields.vesselStatus'
                          | translate
                      "
                      [value]="
                        'general.vesselStatus.short.' +
                          exportManifest.vesselStatus | translate
                      "
                    />
                  </div>
                </div>
                <div class="w-full flex flex-col">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.callReferenceNumber'
                        | translate
                    "
                    [value]="exportManifest.callReferenceNumber"
                  />
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.customsOffice'
                        | translate
                    "
                    [value]="
                      exportManifest.customsOfficeName +
                      ' (' +
                      exportManifest.customsOfficeUnCode +
                      ')'
                    "
                  />
                </div>
                <div class="flex w-full">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.voyageNumber'
                        | translate
                    "
                    [value]="exportManifest.voyageNumber"
                  />
                </div>
              </div>
            </div>

            @if (showEmptyShipmentsError) {
              <div class="w-fit pb-8">
                <pb-alert
                  type="error"
                  [title]="
                    'services.exportManifest.errors.noShipments.title'
                      | translate
                  "
                  [message]="
                    'services.exportManifest.errors.noShipments.message'
                      | translate
                  "
                ></pb-alert>
              </div>
            }
          </div>

          <div class="w-full bg-slate-100">
            <pbe-export-manifest-shipments
              [initialShipments]="exportManifest.shipments"
              (pendingChanges)="save($event)"
              (formValidityChange)="disableSubmit = $event"
            ></pbe-export-manifest-shipments>
          </div>
        </div>
      }
    </div>

    <div
      class="h-[4.5rem] p-4 mx-3 sticky bottom-0 z-10 bg-white footer-shadow"
    >
      <div class="w-full flex items-center justify-between ">
        <div class="flex flex-row justify-center items-center gap-2.5">
          <button
            class="border border-red-200 shadow-sm rounded text-red-700 px-4 py-2 text-sm font-medium hover:border-red-300 hover:bg-red-200 active:bg-red-300"
          >
            {{ "services.exportManifest.editPage.buttons.delete" | translate }}
          </button>
        </div>
        <div class="flex flex-row justify-center items-center gap-2.5">
          <p class="text-gray-500 text-xs font-normal font-sans">
            {{
              "services.exportManifest.createPage.changesAreSavedAutomatically"
                | translate
            }}
          </p>
          <button mat-stroked-button type="button" (click)="onClose()">
            {{ "services.exportManifest.editPage.buttons.close" | translate }}
          </button>
          <button
            mat-raised-button
            type="submit"
            (click)="onSendToCustoms()"
            [disabled]="showEmptyShipmentsError || disableSubmit"
          >
            {{ "services.exportManifest.editPage.buttons.submit" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>`,
})
export class ExportManifestEditPageComponent implements OnChanges {
  @Input({ required: true }) exportManifest!: ExportManifestDetailsDto;

  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;

  ExportManifestStatus = ExportManifestStatus;

  updateExportManifest =
    this.exportManifestService.updateExportManifest().mutateAsync;
  updateExportManifestForSendingDirectly =
    this.exportManifestService.updateExportManifest({
      refetchOnSuccess: false,
      showNotificationOnSuccess: false,
    }).mutateAsync;

  sendToCustoms = this.exportManifestService.sendToCustoms().mutateAsync;

  updatedShipments: (CreateExportManifestShipmentDto | null)[] | null = null;

  showEmptyShipmentsError = false;

  disableSubmit = false;

  @Output() editClose = new EventEmitter();

  constructor(private exportManifestService: ExportManifestService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["exportManifest"]) {
      this.updatedShipments = changes["exportManifest"].currentValue.shipments;
    }
  }

  onClose() {
    this.editClose.emit();
  }

  async save(shipments: CreateExportManifestShipmentDto[]) {
    this.updatedShipments = shipments;
    this.showEmptyShipmentsError = false;

    await this.updateExportManifest({
      id: this.exportManifest.id,
      shipments: (this.updatedShipments?.filter(
        (shipment) => shipment !== null,
      ) ?? []) as CreateExportManifestShipmentDto[],
    });
  }

  async onSendToCustoms() {
    if (this.updatedShipments === null || this.updatedShipments.length === 0) {
      this.showEmptyShipmentsError = true;
      return;
    }

    await this.updateExportManifestForSendingDirectly({
      id: this.exportManifest.id,
      shipments: (this.updatedShipments?.filter(
        (shipment) => shipment !== null,
      ) ?? []) as CreateExportManifestShipmentDto[],
    });

    await this.sendToCustoms({ id: this.exportManifest.id });
    this.editClose.emit();
  }
}
