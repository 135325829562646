import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { injectMutation, injectQuery, queryOptions } from "@ngneat/query";
import { of } from "rxjs";
import { WINDOW } from "../../window";
import { cacheInSessionStorage } from "../utils/session-storage-cache.util";
import { AppConfigService } from "./app-config.service";

export interface OrganisationDto {
  shortName: string;
  name: string;
}

export interface OrganisationProfile {
  shortName: string;
  fullName: string;
  emailAddress: string;
  phoneNumber: string;
  faxNumber: string;
  address: string;
  zipCode: string;
  city: string;
  countryUnCode: string;
  countryName: string;
  organisationTypes: string[];
  chamberOfCommerceNumber: string;
  customsEORINumber: string;
  scacCode: string;
  contact: string;
  eanCode: string;
  portAuthorityId: null;
  eorientrySequenceNumber: string;
  eoriexitSequenceNumber: string;
}

@Injectable({ providedIn: "root" })
export class OrganisationService {
  #http = inject(HttpClient);
  #query = injectQuery();
  #window = inject(WINDOW);
  #cargoHandlingAgents = this.getCargoHandlingAgents().result;
  #terminals = this.getTerminals().result;
  #organisations = this.getOrganisationsForCustomerService().result;
  #mutation = injectMutation();

  private readonly _appConfigService = inject(AppConfigService);

  getCargoHandlingAgentsQueryOptions(enabled: boolean) {
    return {
      queryKey: ["cargo-handling-agents"] as const,
      queryFn: () =>
        cacheInSessionStorage(
          "cargo-handling-agents",
          this.#http.get<OrganisationDto[]>(
            `${this._appConfigService.getConfig().apiUrl}/shipments/cargo-handling-agents`,
          ),
        ),
      enabled,
    };
  }

  getCargoHandlingAgents() {
    return this.#query(this.getCargoHandlingAgentsQueryOptions(false));
  }

  getCargoHandlingAgentForId(
    cargoHandlingAgentId: string,
  ): OrganisationDto | null {
    return (
      this.#cargoHandlingAgents().data?.find(
        (agent) => agent.shortName === cargoHandlingAgentId,
      ) ?? null
    );
  }

  getTerminalForId(terminalId: string): OrganisationDto | null {
    return (
      this.#terminals().data?.find(
        (terminal) => terminal.shortName === terminalId,
      ) ?? null
    );
  }

  assignCargoHandlingAgentToShipment() {
    return this.#mutation({
      mutationFn: ({
        cargoHandlingAgent,
        shipmentId,
      }: {
        shipmentId: string;
        cargoHandlingAgent: string;
      }) =>
        this.#http.patch(
          `${
            this._appConfigService.getConfig().apiUrl
          }/shipments/${shipmentId}/assigned-to-org`,
          { cargoHandlingAgent },
        ),
    });
  }

  getTerminals() {
    return this.#query({
      queryKey: ["terminals"] as const,
      queryFn: () =>
        cacheInSessionStorage(
          "terminals",
          this.#http.get<OrganisationDto[]>(
            `${this._appConfigService.getConfig().apiUrl}/shipments/terminals`,
          ),
        ),
      enabled: true,
    });
  }

  getOrganisationsForCustomerServiceQueryOptions(enabled: boolean) {
    return {
      queryKey: ["admin", "organisations"] as const,
      queryFn: () =>
        this.#http.get<OrganisationDto[]>(
          `${this._appConfigService.getConfig().apiUrl}/admin/organisations`,
        ),
      enabled,
    };
  }

  getOrganisationsForCustomerService() {
    return this.#query(
      this.getOrganisationsForCustomerServiceQueryOptions(false),
    );
  }

  getOrganisationForId(organisationId: string): OrganisationDto | null {
    return (
      this.#organisations().data?.find(
        (organisation) => organisation.shortName === organisationId,
      ) ?? null
    );
  }

  organisationProfileQueryOptions(organisationId: string | null) {
    return queryOptions({
      queryKey: ["organisation", "profile", organisationId] as const,
      queryFn: () => {
        if (this.#window.location.href.includes("localhost")) {
          return of<OrganisationProfile>({
            shortName: "CHA1",
            fullName: "Cargo Handling Agent 1",
            emailAddress: "vl-test1@portinfolink.com",
            phoneNumber: "0102522288",
            faxNumber: "0102522250",
            address: "Cargo Handling Agentlaan 1",
            zipCode: "1111CH",
            city: "Cargo Handling Agentdorp",
            countryUnCode: "NL",
            countryName: "Netherlands",
            organisationTypes: [
              "ote.forwarder",
              "ote.orgadmin",
              "ote.carrier",
              "ote.cargohandlingagent",
              "ote.financialdeclarant",
              "ote.containeroperator",
              "ote.gds.viewer",
            ],
            chamberOfCommerceNumber: "22222222",
            customsEORINumber: "222222222",
            scacCode: "CHAA",
            contact: "TC",
            eanCode: "12345",
            portAuthorityId: null,
            eorientrySequenceNumber: "00",
            eoriexitSequenceNumber: "00",
          });
        }
        return this.#http.get<OrganisationProfile>(
          `https://acc-mgt.accept.portbase.com/acc-mgt/organisations/${organisationId}`,
        );
      },
      enabled: organisationId !== null,
    });
  }

  getOrganisationProfileForId(id: string | null) {
    return this.#query(this.organisationProfileQueryOptions(id));
  }
}
