import { CommonModule } from "@angular/common";
import { Component, DestroyRef, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import {
  MAT_DIALOG_DATA,
  MatDialogActions,
  MatDialogClose,
} from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faShip, faTimes } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { debounceTime, distinctUntilChanged } from "rxjs";
import { PortbaseExportTabBarComponent } from "../../../../components";
import {
  ExportManifestAaxShipmentDto,
  ExportManifestService,
  MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE,
} from "../../../export-manifest.service";
import { CopyShipmentListItemComponent } from "./copy-shipment-list-item.component";

export interface CopyShipmentsDialogData {
  usedDocumentNumbers: string[];
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    ButtonIconDirective,
    PortbaseExportTabBarComponent,
    CopyShipmentListItemComponent,
    MatDialogActions,
    MatDialogClose,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatInputModule,
  ],
  selector: "pbe-export-manifest-copy-shipments-dialog",
  template: `<div class="flex flex-col items-stretch">
      <div class="px-5 pt-5 flex flex-row justify-between items-start mb-3">
        <h3 class="text-lg font-medium mb-2">
          {{ "services.exportManifest.copyShipmentsDialog.title" | translate }}
        </h3>
        <button mat-dialog-close mat-icon-button [icon]="faTimes"></button>
      </div>

      <pbe-tab-bar
        [activeTabIndex]="activeTabIndex"
        (changeTabIndex)="activeTabIndex = $event"
        [tabLabels]="[getAllShipmentsLabel(), getSelectedLabel()]"
      ></pbe-tab-bar>
    </div>
    <div class="px-5 !min-w-[700px] h-[500px] overflow-y-auto">
      <div *ngIf="activeTabIndex === 0">
        <mat-form-field class="w-full" [formGroup]="searchForm">
          <input
            matInput
            [placeholder]="
              'services.arrivalAtExit.overview.searchPlaceholder' | translate
            "
            id="search"
            formControlName="search"
          />
        </mat-form-field>
        <div class="flex flex-col gap-2 mt-4 items-stretch">
          @for (
            shipment of getFilteredShipments();
            track shipment.documentNumber
          ) {
            <pbe-export-manifest-copy-shipment-list-item
              [shipment]="shipment"
              [selected]="isShipmentSelected(shipment)"
              (toggleSelected)="toggleSelected(shipment)"
            />
          } @empty {
            <div
              class="flex flex-col items-center w-full mt-8 justify-center text-center"
            >
              <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
                <fa-icon
                  class="text-5xl block text-gray-400"
                  [icon]="faShip"
                ></fa-icon>
              </div>
              <p class="mt-3">
                {{
                  "services.exportManifest.copyShipmentsDialog.empty.title"
                    | translate
                }}
              </p>
            </div>
          }
        </div>
      </div>
      <div *ngIf="activeTabIndex === 1">
        <div class="flex flex-col gap-2 mt-4 items-stretch">
          @for (shipment of selection; track shipment.documentNumber) {
            <pbe-export-manifest-copy-shipment-list-item
              [shipment]="shipment"
              [selected]="isShipmentSelected(shipment)"
              (toggleSelected)="toggleSelected(shipment)"
            />
          } @empty {
            <div
              class="flex flex-col items-center w-full mt-8 justify-center text-center"
            >
              <div class="bg-gray-200 w-24 h-24 pt-6 text-center rounded-full">
                <fa-icon
                  class="text-5xl block text-gray-400"
                  [icon]="faShip"
                ></fa-icon>
              </div>
              <p class="mt-3">
                {{
                  "services.exportManifest.copyShipmentsDialog.noSelected.title"
                    | translate
                }}
              </p>
            </div>
          }
        </div>
      </div>
    </div>
    <mat-dialog-actions [align]="'end'" class="p-0">
      <button mat-dialog-close mat-stroked-button>
        {{
          "services.exportManifest.copyShipmentsDialog.cancelButton" | translate
        }}
      </button>
      <button
        mat-raised-button
        [disabled]="selection.length === 0"
        [mat-dialog-close]="selection"
      >
        {{
          "services.exportManifest.copyShipmentsDialog.confirmButton"
            | translate
        }}
      </button>
    </mat-dialog-actions>`,
})
export class CopyShipmentsDialogComponent {
  translate = inject(TranslateService);

  data: CopyShipmentsDialogData = inject(MAT_DIALOG_DATA);

  selection: ExportManifestAaxShipmentDto[] = [];

  faTimes = faTimes;
  faShip = faShip;

  activeTabIndex = 0;

  searchForm = new FormGroup({
    search: new FormControl(""),
  });

  manifestService = inject(ExportManifestService);
  shipmentsQuery = this.manifestService.getAaxShipments();
  shipmentsResult = this.shipmentsQuery.result;

  destroyRef = inject(DestroyRef);

  constructor() {
    this.searchForm.controls.search.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.shipmentsQuery.updateOptions(
          this.manifestService.getAaxShipmentsQueryOptions({
            search: this.searchForm.value.search ?? "",
            size: MAX_MANIFEST_AAX_SHIPMENTS_RESULT_SIZE,
          }),
        );
      });
  }

  getAllShipmentsLabel() {
    return `${this.translate.instant("services.exportManifest.copyShipmentsDialog.tabs.allShipments")} (${this.getFilteredShipments()?.length})`;
  }

  getSelectedLabel() {
    return `${this.translate.instant("services.exportManifest.copyShipmentsDialog.tabs.selectedShipments")} (${this.selection.length})`;
  }

  isShipmentSelected(shipment: ExportManifestAaxShipmentDto) {
    return (
      this.selection.findIndex(
        (s) => s.documentNumber === shipment.documentNumber,
      ) !== -1
    );
  }

  toggleSelected(shipment: ExportManifestAaxShipmentDto) {
    if (
      this.selection.findIndex(
        (s) => s.documentNumber === shipment.documentNumber,
      ) !== -1
    ) {
      this.selection = this.selection.filter(
        (s) => s.documentNumber !== shipment.documentNumber,
      );
    } else {
      this.selection.push(shipment);
    }
  }

  getFilteredShipments() {
    return this.shipmentsResult().data?.content.filter(
      (shipment) =>
        !this.data.usedDocumentNumbers.includes(shipment.documentNumber),
    );
  }
}
