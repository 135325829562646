import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseDataItemComponent } from "../../components/data-item.component";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar.component";
import { PortbaseExportEmStatusIndicatorComponent } from "../components/status-indicator.component";
import {
  ExportManifestDetailsDto,
  ExportManifestStatus,
} from "../export-manifest.service";
import { ExportManifestShipmentsComponent } from "./components/export-shipments.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportEmStatusIndicatorComponent,
    ButtonIconDirective,
    MatTabsModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsComponent,
  ],
  selector: "pbe-export-manifest-details-page",
  template: `<div class="h-full flex flex-col items-stretch overflow-hidden">
    <mat-toolbar class="sticky top-0 z-10 bg-white">
      <div class="flex flex-row items-center gap-4">
        <button
          mat-icon-button
          [icon]="faTimes"
          (click)="detailsClose.emit()"
        ></button>
        <h1 class="text-xl text-gray-700 ml-6 items-center">
          {{ exportManifest.callReferenceNumber }}
        </h1>
      </div>
    </mat-toolbar>

    <div
      class="bg-slate-100 flex-grow w-full flex-col overflow-auto overflow-x-hidden border-b border-b-gray-200"
    >
      @if (exportManifest !== undefined) {
        <div class="bg-white shadow-sm">
          <div
            class="w-full ml-8 pt-8 flex flex-col justify-between items-start gap-4"
          >
            @if (
              exportManifest.manifestStatus !==
                ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS &&
              exportManifest.manifestStatus !==
                ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED
            ) {
              <h1 class="text-lg font-medium font-sans text-[#29343D]">
                {{
                  "services.exportManifest.detailsPage.manifestDetails.title"
                    | translate
                }}
              </h1>

              <div class="flex flex-col justify-center items-start gap-1">
                <p class="text-sm font-medium font-sans text-gray-800">
                  {{
                    "services.exportManifest.detailsPage.customsFeedback.manifestStatus"
                      | translate
                  }}
                </p>

                <pbe-em-status-indicator
                  [status]="exportManifest.manifestStatus"
                  [includeLongDescription]="true"
                ></pbe-em-status-indicator>
              </div>
            }

            <div class="w-full pb-5">
              <div class="flex flex-row justify-between gap-4 w-full 2xl:w-1/2">
                <div class="w-full flex flex-col">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.vesselName'
                        | translate
                    "
                    [value]="exportManifest.vesselName"
                  />
                  <div class="flex flex-row gap-12">
                    <pbe-data-item
                      [label]="
                        'services.exportManifest.detailsPage.manifestDetails.fields.departureDate'
                          | translate
                      "
                      [value]="
                        (exportManifest.departureDate | date: 'dd-MM-yyyy') ??
                        '-'
                      "
                    />
                    <pbe-data-item
                      [label]="
                        'services.exportManifest.detailsPage.manifestDetails.fields.vesselStatus'
                          | translate
                      "
                      [value]="
                        'general.vesselStatus.short.' +
                          exportManifest.vesselStatus | translate
                      "
                    />
                  </div>
                </div>
                <div class="w-full flex flex-col">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.callReferenceNumber'
                        | translate
                    "
                    [value]="exportManifest.callReferenceNumber"
                  />
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.customsOffice'
                        | translate
                    "
                    [value]="
                      exportManifest.customsOfficeName +
                      ' (' +
                      exportManifest.customsOfficeUnCode +
                      ')'
                    "
                  />
                </div>
                <div class="flex w-full">
                  <pbe-data-item
                    [label]="
                      'services.exportManifest.detailsPage.manifestDetails.fields.voyageNumber'
                        | translate
                    "
                    [value]="exportManifest.voyageNumber"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="w-full">
            <pbe-export-manifest-shipments
              [readonly]="true"
              [initialShipments]="exportManifest.shipments"
            ></pbe-export-manifest-shipments>
          </div>
        </div>
      }
    </div>

    <div
      class="h-[4.5rem] p-4 mx-3 sticky bottom-0 z-10 bg-white footer-shadow"
    >
      <div class="w-full flex items-center justify-end ">
        <div class="flex flex-row justify-center items-center gap-2.5">
          <button mat-stroked-button (click)="detailsClose.emit()">
            {{
              "services.exportManifest.detailsPage.buttons.close" | translate
            }}
          </button>
        </div>
      </div>
    </div>
  </div>`,
})
export class ExportManifestDetailsPageComponent {
  @Input({ required: true }) exportManifest!: ExportManifestDetailsDto;

  @Output() detailsClose = new EventEmitter();

  faTimes = faTimes;

  ExportManifestStatus = ExportManifestStatus;
}
