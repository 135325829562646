import { CommonModule } from "@angular/common";
import {
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import {
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatDialog } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { isEqual, omit } from "lodash";
import { debounceTime } from "rxjs";
import { ArrivalAtExitStatus } from "../../../arrival-at-exit/shipment.service";
import { PortbaseDataItemComponent } from "../../../components/data-item.component";
import {
  CreateExportManifestShipmentDto,
  ExportManifestAaxShipmentDto,
  ExportManifestShipmentDocumentType,
  ExportManifestShipmentDto,
} from "../../export-manifest.service";
import { documentNumberValidator } from "../../validators/document-number.validator";
import { documentTypeValidator } from "../../validators/document-type-autocomplete.validator";
import { CopyShipmentsDialogComponent } from "./copy-shipments-dialog/copy-shipments-dialog.component";
import { duplicateDocumentNumberValidator } from "./duplicate-document-number.validator";
import { ExportManifestShipmentsTableControlsComponent } from "./export-shipments-table/export-shipments-table-controls.component";
import { ExportManifestShipmentsTableComponent } from "./export-shipments-table/export-shipments-table.component";

export interface ExportManifestShipmentFormControls {
  selected: FormControl<boolean>;
  documentType: FormControl<ExportManifestShipmentDocumentType>;
  documentNumber: FormControl<string>;
  referenceNumber: FormControl<string>;
  weight: FormControl<string>;
  arrivalAtExitStatus: FormControl<ArrivalAtExitStatus>;
  partShipment: FormControl<boolean>;
  copiedFromMael: FormControl<boolean>;
}

const WEIGHT_PATTERN = /^\d+$/;

const DOCUMENT_NUMBER_MAX_LENGTH = 27;

const REFERENCE_NUMBER_MAX_LENGTH = 25;

const AUTO_SAVE_DEBOUNCE_TIME = 300; // 300 milliseconds

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatButtonModule,
    FontAwesomeModule,
    ButtonIconDirective,
    MatFormFieldModule,
    MatInputModule,
    PortbaseDataItemComponent,
    ExportManifestShipmentsTableComponent,
    ExportManifestShipmentsTableControlsComponent,
  ],
  selector: "pbe-export-manifest-shipments",
  template: `
    <pbe-export-manifest-shipments-table-controls
      [disableActions]="!isFormValid || selectedShipments.length > 0"
      [hideActions]="readonly"
      [shipmentsPendingDeletion]="selectedShipments.length"
      (openCopyShipmentsDialog)="openCopyShipmentsDialog()"
      (addShipment)="addShipment()"
      (deleteSelectedShipments)="deleteSelectedShipments()"
    ></pbe-export-manifest-shipments-table-controls>

    <pbe-export-manifest-shipments-table
      [readonly]="readonly"
      [formGroup]="form"
    ></pbe-export-manifest-shipments-table>

    @if (shipments.length < 1) {
      <div class="w-full mt-20 flex justify-center items-center flex-col">
        <p
          class="font-sans text-gray-700 normal text-sm"
          [innerHTML]="
            'services.exportManifest.shipmentsTable.manualEntry' | translate
          "
        ></p>
        <p
          class="font-sans text-gray-700 normal text-sm"
          [innerHTML]="
            'services.exportManifest.shipmentsTable.uploadExcel' | translate
          "
        ></p>
        <p
          class="font-sans text-gray-700 normal text-sm"
          [innerHTML]="
            'services.exportManifest.shipmentsTable.copyFromExisting'
              | translate
          "
        ></p>
      </div>
    }
  `,
})
export class ExportManifestShipmentsComponent implements OnChanges {
  @Input() readonly = false;
  @Input({ required: true }) initialShipments!: ExportManifestShipmentDto[];

  @Output() pendingChanges = new EventEmitter<
    CreateExportManifestShipmentDto[]
  >();

  @Output() formValidityChange = new EventEmitter<boolean>();

  shipments: ExportManifestShipmentDto[] = [];

  form = new FormGroup({
    shipments: new FormArray<FormGroup<ExportManifestShipmentFormControls>>([]),
  });

  hasChangesInitialized = false;

  constructor(
    private dialog: MatDialog,
    private destroyRef: DestroyRef,
  ) {
    this.form.valueChanges
      .pipe(
        debounceTime(AUTO_SAVE_DEBOUNCE_TIME),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const formShipments = this.form.controls.shipments.getRawValue();

        const containsSignificantChanges = !isEqual(
          formShipments.map((shipment) => omit(shipment, "selected")),
          this.shipments.map((shipment) => omit(shipment, "selected")),
        );

        if (!this.isFormValid || !containsSignificantChanges) {
          return;
        }

        this.shipments = [...formShipments];
        this.emitChanges();
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.hasChangesInitialized) {
      return;
    }

    if (changes["initialShipments"]) {
      this.shipments = [...changes["initialShipments"].currentValue];

      this.form.setControl(
        "shipments",
        new FormArray(
          this.shipments.map((shipment) =>
            this.createShipmentFormGroup(shipment),
          ),
        ),
      );

      if (this.readonly) {
        this.form.disable();
      }

      this.hasChangesInitialized = true;
    }
  }

  getUsedDocumentNumbers(): string[] {
    return this.shipments
      .filter((shipment) => shipment !== null)
      .map((shipment) => shipment?.documentNumber)
      .filter(Boolean) as string[];
  }

  async openCopyShipmentsDialog() {
    const dialogRef = this.dialog.open(CopyShipmentsDialogComponent, {
      data: { usedDocumentNumbers: this.getUsedDocumentNumbers() },
    });

    dialogRef
      .afterClosed()
      .subscribe((shipments: ExportManifestAaxShipmentDto[] | null) => {
        if (!shipments) {
          return;
        }

        const newShipments = shipments.map((shipment) => ({
          documentNumber: shipment.documentNumber,
          documentType: shipment.documentType,
          referenceNumber: shipment.reference,
          arrivalAtExitStatus: shipment.arrivalAtExitStatus,
          copiedFromMael: true,
          weight: "",
          partShipment: false,
        })) as ExportManifestShipmentDto[];

        for (const shipment of newShipments) {
          this.form.controls.shipments.push(
            this.createShipmentFormGroup(shipment),
          );
        }

        this.shipments.push(...newShipments);

        // If the form is invalid, we don't want to emit the changes
        if (!this.isFormValid) {
          return;
        }

        this.emitChanges();
      });
  }

  addShipment() {
    this.form.controls.shipments.push(
      this.createShipmentFormGroup(this.createEmptyShipment()),
    );

    this.shipments = this.form.controls.shipments.getRawValue();
  }

  deleteSelectedShipments() {
    if (!this.selectedShipments.length) {
      return;
    }

    for (const shipment of this.selectedShipments) {
      this.form.controls.shipments.removeAt(
        this.form.controls.shipments.value.indexOf(shipment),
      );
    }

    this.shipments = this.form.controls.shipments.getRawValue();
    this.pendingChanges.emit(this.shipments);
  }

  get selectedShipments() {
    return this.form.controls.shipments.value.filter(
      (shipment) => shipment.selected,
    );
  }

  get isFormValid() {
    const errors = this.findErrorsRecursive(this.form);

    // Used for document number validation
    const warningKeys = [
      "invalidYear",
      "invalidCountry",
      "invalidAlphaNumeric",
      "invalidCheckDigit",
      "invalidChecksum",
      "minlength",
      "maxlength",
    ];

    const isFormValid = errors.every((error) => {
      const errorKeys = Object.keys(error);
      return errorKeys.every((key) => warningKeys.includes(key));
    });

    if (this.form.hasError("duplicateDocumentNumbers")) {
      this.formValidityChange.emit(true);
      return false;
    }

    this.formValidityChange.emit(!isFormValid);
    return isFormValid;
  }

  private findErrorsRecursive(
    formToInvestigate: FormGroup | FormArray,
  ): ValidationErrors[] {
    const errors: ValidationErrors[] = [];

    const recursiveFunc = (form: FormGroup | FormArray) => {
      Object.keys(form.controls).forEach((field) => {
        const control = form.get(field);

        if (control?.errors) {
          errors.push(control.errors);
        }

        if (control instanceof FormGroup) {
          recursiveFunc(control);
        } else if (control instanceof FormArray) {
          recursiveFunc(control);
        }
      });
    };

    recursiveFunc(formToInvestigate);
    return errors;
  }

  private createEmptyShipment(): ExportManifestShipmentDto {
    return {
      documentType: "" as ExportManifestShipmentDocumentType,
      documentNumber: "",
      referenceNumber: "",
      weight: "",
      arrivalAtExitStatus: "" as ArrivalAtExitStatus,
      partShipment: false,
      copiedFromMael: false,
    };
  }

  private createShipmentFormGroup(shipment: ExportManifestShipmentDto) {
    return new FormGroup({
      selected: new FormControl(false),
      documentType: new FormControl(shipment.documentType || "", {
        validators: [Validators.required, documentTypeValidator()],
      }),
      documentNumber: new FormControl(shipment.documentNumber || "", {
        validators: [
          Validators.required,
          Validators.maxLength(DOCUMENT_NUMBER_MAX_LENGTH),
          duplicateDocumentNumberValidator(
            () => this.getUsedDocumentNumbers(),
            () => shipment.documentNumber,
          ),
          documentNumberValidator(),
        ],
      }),
      referenceNumber: new FormControl(shipment.referenceNumber || "", {
        validators: [Validators.maxLength(REFERENCE_NUMBER_MAX_LENGTH)],
      }),
      weight: new FormControl(shipment.weight || "", {
        validators: [Validators.pattern(WEIGHT_PATTERN)],
      }),
      arrivalAtExitStatus: new FormControl(shipment.arrivalAtExitStatus),
      partShipment: new FormControl(shipment.partShipment || false),
      copiedFromMael: new FormControl(shipment.copiedFromMael || false),
    }) as FormGroup<ExportManifestShipmentFormControls>;
  }

  private emitChanges() {
    const shipments = this.shipments.filter(
      (shipment) =>
        (shipment.documentType as string) !== "" &&
        shipment.documentNumber !== "",
    );

    this.pendingChanges.emit(shipments);
  }
}
