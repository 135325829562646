import {
  Component,
  DestroyRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  inject,
} from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatListModule, MatListOption } from "@angular/material/list";

import { SelectionModel } from "@angular/cdk/collections";
import { CommonModule, DatePipe } from "@angular/common";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { ActivatedRoute, Router } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PBDatePickerModule } from "@portbase/material/date-picker";
import { endOfDay, formatISO } from "date-fns";
import { debounceTime } from "rxjs";
import { FilterListItemComponent } from "../../components/filter-list-item.component";
import { DialogService } from "../../core/services/dialog/dialog.service";
import { formatDateRange } from "../../core/utils/date-formatter.utils";
import { ArrivalAtExitStatus } from "../shipment.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatListModule,
    ButtonIconDirective,
    TippyDirective,
    FontAwesomeModule,
    MatButtonModule,
    PBDatePickerModule,
    FilterListItemComponent,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  providers: [DatePipe],
  selector: "pbe-arrival-at-exit-filter-list",
  template: `<mat-action-list>
    @if (showStatusesFilter) {
      <pbe-filter-list-item
        [label]="'services.arrivalAtExit.filters.status' | translate"
        [filter]="statusFilter"
        [formattedFilter]="mapStatusFilters(statusFilter)"
        (clearButtonClick)="filterIconButtonClick('statusFilter')"
        [tp]="statusFilterList"
        [tpData]="statusFilterOptions"
        [tpVariation]="'popper'"
        [tpPlacement]="'bottom-start'"
        [tpPopperWidth]="260"
        [tpOffset]="[0, 8]"
        (tpVisible)="statusesFilterVisible = !statusesFilterVisible"
      />
    }
    <pbe-filter-list-item
      [label]="'services.arrivalAtExit.filters.creationDateRange' | translate"
      [filter]="creationDateRange"
      [formattedFilter]="mapDateRangeFilters(creationDateRange)"
      (clearButtonClick)="filterIconButtonClick('creationDateRange')"
      (click)="showDateRangePicker()"
    />
    @if (showOrganisationsFilter) {
      <pbe-filter-list-item
        [label]="'services.arrivalAtExit.filters.organisation' | translate"
        [filter]="organisationFilter"
        [formattedFilter]="mapOrganisationFilters(organisationFilter)"
        (clearButtonClick)="filterIconButtonClick('organisationFilter')"
        [tp]="organisationFilterList"
        [tpData]="organisationFilterOptions"
        [tpVariation]="'popper'"
        [tpPlacement]="'bottom-start'"
        [tpOffset]="[0, 8]"
        (tpVisible)="organisationFilterVisible = !organisationFilterVisible"
      />
    }
    <ng-template #statusFilterList>
      <div>
        <div matSubheader>
          {{ "services.arrivalAtExit.filters.status" | translate }}
        </div>
        <mat-selection-list
          #selection
          (selectionChange)="
            statusFilterSelectionChange(selection.selectedOptions)
          "
        >
          <ng-container *ngFor="let option of statusFilterOptions">
            <mat-list-option
              [value]="option"
              [selected]="statusFilter?.includes(option.value)"
              color="primary"
              togglePosition="before"
            >
              {{ option.label }}
            </mat-list-option>
          </ng-container>
        </mat-selection-list>
      </div>
    </ng-template>
    <ng-template #organisationFilterList>
      <div class="w-fit min-w-[260px] flex flex-col">
        <div matSubheader>
          {{ "services.arrivalAtExit.filters.organisation" | translate }}
        </div>

        <div class="flex flex-grow w-full mt-[-1.25rem] px-3.5">
          <mat-form-field class="w-full" [formGroup]="searchForm">
            <input
              matInput
              [placeholder]="
                'services.arrivalAtExit.filters.organisationSearchPlaceholder'
                  | translate
              "
              id="search"
              formControlName="search"
            />
            @if (searchForm.value.search) {
              <div matSuffix>
                <button
                  mat-icon-button
                  type="button"
                  [icon]="faTimes"
                  (click)="clearSearchQuery()"
                ></button>
              </div>
            }
          </mat-form-field>
        </div>

        @if (filteredOrganisationOptions.length > 0) {
          <mat-selection-list
            #selection
            (selectionChange)="
              organisationFilterSelectionChange(selection.selectedOptions)
            "
            class="max-h-[250px] overflow-y-auto"
          >
            <ng-container *ngFor="let option of filteredOrganisationOptions">
              <mat-list-option
                [value]="option"
                [selected]="organisationFilter?.includes(option.value)"
                color="primary"
                togglePosition="before"
              >
                {{ option.label }}
              </mat-list-option>
            </ng-container>
          </mat-selection-list>
        } @else {
          <div class="flex justify-center items-center h-10">
            {{
              "services.arrivalAtExit.filters.emptyOrganisationSearch"
                | translate
            }}
          </div>
        }
      </div>
    </ng-template>
  </mat-action-list>`,
})
export class ArrivalAtExitFilterListComponent implements OnInit, OnChanges {
  @Input() showStatusesFilter = true;
  @Input() showOrganisationsFilter = false;

  datePipe = inject(DatePipe);
  ArrivalAtExitStatus = ArrivalAtExitStatus;
  translateService = inject(TranslateService);
  dialogService = inject(DialogService);
  router = inject(Router);
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);
  faPlus = faPlus;
  faTimes = faTimes;
  statusFilterOptions = Object.values(ArrivalAtExitStatus).map((status) => ({
    value: status,
    label: this.translateService.instant(
      `general.shipmentStatus.short.${status}`,
    ),
  }));

  statusesFilterVisible = true;
  organisationFilterVisible = true;

  @Input({ required: true }) statusFilter: ArrivalAtExitStatus[] | null = null;
  @Input({ required: true }) creationDateRange: [Date, Date] | null = null;

  @Input() organisationFilter: string[] | null = null;
  @Input() organisationFilterOptions: { value: string; label: string }[] = [];

  searchForm = new FormGroup({
    search: new FormControl(""),
  });

  filteredOrganisationOptions: { value: string; label: string }[] = [];

  ngOnInit(): void {
    this.searchForm.controls.search.valueChanges
      .pipe(debounceTime(300), takeUntilDestroyed(this.destroyRef))
      .subscribe((search) => {
        if (!search || search === "") {
          this.filteredOrganisationOptions = this.organisationFilterOptions;
          return;
        }

        this.filteredOrganisationOptions =
          this.organisationFilterOptions.filter((option) =>
            option.label.toLowerCase().includes(search.toLowerCase()),
          );
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const organisationFilterOptions =
      changes["organisationFilterOptions"]?.currentValue;
    if (organisationFilterOptions !== undefined) {
      this.filteredOrganisationOptions = organisationFilterOptions;
    }
  }

  clearSearchQuery() {
    this.searchForm.controls.search.setValue(null);
  }

  statusFilterSelectionChange(selection: SelectionModel<MatListOption>) {
    this.setStatusFilter(selection.selected.map((s) => s.value.value));
  }

  organisationFilterSelectionChange(selection: SelectionModel<MatListOption>) {
    this.setOrganisationFilter(selection.selected.map((s) => s.value.value));
  }

  async showDateRangePicker() {
    const result = await this.dialogService.showDateRangePickerDialog(
      this.creationDateRange,
    );
    if (result !== undefined && result !== "") {
      this.creationDateRange = result;
      const [start, end] =
        result === null ? [null, null] : (result as [Date, Date | null]);

      this.setDateRangeQueryParams(start, end ?? start);
    }
  }

  filterIconButtonClick(
    filter: "statusFilter" | "creationDateRange" | "organisationFilter",
  ) {
    if (filter === "creationDateRange" && this.creationDateRange !== null) {
      this.setDateRangeQueryParams(null, null);
    } else if (filter === "statusFilter" && this.statusFilter !== null) {
      this.setStatusFilter(null);
    } else if (
      filter === "organisationFilter" &&
      this.organisationFilter !== null
    ) {
      this.setOrganisationFilter(null);
    }
  }

  setDateRangeQueryParams(start: Date | null, end: Date | null) {
    const shouldRemoveParam = start === null || end === null;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        creationDateRange: shouldRemoveParam
          ? null
          : [formatISO(start), formatISO(endOfDay(end))].join(","),
      },
    });
  }

  setStatusFilter(statuses: ArrivalAtExitStatus[] | null) {
    const shouldRemoveParam = statuses === null || statuses.length === 0;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        statuses: shouldRemoveParam ? null : statuses.join(","),
      },
    });
  }

  mapStatusFilters(statuses: ArrivalAtExitStatus[] | null): string {
    if (statuses === null) {
      return "";
    }

    return statuses
      .map((status) =>
        this.translateService.instant(`general.shipmentStatus.short.${status}`),
      )
      .join(", ");
  }

  setOrganisationFilter(organisations: string[] | null) {
    const shouldRemoveParam =
      organisations === null || organisations.length === 0;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: {
        organisations: shouldRemoveParam ? null : organisations.join(","),
      },
    });
  }

  mapOrganisationFilters(organisations: string[] | null): string {
    if (organisations === null) {
      return "";
    }

    return organisations.join(", ");
  }

  mapDateRangeFilters(range: [Date, Date] | null): string {
    if (range === null) {
      return "";
    }
    const [start, end] = range;
    return formatDateRange(start, end);
  }
}
