import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output, inject } from "@angular/core";
import {
  FormArray,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPseudoCheckboxModule } from "@angular/material/core";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
  MatFormFieldModule,
} from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import {
  faCheck,
  faEye,
  faEyeSlash,
  faPencil,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { PluralTranslatePipe } from "../../core/pipes/plural-translate.pipe";
import { ArrivalAtExitNotificationType } from "../services/preferences.service";

@Component({
  selector: "pbe-email-address-list",
  standalone: true,
  imports: [
    NgFor,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSlideToggleModule,
    ReactiveFormsModule,
    TranslateModule,
    FontAwesomeModule,
    ButtonIconDirective,
    PBAutocompleteModule,
    PluralTranslatePipe,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: <MatFormFieldDefaultOptions>{
        appearance: "outline",
        floatLabel: "always",
      },
    },
  ],
  template: `
    <div class="flex flex-col items-start w-full text-sm my-3">
      <div class="flex-grow-0 flex-shrink w-full">
        <h1 class="font-medium">
          {{
            "preferences.notifications.types." + notificationType | translate
          }}
        </h1>

        <span>
          {{
            "preferences.notifications.types.descriptions." + notificationType
              | translate
          }}
        </span>
      </div>

      <div class="flex flex-col">
        <div class="flex items-center flex-row">
          @if (showDetails) {
            @if (canEdit) {
              <div class="flex flex-col w-96">
                <form [formGroup]="formGroup">
                  <div formArrayName="email">
                    @for (
                      group of formGroup.controls.email.controls;
                      track $index
                    ) {
                      <div class="relative" [formGroupName]="$index">
                        <mat-form-field
                          class="w-full !mt-2"
                          [formGroup]="group"
                        >
                          <input matInput formControlName="email" type="text" />
                        </mat-form-field>
                        @if ($index > 0) {
                          <div
                            class="absolute -right-[35px] top-1/2 -translate-y-1/2"
                          >
                            <button
                              mat-icon-button
                              [icon]="faTimes"
                              (click)="
                                formGroup.controls.email.removeAt($index)
                              "
                            ></button>
                          </div>
                        }
                      </div>

                      @if (group.get("email")?.hasError("email")) {
                        <mat-error class="text-xs">{{
                          "general.forms.errors.email" | translate
                        }}</mat-error>
                      }
                    }
                  </div>
                </form>

                <div class="flex justify-between items-center my-2">
                  <button
                    mat-button
                    color="primary"
                    class="!p-0"
                    [icon]="faPlus"
                    (click)="addAnotherEmailAddress()"
                  >
                    {{
                      "preferences.arrivalAtExit.fields.addAnotherButtonText"
                        | translate
                    }}
                  </button>

                  <button
                    mat-stroked-button
                    type="button"
                    (click)="clickHideDetails()"
                  >
                    Save
                  </button>
                </div>
              </div>
            } @else {
              <div class="flex flex-col mb-3 w-full">
                <h1 class="font-medium">Subscribed email addresses:</h1>

                <div class="flex flex-col w-full">
                  @for (email of emailList; track $index) {
                    <mat-form-field class="w-96 !mt-2">
                      <input
                        matInput
                        [value]="email"
                        [disabled]="true"
                        type="text"
                      />
                    </mat-form-field>
                  }
                </div>
              </div>
            }
          } @else {
            <div class="line-clamp-2 text-slate-700 break-word my-2">
              {{
                "preferences.notifications.emailAddressesSubscribed"
                  | pluralTranslate: emailList.length
                  | translate: { emails: emailList.length }
              }}
            </div>
          }

          @if (!showDetails) {
            @if (canEdit) {
              <button
                class="absolute -right-1"
                mat-icon-button
                (click)="clickShowDetails()"
              >
                <fa-icon [icon]="faPencil"></fa-icon>
              </button>
            }

            @if (!canEdit && emailList.length > 0) {
              <button
                class="absolute -right-1"
                mat-icon-button
                (click)="clickShowDetails()"
              >
                <fa-icon [icon]="faEye"></fa-icon>
              </button>
            }
          }
        </div>

        @if (showDetails && !canEdit) {
          <div class="flex justify-end mb-2">
            <button
              mat-stroked-button
              type="button"
              (click)="clickHideDetails()"
            >
              Hide
            </button>
          </div>
        }

        @if (!showDetails) {
          <mat-slide-toggle
            [checked]="isUserSubscribed"
            (change)="toggleSubscription.emit($event.checked)"
          >
            {{
              "preferences.notifications.sendToMyEmailAddress"
                | translate: { email: profile().data?.emailAddress }
            }}
          </mat-slide-toggle>
        }
      </div>
    </div>
  `,
})
export class EmailAddressListComponent {
  @Input({ required: true }) notificationType!: string;
  @Input({ required: true }) isUserSubscribed!: boolean;
  @Input({ required: true }) canEdit!: boolean;

  @Output() toggleSubscription = new EventEmitter<boolean>();

  profile = inject(AuthenticationService).getProfile().result;

  ArrivalAtExitNotificationType = ArrivalAtExitNotificationType;

  faPencil = faPencil;
  faCheck = faCheck;
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faPlus = faPlus;
  faTimes = faTimes;

  showDetails = false;

  @Input() emailList: string[] = [];
  @Output() emailListChange = new EventEmitter<string[]>();

  formGroup = new FormGroup({
    email: new FormArray(
      this.emailList.map(
        (email) =>
          new FormGroup({
            email: new FormControl(email, Validators.email),
          }),
      ),
    ),
  });

  addAnotherEmailAddress() {
    this.formGroup.controls.email.push(
      new FormGroup({
        email: new FormControl("", Validators.email),
      }),
    );
  }

  clickShowDetails() {
    if (this.canEdit) {
      const emails = this.emailList.length > 0 ? this.emailList : [""];
      this.formGroup = new FormGroup({
        email: new FormArray(
          emails.map(
            (email) =>
              new FormGroup({
                email: new FormControl(email, Validators.email),
              }),
          ),
        ),
      });
    }
    this.showDetails = true;
  }

  clickHideDetails() {
    if (this.canEdit) {
      // Submit list of email addresses
      const list = this.formGroup.controls.email.value
        .map((email) => email.email as string)
        .filter(Boolean);

      this.emailListChange.emit(list);
    }

    this.showDetails = false;
  }
}
