import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AuthenticationService,
  UserRights,
} from "../../../core/authentication/authentication.service";
import { ArrivalAtExitCreatePageComponent } from "../../create-page/create-page.component";
import { ArrivalAtExitDetailsPageComponent } from "../../details-page/details-page.component";
import { ArrivalAtExitEditPageComponent } from "../../edit-page/edit-page.component";
import { ShipmentService } from "../../shipment.service";

type ArrivalAtExitViewQueryParam = "create" | "edit" | "details" | null;

@Component({
  standalone: true,
  selector: "pbe-arrival-at-exit-operational-right-drawer",
  imports: [
    ArrivalAtExitDetailsPageComponent,
    ArrivalAtExitCreatePageComponent,
    ArrivalAtExitEditPageComponent,
    MatSidenavModule,
  ],
  template: `<div
    class="fixed w-screen h-screen top-0 left-0 z-[1] pointer-events-none"
  >
    <div
      class="bg-black transition-opacity opacity-0 w-full h-full"
      [class]="
        drawer.opened
          ? 'opacity-50 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      "
      (click)="closeRightDrawer()"
    ></div>
    <mat-drawer
      (closed)="removeQueryParams()"
      #drawer
      mode="over"
      class="w-screen max-w-5xl z-10 pointer-events-auto"
      position="end"
    >
      <div class="bg-white h-full w-full  z-10">
        @if (drawer.opened) {
          @switch (viewQueryParam) {
            @case ("create") {
              <pbe-arrival-at-exit-create-page
                (createCancel)="closeRightDrawer()"
                (createSuccess)="closeRightDrawer()"
                [userCanAssignCargoHandlingAgent]="
                  userRights().includes(UserRights.AssignCargoHandlingAgent)
                "
              ></pbe-arrival-at-exit-create-page>
            }
            @case ("details") {
              @if (shipment().data !== undefined) {
                <pbe-arrival-at-exit-details-page
                  (detailsClose)="closeRightDrawer()"
                  [shipment]="shipment().data!"
                  [userCanAssignCargoHandlingAgent]="
                    userRights().includes(UserRights.AssignCargoHandlingAgent)
                  "
                ></pbe-arrival-at-exit-details-page>
              }
            }
            @case ("edit") {
              @if (shipment().data !== undefined) {
                <pbe-arrival-at-exit-edit-page
                  (editClose)="closeRightDrawer()"
                  (editSuccess)="closeRightDrawer()"
                  [shipment]="shipment().data!"
                  [userCanAssignCargoHandlingAgent]="
                    userRights().includes(UserRights.AssignCargoHandlingAgent)
                  "
                ></pbe-arrival-at-exit-edit-page>
              }
            }
          }
        }
      </div>
    </mat-drawer>
  </div>`,
})
export class ArrivalAtExitOperationalRightDrawerComponent
  implements OnDestroy, AfterViewInit
{
  viewQueryParam: ArrivalAtExitViewQueryParam = null;
  entityIdQueryParam: string | null = null;

  UserRights = UserRights;
  userRights = inject(AuthenticationService).userRights;

  router = inject(Router);
  route = inject(ActivatedRoute);

  shipmentsService = inject(ShipmentService);
  shipmentByIdQuery = this.shipmentsService.getShipmentById(
    this.entityIdQueryParam,
  );
  shipment = this.shipmentByIdQuery.result;

  @ViewChild("drawer") public drawer!: MatDrawer;

  _paramsSubscription?: Subscription;

  ngAfterViewInit() {
    this._paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.viewQueryParam = params.get("view") as ArrivalAtExitViewQueryParam;
      if (this.viewQueryParam === "details" || this.viewQueryParam === "edit") {
        this.entityIdQueryParam = this.route.snapshot.queryParamMap.get("id");
        this.shipmentByIdQuery.updateOptions(
          this.shipmentsService.getShipmentByIdQueryOptions(
            this.entityIdQueryParam,
            this.entityIdQueryParam !== null,
          ),
        );
      }
      const openRightDrawer = this.viewQueryParam !== null;
      if (openRightDrawer) {
        setTimeout(() => {
          this.drawer.open();
        });
      }
    });
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
  }

  closeRightDrawer() {
    this.drawer.close();
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: { view: null, id: null },
    });
  }
}
