import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import {
  faCircleCheck,
  faCircleEllipsis,
  faQuestionCircle,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { ExportManifestStatus } from "../export-manifest.service";

@Component({
  selector: "pbe-em-status-indicator",
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  template: `<div class="flex flex-row items-center h-full min-w-32">
    @if (getStatusIcon(status) !== null) {
      <fa-icon
        [class]="getStatusColor(status)"
        [icon]="getStatusIcon(status) ?? faQuestionCircle"
        size="lg"
      ></fa-icon>
    } @else {
      <div class="w-5 h-5 min-w-5 rounded-full text-center bg-gray-300"></div>
    }
    <span class="ml-4 text-sm text-slate-800"
      >{{ "general.manifestStatus.short." + status | translate }}
      @if (includeLongDescription) {
        <span
          >-
          <span
            [innerHTML]="'general.manifestStatus.long.' + status | translate"
          ></span
        ></span>
      }
    </span>
  </div>`,
})
export class PortbaseExportEmStatusIndicatorComponent {
  @Input({ required: true }) status!: ExportManifestStatus;
  @Input() includeLongDescription = false;

  faQuestionCircle = faQuestionCircle;

  statusToColorMap: Record<ExportManifestStatus, string> = {
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED]: "text-gray-300",
    [ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS]:
      "text-gray-300",
    [ExportManifestStatus.EXPORT_MANIFEST_REPORTED_TO_CUSTOMS]: "text-blue-500",
    [ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS]:
      "text-green-500",
    [ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS]: "text-red-500",
    [ExportManifestStatus.CUSTOMS_UNAVAILABLE]: "text-red-500",
  };

  statusToIconMap: Record<ExportManifestStatus, IconProp | null> = {
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_REPORTED_TO_CUSTOMS]: null,
    [ExportManifestStatus.EXPORT_MANIFEST_NOT_YET_CREATED]: null,
    [ExportManifestStatus.EXPORT_MANIFEST_UPDATED_NOT_YET_REPORTED_TO_CUSTOMS]:
      null,
    [ExportManifestStatus.EXPORT_MANIFEST_REPORTED_TO_CUSTOMS]:
      faCircleEllipsis,
    [ExportManifestStatus.EXPORT_MANIFEST_ACCEPTED_AT_CUSTOMS]: faCircleCheck,
    [ExportManifestStatus.EXPORT_MANIFEST_REJECTED_AT_CUSTOMS]: faTimesCircle,
    [ExportManifestStatus.CUSTOMS_UNAVAILABLE]: faQuestionCircle,
  };

  getStatusColor(status: ExportManifestStatus) {
    return this.statusToColorMap[status];
  }

  getStatusIcon(status: ExportManifestStatus) {
    return this.statusToIconMap[status];
  }
}
