import { Component, effect, inject } from "@angular/core";

import { CommonModule } from "@angular/common";

import { TranslateModule } from "@ngx-translate/core";
import { BeamerService } from "@portbase/beamer";
import { PBSidenavModule } from "@portbase/material/sidenav";
import { environment } from "../../environments/environment";
import { AuthenticationService } from "../core/authentication/authentication.service";
import { PortbaseExportTopbarComponent } from "../core/topbar.component";
import { ExportManifestDataPrefetcherComponent } from "./data-prefetcher.component";
import { ExportManifestOperationalRightDrawerComponent } from "./views/operational-view/operational-right-drawer.component";
import { ExportManifestOperationalViewComponent } from "./views/operational-view/operational-view.component";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PBSidenavModule,
    PortbaseExportTopbarComponent,
    ExportManifestDataPrefetcherComponent,
    ExportManifestOperationalRightDrawerComponent,
    ExportManifestOperationalViewComponent,
  ],
  selector: "pbe-export-manifest-page",
  template: `<div class="flex flex-col w-full h-full">
    <pbe-topbar>
      <div class="flex flex-row justify-between items-center w-full">
        <h1 class="text-xl text-gray-700 ml-6">
          {{ "services.exportManifest.title" | translate }}
        </h1>
        @if (!isProduction) {
          <p class="text-xs mr-6">{{ userRoles().join(", ") }}</p>
        }
      </div>
    </pbe-topbar>

    <pbe-export-manifest-operational-view></pbe-export-manifest-operational-view>

    <pbe-export-manifest-operational-right-drawer></pbe-export-manifest-operational-right-drawer>

    <pbe-export-manifest-data-prefetcher />
  </div>`,
})
export class ExportManifestComponent {
  userRoles = inject(AuthenticationService).userRoles;
  userRights = inject(AuthenticationService).userRights;

  isProduction = environment.production;

  authenticationService = inject(AuthenticationService);
  beamerService = inject(BeamerService);

  securityProfile = this.authenticationService.getSecurityProfile().result;

  constructor() {
    effect(() => {
      const userId = this.securityProfile().data?.iamcUserId;
      if (userId) {
        this.beamerService.updateUserId(userId, "melding-lading-export-bulk");
      }
    });
  }
}
