import { Component, Input } from "@angular/core";

@Component({
  selector: "pbe-data-item",
  standalone: true,
  template: ` <div class="mb-3 text-sm">
    <label class="font-medium">{{ label }}</label>
    <div>{{ value }}<ng-content></ng-content></div>
  </div>`,
})
export class PortbaseDataItemComponent {
  @Input({ required: true }) label!: string;
  @Input({ required: true }) value!: string | number | Date | boolean;
}
