import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faCircleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { TextareaAutoresizeDirective } from "../../directives/textarea-autoresize.directive";

@Component({
  selector: "pbe-em-weight-form-input",
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    NgClass,
    MatAutocompleteModule,
    TextareaAutoresizeDirective,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
  ],
  template: `
    <div class="flex flex-row justify-between items-center -ml-2">
      <ng-container [formGroup]="formGroup">
        @if (
          formGroup.controls[controlName].invalid &&
          formGroup.controls[controlName].touched
        ) {
          <fa-icon
            matSuffix
            size="lg"
            [icon]="faCircleExclamation"
            class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
            [tp]="errorLabel"
            tpVariation="tooltipArrow"
            [tpOffset]="[0, 0]"
          ></fa-icon>
        }

        <textarea
          wrap="soft"
          matInput
          [placeholder]="
            'services.exportManifest.createPage.fields.weightPlaceholder' +
              (formGroup.get(controlName)?.hasError('required')
                ? 'Required'
                : 'Optional') | translate
          "
          [formControlName]="controlName"
          class="w-full resize-none overflow-hidden text-right placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
          autocomplete="off"
          pbTextareaAutoresize
          rows="1"
        ></textarea>
      </ng-container>
    </div>
  `,
})
export class PortbaseExportEmWeightFormInputComponent {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  faCircleExclamation = faCircleExclamation;

  constructor(private translateService: TranslateService) {}

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("required")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.weightRequired",
      );
    }

    if (control.hasError("pattern")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.pattern",
      );
    }

    return undefined;
  }

  get hasErrors(): boolean {
    const control = this.formGroup.get(this.controlName);
    return control !== null && control.invalid && control.touched;
  }
}
