import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { injectQuery, queryOptions } from "@ngneat/query";
import { AppConfigService } from "../core/services/app-config.service";
import { VesselStatus } from "./export-manifest.service";

export interface VesselVisitsQueryOptions {
  search?: string;
}

export interface VesselVisit {
  id: string;
  departureDate: string;
  voyageNumber: string;
  vesselName: string;
  vesselStatus: VesselStatus;
  vesselImoCode: string;
  callReferenceNumber: string;
  customsOfficeName: string;
  customsOfficeUnCode: string;
  atdPort: string;
  etdPort: string;
  ataPort: string;
  etaPort: string;
}

@Injectable({ providedIn: "root" })
export class VesselVisitService {
  #http = inject(HttpClient);
  #query = injectQuery();

  private readonly _appConfigService = inject(AppConfigService);
  private readonly _baseUrl = `${this._appConfigService.getConfig().apiUrl}`;

  getVesselVisitsQueryOptions(
    options: VesselVisitsQueryOptions,
    enabled: boolean,
  ) {
    const sanitizedOptions: VesselVisitsQueryOptions = Object.fromEntries(
      Object.entries(options).filter(([, value]) => value !== undefined),
    );

    return queryOptions({
      queryKey: ["vessel-visits", sanitizedOptions] as const,
      queryFn: () => {
        return this.#http.get<VesselVisit[]>(`${this._baseUrl}/vessel-visits`, {
          params: sanitizedOptions as Record<string, string>,
        });
      },
      enabled,
    });
  }

  getVesselVisits(search: string) {
    return this.#query({
      ...this.getVesselVisitsQueryOptions({ search }, false),
      refetchOnWindowFocus: false,
    });
  }
}
