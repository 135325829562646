import { CommonModule, DatePipe } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatPseudoCheckboxModule } from "@angular/material/core";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TranslateModule } from "@ngx-translate/core";
import { PortbaseExportAaxStatusIndicatorComponent } from "../../../../components";
import { PortbaseDataItemComponent } from "../../../../components/data-item.component";
import { ExportManifestAaxShipmentDto } from "../../../export-manifest.service";

@Component({
  standalone: true,
  imports: [
    TranslateModule,
    CommonModule,
    PortbaseDataItemComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    MatCheckboxModule,
    MatPseudoCheckboxModule,
    MatSlideToggleModule,
    DatePipe,
  ],
  selector: "pbe-export-manifest-copy-shipment-list-item",
  template: `@if (shipment) {
    <div class="w-full cursor-pointer" (click)="toggleSelected.emit()">
      <div
        class="w-full pt-5 pb-2.5 px-6 border flex flex-row justify-between hover:border-pb-blue-600"
        [ngClass]="{
          'border-pb-blue-600': selected,
          'border-gray-200': !selected
        }"
      >
        <div class="mt-2">
          <mat-pseudo-checkbox
            [state]="selected ? 'checked' : 'unchecked'"
          ></mat-pseudo-checkbox>
        </div>
        <pbe-data-item
          class="flex-grow-1"
          [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.document'
              | translate
          "
          [value]="formatDocumentTypeAndNumber()"
        />
        <pbe-data-item
          class="flex-grow-1"
          [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.arrivalDateTime'
              | translate
          "
          [value]="(shipment.arrivalDateTime | date: 'dd-MM-yyyy') || '-'"
        />
        <pbe-data-item
          class="flex-grow-1"
          [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.reference'
              | translate
          "
          [value]="shipment.reference || '-'"
        />
        <pbe-data-item
          [label]="
            'services.exportManifest.copyShipmentsDialog.shipmentFields.arrivalAtExitStatus'
              | translate
          "
          [value]="''"
        >
          @if (shipment.arrivalAtExitStatus) {
            <pbe-aax-status-indicator
              [status]="shipment.arrivalAtExitStatus"
            ></pbe-aax-status-indicator>
          } @else {
            <span>-</span>
          }
        </pbe-data-item>
      </div>
    </div>
  } `,
  styles: [
    `
      :host {
        width: 100%;
      }
    `,
  ],
})
export class CopyShipmentListItemComponent {
  @Input({ required: true }) shipment!: ExportManifestAaxShipmentDto;
  @Input({ required: true }) selected!: boolean;
  @Output() toggleSelected = new EventEmitter();

  formatDocumentTypeAndNumber() {
    return `${this.shipment?.documentType} | ${this.shipment?.documentNumber}`;
  }
}
