import { NgFor } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";

@Component({
  standalone: true,
  imports: [NgFor],
  selector: "pbe-tab-bar",
  template: `<div class="w-full h-14 border-b box-border">
    <div
      class="max-w-3xl w-3xl mx-auto h-full border-b flex flex-row box-content"
    >
      <div class="mx-8">
        @for (tab of tabLabels; track $index) {
          <button
            class="w-32 h-full text-center pt-0 border-b-2 hover:bg-slate-200 cursor-pointer box-border font-medium text-sm"
            [class]="
              activeTabIndex === $index
                ? 'border-b-pb-blue-700 text-pb-blue-700'
                : 'border-b-black/0'
            "
            (click)="changeTabIndex.emit($index)"
          >
            {{ tab }}
          </button>
        }
      </div>
    </div>
  </div>`,
})
export class PortbaseExportTabBarComponent {
  @Input({ required: true }) activeTabIndex!: number;
  @Input({ required: true }) tabLabels!: string[];
  @Output() changeTabIndex = new EventEmitter<number>();
}
