import { JsonPipe } from "@angular/common";
import {
  AfterViewInit,
  Component,
  OnDestroy,
  ViewChild,
  inject,
} from "@angular/core";
import { MatDrawer, MatSidenavModule } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import {
  AuthenticationService,
  UserRights,
} from "../../../core/authentication/authentication.service";
import { ExportManifestCreatePageComponent } from "../../details-page/create-page.component";
import { ExportManifestDetailsPageComponent } from "../../details-page/details-page.component";
import { ExportManifestEditPageComponent } from "../../details-page/edit-page.component";
import { ExportManifestService } from "../../export-manifest.service";

type ExportManifestViewQueryParam = "create" | "edit" | "details" | null;

@Component({
  standalone: true,
  selector: "pbe-export-manifest-operational-right-drawer",
  imports: [
    ExportManifestDetailsPageComponent,
    ExportManifestCreatePageComponent,
    ExportManifestEditPageComponent,
    MatSidenavModule,
    JsonPipe,
  ],
  template: `<div
    class="fixed w-screen h-screen top-0 left-0 z-[1] pointer-events-none"
  >
    <div
      class="bg-black transition-opacity opacity-0 w-full h-full"
      [class]="
        drawer.opened
          ? 'opacity-50 pointer-events-auto'
          : 'opacity-0 pointer-events-none'
      "
      (click)="closeRightDrawer()"
    ></div>
    <mat-drawer
      (closed)="removeQueryParams()"
      #drawer
      mode="over"
      class="w-screen max-w-[1024px] 2xl:max-w-[1600px] z-10 pointer-events-auto"
      position="end"
    >
      <div class="bg-white h-full w-full z-10">
        @if (drawer.opened) {
          @switch (viewQueryParam) {
            @case ("create") {
              <pbe-export-manifest-create-page
                (createClose)="closeRightDrawer()"
              ></pbe-export-manifest-create-page>
            }
            @case ("details") {
              @if (exportManifest().data !== undefined) {
                <pbe-export-manifest-details-page
                  (detailsClose)="closeRightDrawer()"
                  [exportManifest]="exportManifest().data!"
                ></pbe-export-manifest-details-page>
              }
            }
            @case ("edit") {
              @if (exportManifest().data !== undefined) {
                <pbe-export-manifest-edit-page
                  (editClose)="closeRightDrawer()"
                  [exportManifest]="exportManifest().data!"
                ></pbe-export-manifest-edit-page>
              }
            }
          }
        }
      </div>
    </mat-drawer>
  </div>`,
})
export class ExportManifestOperationalRightDrawerComponent
  implements OnDestroy, AfterViewInit
{
  viewQueryParam: ExportManifestViewQueryParam = null;
  entityIdQueryParam: string | null = null;

  UserRights = UserRights;
  userRights = inject(AuthenticationService).userRights;

  router = inject(Router);
  route = inject(ActivatedRoute);

  exportManifestService = inject(ExportManifestService);
  exportManifestByIdQuery = this.exportManifestService.getExportManifestById(
    this.entityIdQueryParam,
  );
  exportManifest = this.exportManifestByIdQuery.result;

  @ViewChild("drawer") public drawer!: MatDrawer;

  _paramsSubscription?: Subscription;

  ngAfterViewInit() {
    this._paramsSubscription = this.route.queryParamMap.subscribe((params) => {
      this.viewQueryParam = params.get("view") as ExportManifestViewQueryParam;
      if (this.viewQueryParam === "details" || this.viewQueryParam === "edit") {
        this.entityIdQueryParam = this.route.snapshot.queryParamMap.get("id");
        this.exportManifestByIdQuery.updateOptions(
          this.exportManifestService.getExportManifestByIdQueryOptions(
            this.entityIdQueryParam,
            this.entityIdQueryParam !== null,
          ),
        );
      }
      const openRightDrawer = this.viewQueryParam !== null;
      if (openRightDrawer) {
        setTimeout(() => {
          this.drawer.open();
        });
      }
    });
  }

  ngOnDestroy() {
    this._paramsSubscription?.unsubscribe();
  }

  closeRightDrawer() {
    this.drawer.close();
  }

  removeQueryParams() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParamsHandling: "merge",
      queryParams: { view: null, id: null },
    });
  }
}
