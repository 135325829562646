import { Component } from "@angular/core";

import { CommonModule } from "@angular/common";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBAutocompleteModule } from "@portbase/material/autocomplete";
import { ButtonIconDirective } from "@portbase/material/button";
import {
  PortbaseExportFormInputComponent,
  PortbaseExportFormSelectComponent,
} from "../../components";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    PBAutocompleteModule,
    PortbaseExportFormInputComponent,
    PortbaseExportFormSelectComponent,
    ButtonIconDirective,
    MatButtonModule,
    MatInputModule,
    MatListModule,
  ],
  selector: "pbe-export-manifest-preferences-form",
  template: `<div class="flex flex-col mt-4">
    <h2 class="text-lg font-medium">Export Manifest</h2>
    <div class="flex flex-col gap-5 max-w-md">
      <pbe-form-select
        label="Default document type"
        [form]="form"
        controlName="defaultDocumentType"
        placeholder="Select default document type..."
        [options]="[]"
      />

      <div class="flex flex-col">
        <h2 class="text-base font-medium">Notifications</h2>
        <p>soon™</p>
      </div>
    </div>
  </div>`,
})
export class ExportManifestPreferencesFormComponent {
  faPlus = faPlus;

  form = new FormGroup({
    defaultDocumentType: new FormControl(""),
  });
}
