import { CommonModule } from "@angular/common";
import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import {
  faCircleExclamation,
  faWarning,
} from "@fortawesome/pro-regular-svg-icons";
import { TippyDirective } from "@ngneat/helipopper";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import Fuse from "fuse.js";
import { TextareaAutoresizeDirective } from "../../directives/textarea-autoresize.directive";
import { ExportManifestShipmentDocumentType } from "../export-manifest.service";

const fuseOptions = {
  threshold: 0.4,
  shouldSort: true,
  minMatchCharLength: 3,
  keys: ["value", "label"],
};

export interface AutocompleteOption {
  label: string;
  value: string | boolean;
}

@Component({
  selector: "pbe-em-document-type-form-input",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    TranslateModule,
    MatInputModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    ButtonIconDirective,
    MatButtonModule,
    TippyDirective,
    MatAutocompleteModule,
    TextareaAutoresizeDirective,
  ],
  template: `
    <div
      class="flex flex-row justify-between items-center"
      (click)="triggerRef.openPanel(); textareaRef.focus()"
    >
      <ng-container [formGroup]="formGroup">
        <textarea
          wrap="soft"
          matInput
          [placeholder]="
            'services.exportManifest.createPage.fields.documentTypePlaceholder'
              | translate
          "
          [formControlName]="controlName"
          [matAutocomplete]="auto"
          [matAutocompleteDisabled]="isDisabled"
          class="w-full resize-none overflow-hidden placeholder:font-normal placeholder:text-gray-400 placeholder:text-sm font-normal text-gray-800 text-sm disabled:cursor-not-allowed"
          autocomplete="off"
          pbTextareaAutoresize
          #triggerRef="matAutocompleteTrigger"
          #textareaRef
          rows="1"
        ></textarea>

        @if (
          formGroup.controls[controlName].invalid &&
          formGroup.controls[controlName].touched
        ) {
          <fa-icon
            matSuffix
            size="lg"
            [icon]="faCircleExclamation"
            class="py-1.5 px-2 text-red-700 rounded-full hover:bg-red-100"
            [tp]="errorLabel"
            tpVariation="tooltipArrow"
            [tpOffset]="[0, 0]"
          ></fa-icon>
        } @else {
          <fa-icon
            matSuffix
            size="lg"
            [icon]="faChevronDown"
            class="py-1.5 px-2 rounded-full"
            [ngClass]="{
              'text-blue-300': isDisabled,
              'text-blue-700 group-hover:bg-blue-100': !isDisabled,
              'bg-blue-200 group-hover:bg-blue-200': isFocused
            }"
          ></fa-icon>
        }

        <mat-autocomplete
          #auto="matAutocomplete"
          [hideSingleSelectionIndicator]="true"
          [displayWith]="displayFn"
        >
          <mat-option
            *ngFor="
              let option of getFilteredOptionsForDocumentTypes(
                formGroup.get('documentType')?.value
              )
            "
            [value]="option.value"
          >
            {{ option.label }}
          </mat-option>
        </mat-autocomplete>
      </ng-container>
    </div>
  `,
  styles: [
    `
      ::ng-deep .mat-mdc-autocomplete-panel {
        margin-top: 16px;
        margin-left: -15px;
        min-width: 260px;
      }

      ::ng-deep .cdk-overlay-pane {
        width: fit-content !important;
      }
    `,
  ],
})
export class PortbaseExportEmDocumentTypeFormInputComponent {
  @Input({ required: true }) formGroup!: FormGroup;
  @Input({ required: true }) controlName!: string;

  @ViewChild("textareaRef") textareaRef!: ElementRef<HTMLTextAreaElement>;

  faCircleExclamation = faCircleExclamation;
  faWarning = faWarning;
  faChevronDown = faChevronDown;

  documentTypes: Array<{
    value: ExportManifestShipmentDocumentType;
    label: string;
  }>;

  searchableListForDocumentTypes?: Fuse<{ value: string; label: string }>;

  constructor(private translateService: TranslateService) {
    this.documentTypes = [
      {
        value: "AAD",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.AAD",
        ),
      },
      {
        value: "ATA",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.ATA",
        ),
      },
      {
        value: "CO",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.CO",
        ),
      },
      {
        value: "EU",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.EU",
        ),
      },
      {
        value: "EX",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.EX",
        ),
      },
      {
        value: "ICT",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.ICT",
        ),
      },
      {
        value: "PPE",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.PPE",
        ),
      },
      {
        value: "RAR",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.RAR",
        ),
      },
      {
        value: "REN",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.REN",
        ),
      },
      {
        value: "REX",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.REX",
        ),
      },
      {
        value: "RTR",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.RTR",
        ),
      },
      {
        value: "RT1",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.RT1",
        ),
      },
      {
        value: "RT2",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.RT2",
        ),
      },
      {
        value: "TAR",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.TAR",
        ),
      },
      {
        value: "TIR-D",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.TIR-D",
        ),
      },
      {
        value: "TIR-R",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.TIR-R",
        ),
      },
      {
        value: "TT1",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.TT1",
        ),
      },
      {
        value: "TT2",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.TT2",
        ),
      },
      {
        value: "ZZZ",
        label: this.translateService.instant(
          "services.exportManifest.createPage.fields.documentTypeOptions.ZZZ",
        ),
      },
    ];

    this.searchableListForDocumentTypes = new Fuse(
      this.documentTypes,
      fuseOptions,
    );
  }

  getFilteredOptionsForDocumentTypes(
    query: string | undefined,
  ): { value: string; label: string }[] {
    return query && query.length > 2
      ? this.searchableListForDocumentTypes
          ?.search(query)
          .map((result) => result.item) ?? []
      : this.documentTypes;
  }

  displayFn = (option: AutocompleteOption | string): string => {
    const foundType = this.documentTypes.find((type) => type.value === option);
    return foundType ? foundType.label : (option as string);
  };

  get isFocused(): boolean {
    if (!this.textareaRef) {
      return false;
    }

    return this.textareaRef.nativeElement === document.activeElement;
  }

  get errorLabel(): string | undefined {
    const control = this.formGroup.get(this.controlName);

    if (control === null || control.untouched) {
      return undefined;
    }

    if (control.hasError("required")) {
      return this.translateService.instant("general.forms.errors.required");
    }

    if (control.hasError("invalidDocumentType")) {
      return this.translateService.instant(
        "services.exportManifest.createPage.errors.invalidDocumentType",
      );
    }

    return undefined;
  }

  get isDisabled() {
    return (
      this.formGroup.controls[this.controlName].disabled &&
      !this.formGroup.controls[this.controlName].invalid
    );
  }
}
