import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  computed,
  inject,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatTabsModule } from "@angular/material/tabs";
import { MatToolbarModule } from "@angular/material/toolbar";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { faAngleDown, faCheck } from "@fortawesome/pro-light-svg-icons";
import {
  faCircle,
  faCircleDot,
  faPencil,
  faTimes,
} from "@fortawesome/pro-regular-svg-icons";
import { injectQueryClient } from "@ngneat/query";
import { TranslateModule } from "@ngx-translate/core";
import { ButtonIconDirective } from "@portbase/material/button";
import { PortbaseExportFormInputComponent } from "../../components";
import { PortbaseExportTabBarComponent } from "../../components/tab-bar.component";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { OrganisationService } from "../../core/services/organisation.service";
import { formatArrivalLocationAddress } from "../../core/utils/address-formatter.utils";
import { ArrivalLocationService } from "../arrival-location.service";
import { PortbaseExportCargoHandlingAgentInputComponent } from "../components/cargo-handling-agent-input.component";
import { PortbaseExportRejectionReasonsComponent } from "../components/rejection-reasons.component";
import { PortbaseExportAaxStatusIndicatorComponent } from "../components/status-indicator.component";
import { ShipmentHistoryComponent } from "../history/shipment-history.component";
import { InspectionTypeService } from "../inspection-type.service";
import { ShipmentHistoryService } from "../shipment-history.service";
import {
  ArrivalAtExitStatus,
  ShipmentDto,
  ShipmentService,
} from "../shipment.service";

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatToolbarModule,
    MatButtonModule,
    PortbaseExportTabBarComponent,
    PortbaseExportAaxStatusIndicatorComponent,
    PortbaseExportRejectionReasonsComponent,
    PortbaseExportFormInputComponent,
    FontAwesomeModule,
    ButtonIconDirective,
    MatTabsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    ShipmentHistoryComponent,
    PortbaseExportCargoHandlingAgentInputComponent,
  ],
  selector: "pbe-arrival-at-exit-details-page",
  template: `<div class="h-full flex flex-col items-stretch">
    <mat-toolbar>
      <div class="flex flex-row items-center gap-4">
        <button mat-icon-button [icon]="faTimes" (click)="onClose()"></button>
        <h1 class="text-xl text-gray-700 ml-6 items-center">
          {{ "services.arrivalAtExit.detailsPage.header" | translate }}
        </h1>
      </div>
    </mat-toolbar>
    <div class="bg-slate-100 flex-grow w-full flex-col">
      <pbe-tab-bar
        [activeTabIndex]="activeTabIndex"
        (changeTabIndex)="activeTabIndex = $event"
        [tabLabels]="[
          'services.arrivalAtExit.detailsPage.detailsTab.header' | translate,
          'services.arrivalAtExit.detailsPage.historyTab.header' | translate
        ]"
      ></pbe-tab-bar>

      @if (activeTabIndex === 0 && shipment !== undefined) {
        <div class="mt-10 bg-white p-8 shadow-sm border max-w-3xl mx-auto">
          <div class="w-full">
            <div class="max-w-96">
              <h2 class="text-lg font-medium mb-4">
                {{
                  "services.arrivalAtExit.createPage.headers.document"
                    | translate
                }}
              </h2>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentType"
                    | translate
                }}</label>
                <div>
                  {{
                    "services.arrivalAtExit.createPage.fields.documentTypeOptions." +
                      shipment.documentType | translate
                  }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.documentNumber"
                    | translate
                }}</label>
                <div>{{ shipment.documentNumber }}</div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.reference"
                    | translate
                }}</label>
                <div>{{ shipment.reference || "-" }}</div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalAtExitStatus"
                    | translate
                }}</label>
                <div class="mt-2">
                  <pbe-aax-status-indicator
                    [status]="shipment.arrivalAtExitStatus"
                  ></pbe-aax-status-indicator>

                  @if (
                    shipment.arrivalAtExitStatus ===
                    ArrivalAtExitStatus.EXPORT_SHIPMENT_REJECTED_AT_CUSTOMS
                  ) {
                    <pbe-rejection-reasons [shipment]="shipment" />
                  } @else {
                    <div
                      class="text-xs text-slate-600 mt-2"
                      [innerHTML]="
                        'general.shipmentStatus.long.' +
                          shipment.arrivalAtExitStatus
                          | translate: { inspectionType: inspectionType().data }
                      "
                    ></div>
                  }
                </div>
              </div>

              <h2 class="text-lg font-medium mt-8 mb-4">
                {{
                  "services.arrivalAtExit.createPage.headers.arrival"
                    | translate
                }}
              </h2>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalLocation"
                    | translate
                }}</label>
                <div>
                  {{ getArrivalLocationAsString() }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.creationDate"
                    | translate
                }}</label>
                <div>
                  {{ shipment.creationDate | date: "dd-MM-yyyy HH:mm" }}
                </div>
              </div>
              <div class="mb-3 text-sm">
                <label class="font-medium">{{
                  "services.arrivalAtExit.detailsPage.detailsTab.fields.arrivalTimeAndDate"
                    | translate
                }}</label>
                @if (shipment.arrivalTimeAndDate !== null) {
                  <div>
                    {{ shipment.arrivalTimeAndDate | date: "dd-MM-yyyy HH:mm" }}
                  </div>
                } @else {
                  <div>
                    {{
                      "services.arrivalAtExit.detailsPage.detailsTab.emptyValues.arrivalTimeAndDate"
                        | translate
                    }}
                  </div>
                }
              </div>
              @if (
                shipment.registeredByOrg !== shipment.assignedToOrg ||
                canUpdateCargoHandlingAgent()
              ) {
                <div>
                  @if (userCanAssignCargoHandlingAgent) {
                    <h2 class="text-lg font-medium mt-8 mb-4">
                      {{
                        "services.arrivalAtExit.createPage.headers.agent"
                          | translate
                      }}
                    </h2>
                    @if (isEditingCargoHandlingAgent) {
                      <div class="flex flex-row gap-4">
                        <pbe-cargo-handling-agent-input
                          [form]="cargoHandlingAgentForm"
                        ></pbe-cargo-handling-agent-input>
                      </div>
                    } @else {
                      <div class="text-sm">
                        <label class="font-medium">{{
                          "services.arrivalAtExit.detailsPage.detailsTab.fields.cargoHandlingAgent"
                            | translate
                        }}</label>
                        <div>{{ getCargoHandlingAgentAsString() }}</div>
                      </div>
                      @if (canUpdateCargoHandlingAgent()) {
                        <button
                          class="text-xs font-medium text-pb-blue-700 hover:underline hover:text-pb-blue-900 active:text-pb-blue-950"
                          (click)="isEditingCargoHandlingAgent = true"
                        >
                          <fa-icon [icon]="faPencil"></fa-icon>
                          {{
                            "services.arrivalAtExit.detailsPage.buttons.change"
                              | translate
                          }}
                        </button>
                      }
                    }
                  } @else {
                    <h2 class="text-lg font-medium mt-8 mb-4">
                      {{
                        "services.arrivalAtExit.createPage.headers.terminal"
                          | translate
                      }}
                    </h2>
                    <div class="mb-3 text-sm">
                      <label class="font-medium">{{
                        "services.arrivalAtExit.detailsPage.detailsTab.fields.terminal"
                          | translate
                      }}</label>
                      <div>{{ getTerminalAsString() }}</div>
                      <p class="mt-3">
                        {{
                          "services.arrivalAtExit.detailsPage.detailsTab.createdByTerminal"
                            | translate
                        }}
                      </p>
                    </div>
                  }
                </div>
              }
            </div>
            @if (isEditingCargoHandlingAgent) {
              <div class="flex flex-row justify-end mt-8">
                <div>
                  <button
                    mat-raised-button
                    [disabled]="isSendingToCustoms()"
                    (click)="saveAndSendWithUpdatedCargoHandlingAgent()"
                  >
                    {{
                      "services.arrivalAtExit.createPage.buttons.saveAndSend"
                        | translate
                    }}
                  </button>
                </div>
              </div>
            } @else if (canResend(shipment)) {
              <div class="flex flex-row justify-end mt-8">
                <div>
                  <button
                    mat-raised-button
                    (click)="resendShipment()"
                    [disabled]="isSendingToCustoms()"
                  >
                    {{
                      "services.arrivalAtExit.createPage.buttons.resend"
                        | translate
                    }}
                  </button>
                </div>
              </div>
            }
          </div>
        </div>
      }

      @if (activeTabIndex === 1) {
        <div class="mt-10 max-w-3xl mx-auto flex flex-col gap-y-6">
          <div
            class="bg-white shadow-sm border p-8 max-h-[65vh] overflow-y-auto"
          >
            <div class="w-full flex flex-col gap-5">
              @if (shipmentHistory().data) {
                @for (history of shipmentHistory().data; track history.id) {
                  <pbe-shipment-history
                    [history]="history"
                    [isMostRecent]="$index === 0"
                  ></pbe-shipment-history>
                }
              }
            </div>
          </div>
        </div>
      }
    </div>
  </div>`,
})
export class ArrivalAtExitDetailsPageComponent implements OnChanges {
  @Input({ required: true }) shipment!: ShipmentDto;

  faPencil = faPencil;
  faTimes = faTimes;
  faCircle = faCircle;
  faCircleDot = faCircleDot;
  faAngleDown = faAngleDown;
  faCheck = faCheck;

  queryClient = injectQueryClient();

  sendToCustoms = inject(ShipmentService).sendToCustoms();
  sendToCustomsResult = this.sendToCustoms.result;

  arrivalLocationsService = inject(ArrivalLocationService);

  organisationService = inject(OrganisationService);

  authenticationData = inject(AuthenticationService).authenticated;

  inspectionTypeService = inject(InspectionTypeService);
  inspectionTypeQuery =
    this.inspectionTypeService.getInspectionTypeForShipmentId(null);
  inspectionType = this.inspectionTypeQuery.result;

  shipmentHistoryService = inject(ShipmentHistoryService);
  shipmentHistoryQuery =
    this.shipmentHistoryService.getHistoryForShipmentId(null);
  shipmentHistory = this.shipmentHistoryQuery.result;

  ArrivalAtExitStatus = ArrivalAtExitStatus;

  @Output() detailsClose = new EventEmitter();

  @Input() userCanAssignCargoHandlingAgent = false;

  activeTabIndex = 0;
  tabs = ["details", "history"];

  cargoHandlingAgentForm = new FormGroup({
    agent: new FormControl({
      label: "",
      value: "",
    }),
  });

  isEditingCargoHandlingAgent = false;

  assignCargoHandlingAgent =
    this.organisationService.assignCargoHandlingAgentToShipment();
  assignToCargoHandlingAgentResult = this.assignCargoHandlingAgent.result;

  isSendingToCustoms = computed(() => {
    const isCallingSendToCustoms = this.sendToCustomsResult().isPending;
    const isCallingAssignCargoHandlingAgent =
      this.assignToCargoHandlingAgentResult().isPending;

    return isCallingSendToCustoms || isCallingAssignCargoHandlingAgent;
  });

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["shipment"].currentValue) {
      const shipment = changes["shipment"].currentValue as ShipmentDto;
      this.inspectionTypeQuery.updateOptions(
        this.inspectionTypeService.inspectionTypeQueryOptions(
          shipment.shipmentId,
          shipment.arrivalAtExitStatus,
        ),
      );

      this.shipmentHistoryQuery.updateOptions(
        this.shipmentHistoryService.shipmentHistoryQueryOptions(
          shipment.shipmentId,
        ),
      );

      this.cargoHandlingAgentForm = new FormGroup({
        agent: new FormControl(
          {
            label:
              this.organisationService.getCargoHandlingAgentForId(
                this.shipment.assignedToOrg,
              )?.name ?? "",
            value: this.shipment.assignedToOrg,
          },
          [Validators.required],
        ),
      });
    }
  }

  onClose() {
    this.detailsClose.emit();
  }

  getArrivalLocationAsString(): string {
    const arrivalLocation =
      this.arrivalLocationsService.getArrivalLocationForId(
        this.shipment.arrivalLocationId,
      );
    if (!arrivalLocation) {
      return "";
    }
    return formatArrivalLocationAddress(arrivalLocation);
  }

  getCargoHandlingAgentAsString(): string {
    const cargoHandlingAgent =
      this.organisationService.getCargoHandlingAgentForId(
        this.shipment.assignedToOrg,
      );
    if (!cargoHandlingAgent) {
      return "";
    }
    return cargoHandlingAgent.name;
  }

  getTerminalAsString(): string {
    const terminal = this.organisationService.getTerminalForId(
      this.shipment.registeredByOrg,
    );
    if (!terminal) {
      return "";
    }
    return terminal.name;
  }

  resendShipment() {
    this.sendToCustoms.mutateAsync({
      id: this.shipment.shipmentId,
    });
  }

  canResend(entity: ShipmentDto) {
    return (
      entity.registeredByOrg === this.authenticationData().data?.credentials &&
      (entity.arrivalAtExitStatus === ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE ||
        entity.arrivalAtExitStatus === ArrivalAtExitStatus.INTERNAL_ERROR)
    );
  }

  async saveAndSendWithUpdatedCargoHandlingAgent() {
    if (
      this.cargoHandlingAgentForm.valid &&
      this.cargoHandlingAgentForm.value.agent &&
      this.shipment.assignedToOrg !==
        this.cargoHandlingAgentForm.value.agent.value
    ) {
      await this.assignCargoHandlingAgent.mutateAsync({
        shipmentId: this.shipment.shipmentId,
        cargoHandlingAgent: this.cargoHandlingAgentForm.value.agent.value,
      });
      this.isEditingCargoHandlingAgent = false;

      this.queryClient.refetchQueries({
        predicate: (query) =>
          query.queryKey[0] === "shipments" && query.isActive(),
      });
    }

    await this.sendToCustoms.mutateAsync({
      id: this.shipment.shipmentId,
    });

    this.detailsClose.emit();
  }

  canUpdateCargoHandlingAgent() {
    return (
      (this.userCanAssignCargoHandlingAgent &&
        this.shipment.arrivalAtExitStatus ===
          ArrivalAtExitStatus.EXPORT_SHIPMENT_ACCEPTED_AT_CUSTOMS) ||
      this.shipment.arrivalAtExitStatus ===
        ArrivalAtExitStatus.CUSTOMS_UNAVAILABLE
    );
  }
}
