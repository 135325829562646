import { CommonModule } from "@angular/common";
import { Component, DestroyRef, OnInit, inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute, Router } from "@angular/router";
import {
  faClipboardQuestion,
  faClockFour,
  faInbox,
  faShieldExclamation,
  faShip,
} from "@fortawesome/pro-light-svg-icons";
import { TranslateModule } from "@ngx-translate/core";
import { PBSidenavModule } from "@portbase/material/sidenav";
import { isValid, parseISO } from "date-fns";
import {
  EmptyStateVariant,
  OverviewTableEmptyStateComponent,
} from "../../../components/overview-table/empty-state.component";
import { OverviewTableLoadingStateComponent } from "../../../components/overview-table/loading-state.component";
import { PortbaseExportTopbarComponent } from "../../../core/topbar.component";
import { formatDateForApi } from "../../../core/utils/date-formatter.utils";
import {
  ExportManifestService,
  ExportManifestStatus,
  VesselStatus,
} from "../../export-manifest.service";
import { ExportManifestFilterListComponent } from "../../filter-list/filter-list.component";
import {
  OperationalViewTableControlsComponent,
  OrderByOption,
  OrderDirection,
} from "./operational-view-table/operational-view-table-controls.component";
import { ExportManifestOperationalViewTableComponent } from "./operational-view-table/operational-view-table.component";

const DEFAULT_PAGE_SIZE = 25;

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    PBSidenavModule,
    PortbaseExportTopbarComponent,
    ExportManifestOperationalViewTableComponent,
    ExportManifestFilterListComponent,
    OperationalViewTableControlsComponent,
    OverviewTableLoadingStateComponent,
    OverviewTableEmptyStateComponent,
  ],
  selector: "pbe-export-manifest-operational-view",
  template: ` <pb-sidenav
    [primary]="false"
    [canCollapse]="false"
    [hasLogo]="false"
    [navigationListTitle]="
      'services.exportManifest.navigation.title' | translate
    "
    [navigationOptions]="[
      {
        link: '../draft',
        label: 'services.exportManifest.navigation.notYetSent' | translate,
        icon: faClipboardQuestion
      },
      {
        link: '../urgent',
        label: 'services.exportManifest.navigation.urgent' | translate,
        icon: faClockFour
      },
      {
        link: '../rejected',
        label: 'services.exportManifest.navigation.rejected' | translate,
        icon: faShieldExclamation
      },
      {
        link: '../all',
        label: 'services.exportManifest.navigation.all' | translate,
        icon: faShip
      }
    ]"
    [additionalContentTitle]="
      'services.exportManifest.filters.title' | translate
    "
  >
    <div additionalContent>
      <pbe-export-manifest-filter-list
        [departureDateRange]="departureDateRange"
        [vesselStatusFilter]="vesselStatuses"
        [manifestStatusFilter]="manifestStatuses"
      ></pbe-export-manifest-filter-list>
    </div>

    <div class="flex-grow flex flex-col border-l border-l-black/20">
      <pbe-operational-view-table-controls
        [paginationData]="paginatedManifests().data"
        [isLoading]="paginatedManifests().isInitialLoading"
        [search]="search ?? ''"
        [page]="page"
        [pageSize]="pageSize"
        [orderBy]="orderBy"
        [orderDirection]="orderDirection"
      />
      <div class="bg-slate-100 flex-grow p-10">
        @if (paginatedManifests().isInitialLoading) {
          <pbe-overview-table-loading-state service="exportManifest" />
        }
        @if (paginatedManifests().data?.content; as manifests) {
          @if (manifests.length === 0) {
            <pbe-overview-table-empty-state
              service="exportManifest"
              [emptyStateVariant]="getEmptyStateVariant()"
            />
          } @else {
            <pbe-export-manifest-operational-view-table
              [manifests]="manifests"
            />
          }
        }
      </div>
    </div>
  </pb-sidenav>`,
  styles: `
    :host {
      width: 100%;
      height: 100%;
    }
  `,
})
export class ExportManifestOperationalViewComponent implements OnInit {
  faInbox = faInbox;
  faShieldExclamation = faShieldExclamation;
  faClipboardQuestion = faClipboardQuestion;
  faClockFour = faClockFour;
  faShip = faShip;

  quickFilter: ExportManifestStatus[] | null = null;
  departureDateRange: [Date, Date] | null = null;
  vesselStatuses: VesselStatus[] | null = null;
  manifestStatuses: ExportManifestStatus[] | null = null;
  search: string | null = null;
  orderBy: OrderByOption = "departureDate";
  orderDirection: OrderDirection = "desc";
  page = 0;
  pageSize = DEFAULT_PAGE_SIZE;

  router = inject(Router);
  route = inject(ActivatedRoute);
  destroyRef = inject(DestroyRef);

  exportManifestService = inject(ExportManifestService);
  exportManifestsQuery = this.exportManifestService.getExportManifests(
    this.page,
    this.pageSize,
  );
  paginatedManifests = this.exportManifestsQuery.result;

  ngOnInit(): void {
    this.route.data
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data) => {
        this.quickFilter = data["quickFilter"] ?? null;
      });

    this.route.queryParamMap
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        this.handleQueryParamDateRange(params.get("departureDateRange"));

        if (this.quickFilter && this.quickFilter.length > 0) {
          this.manifestStatuses = this.quickFilter;
        } else {
          this.handleQueryParamManifestStatuses(params.get("manifestStatuses"));
        }
        this.handleQueryParamVesselStatuses(params.get("vesselStatuses"));

        const orderDirection = params.get(
          "orderDirection",
        ) as OrderDirection | null;
        const page = params.get("page");
        const pageSize = params.get("pageSize");
        const search = params.get("search");

        this.orderDirection = orderDirection ?? "desc";

        this.page = page !== null ? +page : 0;
        this.pageSize = pageSize !== null ? +pageSize : DEFAULT_PAGE_SIZE;
        this.search = search;

        const queryOptions = {
          page: this.page,
          size: this.pageSize,
          search: this.search ?? undefined,
          vesselStatuses: this.vesselStatuses ?? undefined,
          manifestStatuses: this.manifestStatuses ?? undefined,
          direction: this.orderDirection.toUpperCase(),
          fromDepartureDate: this.departureDateRange
            ? formatDateForApi(this.departureDateRange[0])
            : undefined,
          toDepartureDate: this.departureDateRange
            ? formatDateForApi(this.departureDateRange[1])
            : undefined,
        };

        this.exportManifestsQuery.updateOptions(
          this.exportManifestService.getExportManifestsQueryOptions(
            queryOptions,
            true,
          ),
        );
      });
  }

  handleQueryParamDateRange(range: null | string) {
    if (range === null) {
      this.departureDateRange = null;
      return;
    }
    const [startString, endString] = range.split(",");
    const start = parseISO(startString);
    const end = parseISO(endString);

    if (isValid(start) && isValid(end)) {
      this.departureDateRange = [start, end];
    } else {
      this.departureDateRange = null;
    }
  }

  handleQueryParamVesselStatuses(statusesString: null | string) {
    if (statusesString === null || statusesString.length === 0) {
      this.vesselStatuses = null;
      return;
    }

    const statuses: string[] = statusesString.split(",");
    const validStatuses = statuses.filter((status) =>
      Object.values(VesselStatus).includes(status as VesselStatus),
    ) as VesselStatus[];

    if (
      validStatuses.length === 0 ||
      validStatuses.length !== statuses.length
    ) {
      return;
    }

    if (validStatuses.length > 0) {
      this.vesselStatuses = validStatuses;
    } else {
      this.vesselStatuses = null;
    }
  }

  handleQueryParamManifestStatuses(statusesString: null | string) {
    if (statusesString === null || statusesString.length === 0) {
      this.manifestStatuses = null;
      return;
    }

    const statuses: string[] = statusesString.split(",");
    const validStatuses = statuses.filter((status) =>
      Object.values(ExportManifestStatus).includes(
        status as ExportManifestStatus,
      ),
    ) as ExportManifestStatus[];

    if (
      validStatuses.length === 0 ||
      validStatuses.length !== statuses.length
    ) {
      return;
    }

    if (validStatuses.length > 0) {
      this.manifestStatuses = validStatuses;
    } else {
      this.manifestStatuses = null;
    }
  }

  getEmptyStateVariant(): EmptyStateVariant {
    const hasQuickFilterActive = this.quickFilter !== null;
    const hasSearchTerm = this.search !== null;

    if (hasQuickFilterActive) {
      return "emptyQuickFilter";
    }

    if (hasSearchTerm) {
      return "empty";
    }

    return "emptyNoFilter";
  }
}
